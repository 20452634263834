import {
  PUSH_FAVOURITES_PAGE,
  SET_LOADING_FAVOURITES,
  UPDATE_FAVOURITES
} from './constants/favourites';

export const favouritesReducerDefaultState = {
  loaded: false,
  currentPage: 'overview',
  showHeader: true,
  pending: [],
  favourites: [],
  recommended: []
};

export default (state = favouritesReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_FAVOURITES:
      return {
        ...state,
        loaded: action.loaded,
        pending: action.pending,
        favourites: action.favourites,
        recommended: action.recommended
      };
    case SET_LOADING_FAVOURITES:
      return {
        ...state,
        loaded: false
      };
    case PUSH_FAVOURITES_PAGE:
      return {
        ...state,
        showHeader: action.showHeader,
        currentPage: action.currentPage
      };
    default:
      return state;
  }
};
