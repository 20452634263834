import { push } from 'connected-react-router';
import { set } from 'idb-keyval';
import { enqueueSnackbar } from './snackbar';
import { updateProfileDetails } from './profile';
import { continueCreation } from './continueCreation';
import {
  setCookie,
  getCookie,
  deleteCookie
} from '../libraries/authentication';
import { registerCordovaPush } from '../libraries/cordovaNotification';
import { initializePwaPush } from '../libraries/notification';
import { getNotifications } from './notification';
import { getCreditsRemaining } from './credits';
import { displayReferralIntroDialog } from './customPopup';
import {
  createPosterReferralCode,
  createSession,
  createSocialSession,
  createUser,
  resendVerificationCode,
  resetPasswordRequest,
  updatePhoneAndEmail,
  verifyPhoneNumber
} from '../api/auth';

import {
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  REGISTERATION_FULLFILED,
  REGISTERATION_PROCESS,
  REGISTERATION_REJECTED
} from '../reducers/constants/auth';

export const LOGIN_USER = 'login_user';
export const USER_REGISTER = 'user_register';
export const FETCH_PROFILE = 'fetch_profile';

const getSegmentAnnID = () => {
  try {
    let id;
    if (window.localStorage.getItem('ajs_anonymous_id') !== null) {
      id = window.localStorage.getItem('ajs_anonymous_id');
    } else if (
      window &&
      window.analytics &&
      window?.analytics?.user() &&
      window.trackingPermitted
    ) {
      id = window.analytics.user().anonymousId();
    }
    return id;
  } catch (error) {
    // eslint-disable-next-line no-console
    return console.error(error);
  }
};

export function logIn(values) {
  return async (dispatch, getState) => {
    const { isSuccess, authUser, authToken, message, error } =
      await createSession(values);

    if (isSuccess) {
      await dispatch(updateProfileDetails(authUser));
      await dispatch({ type: LOGIN_FULFILLED });
      getSegmentAnnID();
      if (window.trackingPermitted) {
        window.analytics.alias(getSegmentAnnID(), authUser?.id);
        window.analytics.identify(authUser?.id, {
          name: authUser?.name,
          email: authUser?.email,
          phone_num: authUser?.phone_num,
          user_type: authUser?.is_business_account
            ? 'business_account'
            : 'free_account',
          signup_at: authUser?.created_at,
          admin: authUser?.is_admin,
          roles: authUser?.roles,
          signup_method: authUser?.signup_method
        });
      }

      window.analytics.track('logged_in', {
        category:
          authUser && authUser.is_business_account
            ? 'business_account'
            : 'free_account',
        login_method: 'email',
        platform: 'pwa'
      });

      const phoneVerified = authUser.phone_verified;
      const phoneNumber = authUser?.phone_num;
      setCookie('goget-auth-token', authToken);
      setCookie('user-phone-verified', phoneVerified);
      setCookie('user-phone-number', phoneNumber);
      setCookie('user-email-address', authUser?.email);
      set('user-id', authUser.id);
      deleteCookie('atid');
      await dispatch(getNotifications());
      await dispatch(getCreditsRemaining());
      registerCordovaPush();
      initializePwaPush();
      if (authToken) {
        if (!phoneVerified) {
          dispatch(push('/edit_phone_and_email'));
        } else if (getState().continueCreation.path !== undefined) {
          dispatch(push(getState().continueCreation.path));
          dispatch(continueCreation(undefined));
        } else {
          dispatch(push('/'));
        }
        await dispatch(
          enqueueSnackbar({
            message,
            options: {
              variant: 'success'
            }
          })
        );
      }

      return {
        success: true
      };
    } else {
      if (error?.response) {
        await dispatch({ type: LOGIN_REJECTED, payload: error });
      }
      return {
        success: false
      };
    }
  };
}

export function socialLogin(values) {
  return async (dispatch, getState) => {
    window.analytics.track('click', {
      category: 'sign_up_log_in',
      label: 'continue_w_fb',
      platform: 'pwa'
    });

    let registrationParams = values;
    const atid = getCookie('atid');
    if (atid) {
      registrationParams = {
        ...registrationParams,
        atid
      };
    }

    const { isSuccess, authUser, authToken, newUser, message, error } =
      await createSocialSession(registrationParams);

    if (isSuccess) {
      await dispatch(updateProfileDetails(authUser));
      await dispatch({ type: LOGIN_FULFILLED });

      if (window.trackingPermitted) {
        window.analytics.alias(getSegmentAnnID(), authUser?.id);
        window.analytics.identify(authUser?.id, {
          name: authUser?.name,
          email: authUser?.email,
          phone_num: authUser?.phone_num,
          user_type: authUser?.is_business_account
            ? 'business_account'
            : 'free_account',
          signup_at: authUser?.created_at,
          admin: authUser?.is_admin,
          roles: authUser?.roles,
          signup_method: authUser?.signup_method
        });
      }

      if (newUser) {
        window.analytics.track('signed_up', {
          category: 'signup_page',
          label: 'user_signup',
          platform: 'pwa',
          signup_method: values.type
        });
      } else {
        window.analytics.track('logged_in', {
          category:
            authUser && authUser?.is_business_account
              ? 'business_account'
              : 'free_account',
          login_method: values.type,
          platform: 'pwa'
        });
      }

      const phoneVerified = authUser?.phone_verified;
      const phoneNum = authUser?.phone_num;
      const { email } = authUser;
      setCookie('goget-auth-token', authToken);
      setCookie('user-phone-verified', phoneVerified);
      setCookie('user-phone-number', phoneNum);
      setCookie('user-email-address', email);
      window.localStorage.setItem('user-email-address', email);
      set('user-id', authUser?.id);
      deleteCookie('atid');
      await dispatch(getCreditsRemaining());
      await dispatch(getNotifications());
      registerCordovaPush();
      initializePwaPush();

      if (email === null || phoneNum === null || phoneVerified === false) {
        dispatch(push('/edit_phone_and_email'));
      } else if (getState().continueCreation.path !== undefined) {
        dispatch(push(getState().continueCreation.path));
        dispatch(continueCreation(undefined));
      } else {
        dispatch(push('/'));
      }
      await dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success'
          }
        })
      );
      return {
        success: true
      };
    } else {
      if (error?.response) {
        await dispatch({ type: LOGIN_REJECTED, payload: error });
      }
      return {
        success: false
      };
    }
  };
}

export function register(values) {
  return async dispatch => {
    let registrationParams = values;
    const atid = getCookie('atid');
    if (atid) {
      registrationParams = {
        ...registrationParams,
        atid
      };
    }
    const { isSuccess, authUser, authToken, error, data } = await createUser(
      registrationParams
    );

    dispatch({ type: REGISTERATION_PROCESS });

    if (!isSuccess && error?.response) {
      dispatch({ type: REGISTERATION_REJECTED });
    }

    if (isSuccess) {
      if (window.trackingPermitted) {
        window.analytics.alias(getSegmentAnnID(), authUser?.id);
      }

      window.analytics.track('signed_up', {
        category: 'signup_page',
        label: 'user_signup',
        platform: 'pwa',
        signup_method: 'email'
      });

      const phoneNum = authUser?.phone_num;
      const email = authUser?.email;
      const phoneVerified = authUser?.phone_verified;

      dispatch(updateProfileDetails(authUser));
      setCookie('posterReferralCode', '');
      setCookie('goget-auth-token', authToken);
      setCookie('user-phone-verified', phoneVerified);
      setCookie('user-phone-number', phoneNum);
      setCookie('user-email-address', email);
      dispatch({ type: FETCH_PROFILE, payload: data });
      dispatch({ type: REGISTERATION_FULLFILED });
      dispatch(push('/phone_verification'));
      set('user-id', authUser.id);
      deleteCookie('atid');
      dispatch(getNotifications());
      registerCordovaPush();
      initializePwaPush();
    }
  };
}

export function PhoneConfirmation(values, callback) {
  return async dispatch => {
    const { isSuccess, error, authUser, message } = await verifyPhoneNumber(
      values
    );

    if (!isSuccess && error?.response) {
      callback(false);
    }

    if (isSuccess) {
      const { phone_verified } = authUser;

      setCookie('user-phone-verified', phone_verified);
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch(getNotifications());
      callback(phone_verified);
    }
  };
}

// - FORGOT PASSWORD
export function resetPassword(email) {
  return async dispatch => {
    const { isSuccess, error, message } = await resetPasswordRequest(email);

    if (isSuccess) {
      await dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch(push('/login'));
      return {
        success: true
      };
    } else {
      if (error?.response) {
        await dispatch({ type: LOGIN_REJECTED, payload: error });
      }
      return {
        success: false
      };
    }
  };
}

export function resendCode() {
  return async dispatch => {
    const { isSuccess, message, error } = await resendVerificationCode();

    if (isSuccess) {
      await dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch(push('/phone_verification'));
      return {
        success: true
      };
    } else {
      if (error?.response) {
        await dispatch({ type: LOGIN_REJECTED, payload: error });
      }
      return {
        success: false
      };
    }
  };
}

export function updatePhoneAndEmailAndResendCode(userInfo) {
  return async dispatch => {
    const { isSuccess, user, error } = await updatePhoneAndEmail(userInfo);

    if (isSuccess) {
      const phoneVerified = user?.phone_verified;
      const phoneNum = user?.phone_num;
      setCookie('user-phone-number', phoneNum);
      setCookie('user-phone-verified', phoneVerified);
      window.localStorage.setItem('user-email-address', user?.email);

      await dispatch(resendCode());

      dispatch(
        updateProfileDetails({
          ...user
        })
      );
    } else {
      if (error?.response) {
        await dispatch({ type: LOGIN_REJECTED, payload: error });
      }
      return {
        success: false
      };
    }
  };
}

export function createPosterReferral(code) {
  return async dispatch => {
    let data = {};

    if (code) {
      data = {
        poster_referral: {
          referral_code: code
        }
      };
    }

    const { isSuccess, isTempReferral, message, userData } =
      await createPosterReferralCode(data);

    if (isSuccess) {
      // handle case when applying temp referral code and found empty
      if (isTempReferral) return;

      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch(displayReferralIntroDialog());
      dispatch(
        updateProfileDetails({
          ...userData
        })
      );
    }
  };
}
