import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import TierCard from './ui/TierCard';

import { getKycStatus } from '../../actions/profile';
import { getAuthToken } from '../../libraries/authentication';
import {
  KYC_NOT_STARTED,
  KYC_TIER_ONE,
  KYC_TIER_THREE,
  KYC_TIER_TWO
} from './constants/kycServices';

import MobilePageDivider from '../ui/MobilePageDivider';

const StyledCard = styled(Card)`
  width: 100%;
  && {
    border-radius: ${({ isMobile }) => (isMobile ? 0 : '8px')};
  }
`;

const TierProgress = props => {
  const {
    withFooterCta,
    profile,
    isMobile,
    dispatchGetKycStatus,
    desktopLayout,
    isProfile
  } = props;

  const userTier = profile?.tier;
  const promoRedeemed = profile?.code_redeemed;
  const isVerified = userTier === KYC_TIER_THREE && profile?.kyc_verified;

  useEffect(() => {
    if (getAuthToken()) {
      dispatchGetKycStatus();
    }
  }, []);

  const renderTierCard = tier => {
    if ([KYC_TIER_ONE, KYC_TIER_TWO].includes(tier)) {
      return (
        <TierCard
          tier={tier}
          isVerified={isVerified}
          avatar={profile?.avatar}
          name={profile?.name}
          verificationStatus={profile?.status}
          failureReasons={profile?.reason}
          retryPossible={profile?.allow_retry}
          withFooterCta={withFooterCta}
          desktopLayout={desktopLayout}
        />
      );
    } else {
      return <></>;
    }
  };

  if (
    !getAuthToken() ||
    !userTier ||
    userTier === KYC_NOT_STARTED ||
    promoRedeemed ||
    isVerified
  )
    return <></>;

  return (
    <>
      {isProfile && isMobile && <MobilePageDivider />}
      <StyledCard elevation={!isMobile ? 2 : 0} isMobile={isMobile}>
        <CardContent>{renderTierCard(userTier)}</CardContent>
      </StyledCard>
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state?.user?.profile,
    desktopLayout: state?.layout?.desktopLayout
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetKycStatus: () => dispatch(getKycStatus())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TierProgress);
