import React from 'react';
import styled from 'styled-components/macro';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import compareVersions from 'compare-versions';

import { setSrcPath } from '../../libraries/ApplicationService';

import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 16px;
  }
`;
const Container = styled.div`
  overflow-y: scroll;
  background-color: ${colors.primary};
  color: ${colors.white};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 32px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${colors.primary};
  color: ${colors.white};
`;
const Body = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
`;
const Title = styled.div`
  font-size: ${fontSize.xLarge};
  margin-bottom: 16px;
`;
const SubTitle = styled.div`
  font-size: ${fontSize.regular};
  font-weight: 100;
`;
const Text = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
`;
const ScreenshotWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;
const ScreenshotImg = styled.img`
  max-height: 400px;
`;
const DownloadButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 32px;
  padding-right: 32px;
`;
const DownloadButtonImg = styled.img`
  width: 160px;
`;

export const CLOSE_BUTTON_TEST_ID = 'gogetter-dialog-close';
export const APPSTORE_LINK_TEST_ID = 'appstore-link';
export const PLAYSTORE_LINK_TEST_ID = 'playstore-link';

const DialogGogetterApp = props => {
  const { open, onClose } = props;
  const isIOS =
    window?.cordova &&
    window?.device?.version &&
    compareVersions.compare(window?.device?.version, '13.0', '>=');

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container>
        <Header>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            data-testid={CLOSE_BUTTON_TEST_ID}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <Body>
          <Title>We have a brand new app for GoGetters!</Title>
          <SubTitle>Download now to start finding jobs.</SubTitle>
          <ScreenshotWrapper>
            <ScreenshotImg
              alt=""
              src={setSrcPath('/GogetterAppScreenshot.png')}
            />
          </ScreenshotWrapper>
          <Text>Download now:</Text>
        </Body>
        <DownloadButtonsWrapper>
          {isIOS ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/my/app/gogetter-part-timer-community/id1483160371"
              data-testid={APPSTORE_LINK_TEST_ID}
            >
              <DownloadButtonImg
                alt=""
                src={setSrcPath('/AppStoreDownload.svg')}
              />
            </a>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=my.goget.gogetter&hl=en"
              data-testid={PLAYSTORE_LINK_TEST_ID}
            >
              <DownloadButtonImg
                alt=""
                src={setSrcPath('/PlayStoreDownload.svg')}
              />
            </a>
          )}
        </DownloadButtonsWrapper>
      </Container>
    </StyledDialog>
  );
};
export default DialogGogetterApp;
