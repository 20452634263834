import {
  UPDATE_DESKTOP_LAYOUT,
  UPDATE_TABLET_LAYOUT
} from '../reducers/constants/layout';

export const updateLayout = desktopLayout => ({
  type: UPDATE_DESKTOP_LAYOUT,
  desktopLayout
});

export const updateTablet = isTablet => ({
  type: UPDATE_TABLET_LAYOUT,
  isTablet
});

export const setLayout = desktopLayout => {
  return dispatch => {
    dispatch(updateLayout(desktopLayout));
  };
};

export const setIsTablet = isTablet => {
  return dispatch => {
    dispatch(updateTablet(isTablet));
  };
};
