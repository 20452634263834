export const partTimerName = `SAMPLE - Remove non-applicable items

A) Important

1. General description:
e.g. Sort and pack fashion items into parcels.

2. Job tasks:
e.g. - Pick items from shelves based on order list and send to packing station.
- Pack items using bubble wrap and boxes.
- Report to supervisor if item is damaged.

3. Environment:
e.g. Open air warehouse. Working closely with full time staffs who are ready to help.

B) Optional (remove if not applicable)

4. Expected job outcome:
e.g. Pack 20 orders in one hour.

5. Tools required:
e.g. All tools will be provided.

6. Physical requirements:
e.g. - A lot of standing and walking.
- Might need to lift heavy boxes.`;

export const personalHelperName = `SAMPLE - Remove non-applicable items

A) Important

1. General description:
e.g. Help me move and arrange my home furnitures into the lorry.

2. Job tasks:
e.g. - Carry furnitures like table, sofa, and bed frame into lorry.
- Arrange furnitures inside lorry.
- Secure all fragile items using bubble wrap and plastic sheet.

3. Environment:
e.g. 2-storey terrace. Working with me and family. We have 2 dogs.

B) Optional (remove if not applicable)

4. Expected job outcome:
e.g. Move all furnitures in 3 hours.

5. Tools required:
e.g. All tools will be provided.

6. Physical requirements:
e.g. - A lot of standing and walking.
- Must be fit to lift heavy furnitures.`;
