import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Popper from '@material-ui/core/Popper';
import colors from '../../style/colors';

const arrowRotation = position => {
  switch (position) {
    case 'top':
      return 'rotate(0deg)';
    case 'bottom':
      return 'rotate(180deg)';
    case 'left':
      return 'rotate(270deg)';
    case 'right':
      return 'rotate(90deg)';
    default:
      return 'rotate(0deg)';
  }
};

const useStyles = makeStyles({
  root: {
    padding: '8px',
    margin: '8px',
    borderRadius: '8px',
    lineHeight: '20px',
    color: colors.white,
    background: colors.blue,
    zIndex: props => props.zIndex || 9999
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    top: props => props.arrowPosition === 'top' && 0,
    bottom: props => props.arrowPosition === 'bottom' && '-1em',
    transform: props => arrowRotation(props.arrowPosition),
    left: props => props.arrowPosition === 'left' && '-1em',
    width: '3em',
    height: '3em',
    marginTop: '-0.9em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${colors.blue} transparent`
    }
  }
});

const GGPopper = ({
  children,
  open,
  anchorEl,
  arrowPosition,
  placement,
  zIndex
}) => {
  const classes = useStyles({ arrowPosition, zIndex });
  const [arrowRef, setArrowRef] = React.useState(null);
  return (
    <Popper
      placement={placement}
      className={classes.root}
      open={open}
      anchorEl={anchorEl}
      modifiers={{
        flip: {
          enabled: false
        },
        arrow: {
          enabled: true,
          element: arrowRef
        }
      }}
    >
      <>
        <span className={classes.arrow} ref={setArrowRef} />
        {children}
      </>
    </Popper>
  );
};

export default GGPopper;
