import { Chip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { getAuthToken } from '../../../libraries/authentication';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

const TileWrapper = styled.div`
  display: inline-block;
  width: ${props => (props.mobile ? '70px' : '80px')};
  margin: 0 0.25rem;
`;
const ItemButton = styled.button`
  width: 100%;
  padding: 0.25rem;
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  background-color: white;
  cursor: pointer;
  border-radius: 6px;
  color: ${colors.black};

  :hover {
    background-color: ${colors.greyLightest};
  }
`;
const StyledIconImage = styled.img`
  display: block;
  width: 60px;
  height: 61px;
  margin: 0 auto;
`;
const StyledTitle = styled.div`
  display: block;
  text-align: center;
  font-size: ${({ mobile }) => (mobile ? fontSize.regular : fontSize.medium)};
  line-height: 1rem;
  font-family: 'Open Sans', sans-serif;
  white-space: normal;
  margin: 0 auto;
`;
const StyledChip = styled(Chip)`
  && {
    position: absolute;
    background: ${({ color }) => color};
    height: 20px;
    color: ${colors.white};
    font-size: 8px;
    font-weight: 600;
    bottom: ${({ mobileLayout }) => (mobileLayout ? '4px' : '35px')};
    border: 3px solid ${colors.white};
    line-height: 8px;
    cursor: pointer;
`;
const Container = styled.div`
  position: relative;
  display: inline;
`;

const Tile = props => {
  const {
    onClick,
    jobType,
    isBusinessAccount,
    mobileLayout,
    image,
    title,
    chip
  } = props;

  const onHandleClick = () => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: jobType,
      platform: 'pwa'
    });
    onClick();
  };

  return (
    <Container>
      <TileWrapper mobile={mobileLayout} data-test={`tile-button-${title}`}>
        <ItemButton
          onClick={onHandleClick}
          mobile={mobileLayout}
          data-test={`tile-button-${title}`}
        >
          <StyledIconImage src={image} mobile={mobileLayout} />
          {chip?.color && chip?.text && (
            <StyledChip
              color={chip?.color}
              label={chip.text}
              mobileLayout={mobileLayout}
            />
          )}
          <StyledTitle mobile={mobileLayout}>{title}</StyledTitle>
        </ItemButton>
      </TileWrapper>
    </Container>
  );
};

export default React.memo(Tile);
