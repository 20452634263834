import moment from 'moment';

const toNearestFifteen = time => {
  const start = moment(time);
  const remainder = 15 - (start.minutes() % 15);

  return moment(start).add(remainder, 'minutes');
};

export default toNearestFifteen;
