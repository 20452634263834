import axios from 'axios';
import {
  SERVER_URL,
  successResponse,
  failedResponse,
  HTTP_STATUS_OK
} from './apiHelpers';

export const getJobFee = async requestData => {
  try {
    const url = `${SERVER_URL}/private/v1/jobs/fee`;
    const response = await axios.post(url, requestData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
