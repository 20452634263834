import _ from 'lodash';

import {
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_FULFUILED,
  MARK_AS_READ,
  MARK_ALL_AS_READ
} from './constants/notification';

export const notificationReducerDefaultState = {
  notifications: [],
  updatedAt: undefined,
  unreadActivityCount: 0,
  unreadCount: 0,
  unreadGeneralCount: 0,
  loading: false,
  loaded: false
};

export default (state = notificationReducerDefaultState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION:
      return { ...state, loading: true };
    case FETCH_NOTIFICATION_FULFUILED:
      const newNotification = _.mapKeys(action.notifications, 'id');
      const allNotifications = {
        ...state.notifications,
        ...newNotification
      };
      return {
        ...state,
        loading: false,
        loaded: true,
        updatedAt: action.updatedAt,
        notifications: allNotifications,
        newNotifications: !action.firstLoad && newNotification,
        unreadActivityCount: action.unreadActivityCount,
        unreadCount: action.unreadCount,
        unreadGeneralCount: action.unreadGeneralCount
      };
    case MARK_AS_READ:
      const readNotification = _.find(_.mapKeys(state.notifications, 'id'), [
        'id',
        action.notificationId
      ]);
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.notificationId]: _.set(readNotification, 'read', true)
        }
      };
    case MARK_ALL_AS_READ:
      const originalNotifications = _.mapKeys(state.notifications, 'id');
      const readNotifications = _.map(originalNotifications, notification => {
        const updatedNotification = { ...notification, read: true };
        return updatedNotification;
      });
      return {
        ...state,
        notifications: {
          ...readNotifications
        }
      };
    default:
      return state;
  }
};
