export const HIRING_POST_CHANGE_PAGE = 'HIRING_POST_CHANGE_PAGE';
export const HIRING_POST_EDIT_JOB_DETAILS = 'HIRING_POST_EDIT_JOB_DETAILS';
export const HIRING_POST_START_JOB_CREATE = 'HIRING_POST_START_JOB_CREATE';
export const HIRING_POST_END_JOB_CREATE = 'HIRING_POST_END_JOB_CREATE';
export const HIRING_POST_RESET_STATE = 'HIRING_POST_RESET_STATE';
export const HIRING_POST_START_FETCH_FEE = 'HIRING_POST_START_FETCH_FEE';
export const HIRING_POST_END_FETCH_FEE = 'HIRING_POST_END_FETCH_FEE';
export const HIRING_POST_UPDATE_FEE_BREAKDOWN =
  'HIRING_POST_UPDATE_FEE_BREAKDOWN';
export const HIRING_POST_SET_FIRST_ENTRY = 'HIRING_POST_SET_FIRST_ENTRY';
export const HIRING_POST_DISMISS_FIRST_ENTRY =
  'HIRING_POST_DISMISS_FIRST_ENTRY';
export const HIRING_POST_EDIT_ABANDON_DETAILS =
  'HIRING_POST_EDIT_ABANDON_DETAILS';
export const EDIT_HIRING_POST_START_EDIT = 'EDIT_HIRING_POST_START_EDIT';
export const EDIT_HIRING_POST_START_UPDATE = 'EDIT_HIRING_POST_START_UPDATE';
export const EDIT_HIRING_POST_END_UPDATE = 'EDIT_HIRING_POST_END_UPDATE';
