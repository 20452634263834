import { lazy } from 'react';

const Profile = lazy(() => import('../../components/profile/ProfileV2'));
const EditProfile = lazy(() => import('../../components/profile/EditProfile'));
const Reviews = lazy(() => import('../../components/profile/Reviews'));
const Settings = lazy(() => import('../../components/profile/Settings'));
const Privacy = lazy(() => import('../../components/profile/Privacy'));
const Referral = lazy(() => import('../../components/referral/Referral'));
const MyJobs = lazy(() => import('../../components/my_jobs/MyJobs'));
const EditJob = lazy(() => import('../../components/job_edit/EditJob'));
const JobShow = lazy(() => import('../../components/job_show/Show'));
const Chat = lazy(() => import('../../components/chat/Chat'));
const CreditReceipt = lazy(() => import('../../components/credits/Receipt'));
const Receipt = lazy(() => import('../../components/receipt/Receipt'));
const Report = lazy(() => import('../../components/job_show/Report'));
const Templates = lazy(() => import('../../components/templates/Templates'));
const Rewards = lazy(() => import('../../components/rewards/Rewards'));
const HiringPostsJobs = lazy(() => import('../../components/hiringPost/Jobs'));
const ManageBulkOrders = lazy(() =>
  import('../../components/manageBulkOrder/ManageBulkOrders')
);
const ChangeAccount = lazy(() =>
  import('../../components/profile/ChangeAccount')
);
const DeleteAccount = lazy(() =>
  import('../../components/profile/DeleteAccount')
);
const UpdatePassword = lazy(() =>
  import('../../components/profile/UpdatePassword')
);
const HelpSupportPage = lazy(() =>
  import('../../components/profile/HelpSupportPage')
);
const FavouriteGoGetters = lazy(() =>
  import('../../components/myGoGetters/MyGoGetters')
);
const Announcement = lazy(() =>
  import('../../components/announcement/Announcement')
);
const Notification = lazy(() =>
  import('../../components/notification/Notification')
);
const LazadaOrdersManagement = lazy(() =>
  import('../../components/lazada/LazadaOrdersManagement')
);
const GgInspector = lazy(() =>
  import('../../components/ggInspector/Dashboard')
);
const GoGetterProfile = lazy(() =>
  import('../../components/myGoGetters/GoGetterProfile')
);
const VerifyCustomer = lazy(() =>
  import('../../components/kyc/VerifyCustomer')
);
const HiringPostEdit = lazy(() =>
  import('../../components/hiringPost/HiringPostEdit')
);
const HiringPostsJobDetails = lazy(() =>
  import('../../components/hiringPost/Details')
);
const HiringPostsReceipt = lazy(() =>
  import('../../components/hiringPost/Receipt')
);
const ListofApplicants = lazy(() =>
  import('../../components/hiringPost/ListofApplicants')
);
const ApplicantDetails = lazy(() =>
  import('../../components/hiringPost/ApplicantDetails')
);
const ApplicantsReview = lazy(() =>
  import('../../components/hiringPost/ApplicantsReview')
);

export const privateRouters = [
  {
    path: '/manage_bulk_orders/:bulkId',
    component: ManageBulkOrders
  },
  {
    path: '/manage_bulk_orders',
    component: ManageBulkOrders
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/change_account',
    component: ChangeAccount
  },
  {
    path: '/edit_profile',
    component: EditProfile
  },
  {
    path: '/reviews',
    component: Reviews
  },
  {
    path: '/change_password',
    component: UpdatePassword
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/referral',
    component: Referral
  },
  {
    path: '/help_support',
    component: HelpSupportPage
  },
  {
    path: '/favourites',
    component: FavouriteGoGetters
  },
  {
    path: '/announcements/:id',
    component: Announcement
  },
  {
    path: '/notifications',
    component: Notification
  },
  {
    path: '/jobs',
    component: MyJobs
  },
  {
    path: '/jobs/:id/edit',
    component: EditJob
  },
  {
    path: '/jobs/:id/messages',
    component: Chat
  },
  {
    path: '/jobs/:id/receipt',
    component: Receipt
  },
  {
    path: '/credits/:id/receipt',
    component: CreditReceipt
  },
  {
    path: '/jobs/:id/reports',
    component: Report
  },
  {
    path: '/templates',
    component: Templates
  },
  {
    path: '/rewards',
    component: Rewards
  },
  {
    path: '/lazada_orders_management',
    component: LazadaOrdersManagement
  },
  {
    path: '/gg_inspector',
    component: GgInspector
  },
  {
    path: '/gogetter/:gogetterId',
    component: GoGetterProfile
  },
  {
    path: '/verify_customer',
    component: VerifyCustomer
  },
  {
    path: '/jobs/:id/:showGGModal',
    component: MyJobs,
    mobileComponent: JobShow
  },
  {
    path: '/jobs/:id',
    component: MyJobs,
    mobileComponent: JobShow
  },
  {
    path: '/delete_account',
    component: DeleteAccount
  },
  {
    path: '/hiring_posts',
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id',
    mobileComponent: HiringPostsJobDetails,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/edit',
    component: HiringPostEdit
  },
  {
    path: '/hiring_posts/:id/applicants',
    mobileComponent: ListofApplicants,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/applicants/:applicantId',
    mobileComponent: ApplicantDetails,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/applicants/:applicantId/reviews',
    mobileComponent: ApplicantsReview,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/receipt',
    component: HiringPostsReceipt
  }
];
