export const BLASTPOOL_ANYONE = 'anyone';
export const BLASTPOOL_FAVOURITES = 'favourites';
export const BLASTPOOL_TEAMS = 'teams';

export const ANY_GOGETTER = 'any_gogetter';
export const ALL_FAVOURITE_GOGETTER = 'all_favourite_gogetter';
export const SINGLE_FAVOURITE_GOGETTER = 'single_favourite_gogetter';
export const ALL_TRAINED_GOGETTER = 'all_trained_gogetter';
export const SINGLE_TRAINED_GOGETTER = 'single_trained_gogetter';

export const gogetterSelection = job => {
  const blastPool = job.blast_pool;
  const selectedGoGetter = job.selected_gogetter_id;

  if (blastPool === BLASTPOOL_ANYONE) {
    return ANY_GOGETTER;
  } else if (blastPool === BLASTPOOL_FAVOURITES && !selectedGoGetter) {
    return ALL_FAVOURITE_GOGETTER;
  } else if (blastPool === BLASTPOOL_FAVOURITES && selectedGoGetter) {
    return SINGLE_FAVOURITE_GOGETTER;
  } else if (blastPool === BLASTPOOL_TEAMS && !selectedGoGetter) {
    return ALL_TRAINED_GOGETTER;
  } else if (blastPool === BLASTPOOL_TEAMS && selectedGoGetter) {
    return SINGLE_TRAINED_GOGETTER;
  }
};
