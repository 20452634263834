import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import styled from 'styled-components/macro';
import Moment from 'react-moment';

import Avatar from '@material-ui/core/Avatar';

import GoGetRunningMan from '../icons/DefaultGoGetter.svg';

import { readNotification } from '../../../actions/notification';
import { clickNotification } from '../../../libraries/notification';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

const StyledContainer = styled.div`
  width: ${props => (props.isInAppNotification ? 'calc(100% - 16px)' : '100%')};
  height: ${props => !props.isInAppNotification && '72px'};
  background-color: ${props =>
    !props.isRead && !props.isInAppNotification && `${colors.greyLight}`};
  padding: 8px;
  display: flex;
  cursor: pointer;
`;
const AvatarWrapper = styled.div`
  margin-left: 8px;
`;
const ContentWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
  min-width: 150px;
`;
const NotificationFrom = styled.div`
  font-size: ${fontSize.large};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;
const NotificationContent = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.greyDark};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  display: -webkit-box;
  line-height: 21px;
  max-height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const DateWrapper = styled.div`
  font-size: ${fontSize.small};
  color: ${colors.black}
  float: right;
  margin-right: 1.5rem;
  text-align: right;
  display: ${props => props.isInAppNotification && 'none'};
`;

class NotificationBox extends Component {
  handleNotificationClick = () => {
    const {
      notifiableId,
      action,
      notificationId,
      push,
      read,
      readNotification
    } = this.props;

    clickNotification(
      notificationId,
      notifiableId,
      action,
      read,
      push,
      readNotification
    );
  };

  render() {
    const { read, avatar, createdAt, content, title, isInAppNotification } =
      this.props;
    return (
      <>
        <StyledContainer
          onClick={this.handleNotificationClick}
          isInAppNotification={isInAppNotification}
          isRead={read}
        >
          <AvatarWrapper>
            <Avatar src={avatar || GoGetRunningMan} />
          </AvatarWrapper>
          <ContentWrapper>
            <NotificationFrom>{title}</NotificationFrom>
            <NotificationContent>{content}</NotificationContent>
          </ContentWrapper>
          <DateWrapper isInAppNotification={isInAppNotification}>
            <div>
              <Moment format="DD MMM">{createdAt}</Moment>
            </div>
            <div>
              <Moment format="hh:mm A">{createdAt}</Moment>
            </div>
          </DateWrapper>
        </StyledContainer>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  push: id => dispatch(push(id)),
  readNotification: id => dispatch(readNotification(id))
});

export default connect(null, mapDispatchToProps)(NotificationBox);
