import { oneJobFeeCalculation } from './massJobCalculationService';

const getMassFeeBreakdown = job => {
  const mappedFees = getRecommendedHelperFee(job.helper_type);

  const isSingleJob =
    job?.schedules?.length === 1 && job?.schedules[0]?.no_of_gogetters === 1;

  return {
    'final': isSingleJob
      ? oneJobFeeCalculation(job.schedules[0], mappedFees.suggestedFee)
      : mappedFees.suggestedFee,
    min_fee: isSingleJob
      ? oneJobFeeCalculation(job.schedules[0], mappedFees.minimumFee)
      : mappedFees.minimumFee,
    total: isSingleJob
      ? oneJobFeeCalculation(job.schedules[0], mappedFees.suggestedFee)
      : mappedFees.suggestedFee
  };
};

const getRecommendedHelperFee = helperType => {
  let suggestedFee;
  let minimumFee;

  if (['promoter'].includes(helperType)) {
    suggestedFee = 17;
    minimumFee = 16;
  } else if (['personal_assistant'].includes(helperType)) {
    suggestedFee = 15;
    minimumFee = 10;
  } else if (['flyering'].includes(helperType)) {
    suggestedFee = 17;
    minimumFee = 14;
  } else if (['bulky'].includes(helperType)) {
    suggestedFee = 15;
    minimumFee = 15;
  } else if (['general_labour', 'queue_in_line'].includes(helperType)) {
    suggestedFee = 12;
    minimumFee = 12;
    // This is event_staff
  } else if (['event_crew'].includes(helperType)) {
    suggestedFee = 14;
    minimumFee = 11;
  } else if (['Kitchen_Helper', 'retail_staff'].includes(helperType)) {
    suggestedFee = 12;
    minimumFee = 9;
  } else if (['floor_staff', 'waiter_server'].includes(helperType)) {
    suggestedFee = 13;
    minimumFee = 10;
  } else if (['manning_booth', 'data_entry'].includes(helperType)) {
    suggestedFee = 12;
    minimumFee = 10;
  } else {
    suggestedFee = 12;
    minimumFee = 9;
  }

  return {
    suggestedFee,
    minimumFee
  };
};

export { getMassFeeBreakdown, getRecommendedHelperFee };
