import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Div100vh from 'react-div-100vh';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import colors from '../../style/colors';
import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import { updatePassword, validateResetPasswordToken } from '../../api/auth';
import { enqueueSnackbar } from '../../actions/snackbar';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;

const SpinnerWrapper = styled.div`
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;

const ResetPassword = props => {
  const { match, history, desktopLayout, enqueueNewSnackbar } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isShowingConfirmPassword, setIsShowingConfirmPassword] =
    useState(false);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    async function validatePasswordToken() {
      const response = await validateResetPasswordToken(match.params.token);

      if (response.isSuccess) {
        setIsLoading(false);
      } else {
        history.push('/login');
      }
    }

    if (!match.params.token) {
      history.push('/forgot_password');
    }

    if (match.params.token) {
      validatePasswordToken();
    }
  }, []);

  const onBack = () => {
    history.push('/login');
  };

  const handleResetPassword = async () => {
    if (password && confirmPassword && password === confirmPassword) {
      setIsResetting(true);

      const response = await updatePassword(match.params.token, password);

      if (response.isSuccess) {
        setIsResetting(false);
        enqueueNewSnackbar({
          message: response.message,
          options: {
            variant: 'success'
          }
        });
        history.push('/login', { email: response.email });
      } else {
        setIsResetting(false);
      }
    }
  };

  const isPasswordValid = !password || (password && password.length >= 8);
  const isConfirmPasswordValid =
    !confirmPassword || (confirmPassword && confirmPassword === password);

  return (
    <>
      <FixedHeaderAppBar
        isMobile={!desktopLayout}
        title="Reset password"
        onBackClick={onBack}
      />
      <Container
        desktopLayout={desktopLayout}
        style={{ height: 'calc(100vh - 64px)' }}
      >
        {isLoading ? (
          <SpinnerWrapper>
            <CircularProgress size={35} />
          </SpinnerWrapper>
        ) : (
          <Card desktopLayout={desktopLayout}>
            <TextField
              name="password"
              label="Password"
              type={isShowingPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setIsShowingPassword(!isShowingPassword)}
                    >
                      {isShowingPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={
                !isPasswordValid && 'Password must be min. 8 characters'
              }
              error={!isPasswordValid}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type={isShowingConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle confirm password visibility"
                      onClick={() =>
                        setIsShowingConfirmPassword(!isShowingConfirmPassword)
                      }
                    >
                      {isShowingConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={!isConfirmPasswordValid && 'Password does not match'}
              error={!isConfirmPasswordValid}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <BtnWrapper>
              <CtaButton
                disabled={
                  isResetting ||
                  !password ||
                  !isPasswordValid ||
                  !confirmPassword ||
                  !isConfirmPasswordValid
                }
                onClick={handleResetPassword}
              >
                RESET PASSWORD
              </CtaButton>
            </BtnWrapper>
          </Card>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    desktopLayout: state && state.layout && state.layout.desktopLayout
  };
};

const mapDispatchToProps = dispatch => {
  return {
    enqueueNewSnackbar: properties => dispatch(enqueueSnackbar(properties))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
