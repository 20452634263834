import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Close from '@material-ui/icons/Close';

import NatureOfUsageToggle from './NatureOfUsageToggle';

import { KYCServices, industries } from '../kyc/constants/kycServices';
import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

import { getKycStatus, updateUserKycDetails } from '../../actions/profile';
import { getUserUsageInfo, updateUserUsageInfo } from '../../api/profile';
import { enqueueSnackbar } from '../../actions/snackbar';

const DialogTitle = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;

const DialogName = styled.span`
  color: ${colors.primary};
`;

const DialogSubtitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 400;
  margin: 1rem 0;
`;

const BusinessInputContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.desktopLayout ? 'row' : 'column')};
  align-items: center;
  justify-content: space-between;
`;

const InputWrap = styled.div`
  flex: 1;
  width: ${props => !props.desktopLayout && '100%'};
  margin: ${props => (props.desktopLayout ? '1rem 0' : '0.5rem 0')};
  margin-right: ${props => props.right && props.desktopLayout && '0.5rem'};
  margin-left: ${props => props.left && props.desktopLayout && '0.5rem'};
  margin-top: ${props => props.right && !props.desktopLayout && '1rem'};
`;

const ServicesBox = styled.div`
  margin: 1rem 0;
  background-color: ${colors.greyLight};
  border-radius: 6px;
  padding: 1rem 0.75rem;
  display: flex;
`;

const ServiceImageWrap = styled.div`
  width: 20%;
  display: ${props => (props.desktopLayout ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const ServiceImage = styled.img`
  width: ${props => (props.small ? '15%' : '65%')};
  margin-right: ${props => props.right && '0.75rem'};
  display: ${props => props.hidden && 'none'};
`;

const ServicesContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ServicesTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ServicesTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
`;

const ServicesBadgesContainer = styled.div`
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrap = styled.div`
  margin: 1rem 0;
`;

const StyledChip = styled(Chip)`
  && {
    background-color: ${props =>
      props.active ? colors.primary : colors.white};
    color: ${props => (props.active ? colors.white : colors.black)};
    border: 2px solid ${props => (props.active ? colors.primary : colors.grey)};

    :focus,
    :hover {
      background-color: ${props =>
        props.active ? colors.primary : colors.white};
    }
  }
`;

const ChipWrap = styled.div`
  margin: 0.5rem 0rem;
  margin-right: 0.5rem;
`;

const DismissWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledClose = styled(Close)`
  cursor: pointer;
`;

const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

const ServiceInterestsDialog = props => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    business_details: {
      company_name: '',
      industry: ''
    },
    nature_of_usage: 'personal',
    service_interests: []
  });

  const {
    profile,
    desktopLayout,
    isDiscover,
    enqueueNewSnackbar,
    isOpen,
    isEdit,
    handleDismiss,
    updateKycInfo,
    dispatchGetKycStatus
  } = props;

  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await getUserUsageInfo();

      if (response?.isSuccess) {
        setUserInfo({
          business_details: {
            company_name: response?.data?.business_details?.company_name || '',
            industry: response?.data?.business_details?.industry || ''
          },
          nature_of_usage: response?.data?.nature_of_usage || 'personal',
          service_interests: response?.data?.service_interests || []
        });
      }

      setIsLoading(false);
    };
    if (isOpen) {
      fetchUserInfo();
    }
  }, [isOpen]);

  const handleNatureOfUsage = value => {
    const newData = { ...userInfo };

    newData.nature_of_usage = value;
    newData.service_interests = [];

    setUserInfo(newData);
  };

  const handleSelectTag = value => {
    const newData = { ...userInfo };

    if (newData.service_interests.includes(value)) {
      newData.service_interests = newData.service_interests.filter(
        tag => tag !== value
      );
    } else {
      newData.service_interests = [...newData.service_interests, value];
    }

    setUserInfo(newData);
  };

  const handleBusinessData = e => {
    const newData = { ...userInfo };

    newData.business_details[e.target.name] = e.target.value;

    setUserInfo(newData);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const response = await updateUserUsageInfo(userInfo);

    if (response?.isSuccess) {
      enqueueNewSnackbar({
        message:
          isDiscover || isEdit
            ? 'Profile updated!'
            : 'Got it, thanks for sharing!',
        options: {
          variant: 'success'
        }
      });

      updateKycInfo(profile, response?.updatedUserData);
      dispatchGetKycStatus();
      handleDismiss();
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  };

  const hasSelectedService = userInfo?.service_interests?.length > 0;
  const isBusinessValid =
    userInfo?.business_details?.company_name &&
    userInfo?.business_details?.industry;

  const isButtonDisabled =
    !hasSelectedService ||
    (userInfo.nature_of_usage === 'business' && !isBusinessValid);

  return (
    <Dialog
      open={isOpen}
      fullScreen={!desktopLayout}
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        {isEdit && (
          <DismissWrap>
            <StyledClose onClick={handleDismiss} />
          </DismissWrap>
        )}
        {isLoading ? (
          <SpinnerWrap>
            <CircularProgress size={45} />
          </SpinnerWrap>
        ) : (
          <>
            {isDiscover || isEdit ? (
              <>
                {isDiscover && (
                  <>
                    <DialogTitle>
                      Welcome back <DialogName>{profile?.name}</DialogName>,
                      tell us a bit more about you!
                    </DialogTitle>
                    <DialogSubtitle>
                      Get started by choosing your nature of usage:
                    </DialogSubtitle>
                  </>
                )}
                <NatureOfUsageToggle
                  desktopLayout={desktopLayout}
                  natureOfUsage={userInfo.nature_of_usage}
                  handleButton={handleNatureOfUsage}
                />
                {userInfo.nature_of_usage === 'business' && (
                  <BusinessInputContainer desktopLayout={desktopLayout}>
                    <InputWrap desktopLayout={desktopLayout} right>
                      <TextField
                        label="Company Name"
                        name="company_name"
                        variant="outlined"
                        value={userInfo.business_details.company_name}
                        onChange={e => handleBusinessData(e)}
                        fullWidth
                      />
                    </InputWrap>
                    <InputWrap desktopLayout={desktopLayout} left>
                      <TextField
                        select
                        label="Industry"
                        name="industry"
                        variant="outlined"
                        value={userInfo.business_details.industry}
                        onChange={e => handleBusinessData(e)}
                        fullWidth
                      >
                        {industries.map(industry => {
                          return (
                            <MenuItem key={industry} value={industry}>
                              {industry}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </InputWrap>
                  </BusinessInputContainer>
                )}
              </>
            ) : (
              <DialogTitle>
                While we connect you to a GoGetter, help us tailor a better
                GoGet experience for you!
              </DialogTitle>
            )}
            <DialogSubtitle>
              {isDiscover
                ? 'And which services are you interested in? You may select more than one.'
                : 'Which services are you interested in? You may select more than one.'}
            </DialogSubtitle>
            {KYCServices[userInfo?.nature_of_usage || 'personal'].boxes.map(
              box => {
                return (
                  <ServicesBox key={box.id}>
                    <ServiceImageWrap desktopLayout={desktopLayout}>
                      <ServiceImage src={box.icon} alt="icon" />
                    </ServiceImageWrap>
                    <ServicesContent>
                      <ServicesTitleWrap>
                        <ServiceImage
                          src={box.icon}
                          alt="icon"
                          hidden={desktopLayout}
                          small
                          right
                        />
                        <ServicesTitle>{box.title}</ServicesTitle>
                      </ServicesTitleWrap>
                      <ServicesBadgesContainer>
                        {box.tags.map(tag => {
                          return (
                            <ChipWrap key={`${box.id}-${tag.id}`}>
                              <StyledChip
                                label={tag.title}
                                active={userInfo?.service_interests.includes(
                                  tag.title
                                )}
                                onClick={() => handleSelectTag(tag.title)}
                              />
                            </ChipWrap>
                          );
                        })}
                      </ServicesBadgesContainer>
                    </ServicesContent>
                  </ServicesBox>
                );
              }
            )}
            <ButtonWrap>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={isButtonDisabled || isSubmitting}
                onClick={handleSubmit}
                fullWidth
              >
                DONE
              </Button>
            </ButtonWrap>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    desktopLayout: state?.layout?.desktopLayout,
    profile: state?.user?.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    enqueueNewSnackbar: config => dispatch(enqueueSnackbar(config)),
    updateKycInfo: (profile, userData) =>
      dispatch(updateUserKycDetails(profile, userData)),
    dispatchGetKycStatus: () => dispatch(getKycStatus())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceInterestsDialog);
