import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Div100vh from 'react-div-100vh';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import { logout } from '../../libraries/authentication';
import { setSrcPath } from '../../libraries/ApplicationService';

const Container = styled(Div100vh)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  color: ${colors.greyDark};
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const Img = styled.img`
  width: 100px;
  margin: 0 auto;
  display: block;
`;
const Message = styled.div`
  font-size: 1.7rem;
  color: ${colors.primary};
  margin-bottom: 4rem;
`;
const FinePrint = styled.div`
  font-size: ${fontSize.large};
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const StyledButton = styled(Button)`
  width: calc(100% - 8rem);
  color: ${colors.white} !important;
`;
const LoutOut = styled.span`
  color: ${colors.primary};
  font-weight: 600;
  cursor: pointer;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });

    Sentry.withScope(scope => {
      scope.setExtra(info);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      const { desktopLayout } = this.props;
      return (
        <>
          <FixedHeaderAppBar showWallet isMobile={!desktopLayout} />
          <LinearProgress />
          <Container style={{ height: 'calc(100rvh - 68px)' }}>
            <Card>
              <Img src={setSrcPath('/black_logo.png')} />
              <Message>Something Went Wrong</Message>
              <StyledButton variant="contained" color="primary" href="/">
                Go back
              </StyledButton>
              <FinePrint>
                If that doesn&apos;t work, try{' '}
                <LoutOut onClick={logout}>signing out </LoutOut>and back in.
              </FinePrint>
            </Card>
          </Container>
        </>
      );
    }
    return <> {children} </>;
  }
}

export default ErrorBoundary;
