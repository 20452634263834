import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import GGPopper from './GGPopper';
import { useLocation } from 'react-router';
import { getAuthToken } from '../../libraries/authentication';

const PopperWrap = styled(GGPopper)`
  z-index: 999999 !important;
`;

const NewProfilePopper = props => {
  const [isPopperShowing, setIsPopperShowing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const { anchorId, isMobile } = props;

  useEffect(() => {
    const el = document.getElementById(anchorId);
    if (el) {
      setAnchorEl(el);
    }
  }, []);

  useEffect(() => {
    const value = localStorage.getItem('new-profile-popover');

    if (getAuthToken() && !value) {
      setIsPopperShowing(true);
    }
  }, []);

  const handleDismissPopper = () => {
    localStorage.setItem('new-profile-popover', true);
    setIsPopperShowing(false);
  };

  if (!anchorEl) {
    return null;
  }

  return (
    <PopperWrap
      open={isPopperShowing && !location.pathname.includes('profile')}
      anchorEl={anchorEl}
      arrowPosition={isMobile ? 'bottom' : 'top'}
      placement={isMobile ? 'top' : 'bottom'}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={1}>
          <Typography variant="p">
            Check out your <strong>NEW</strong> Profile page!
          </Typography>
          <Button onClick={handleDismissPopper} style={{ color: 'white' }}>
            <strong>GOT IT</strong>
          </Button>
        </Box>
      </Box>
    </PopperWrap>
  );
};

export default NewProfilePopper;
