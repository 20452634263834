import React from 'react';
import styled from 'styled-components/macro';

import NAUHomeAndLife from '../assets/NAUHomeAndLife.svg';
import NAUBusiness from '../assets/NAUBusiness.svg';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const NatureOfUsageWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
`;
const NatureOfUsageButton = styled.div`
  width: ${({ desktopLayout }) => !desktopLayout && '100%'};
  box-sizing: border-box;
  height: 60px;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: ${props =>
    props.active
      ? `1px solid ${colors.secondary}`
      : `1px solid ${colors.grey}`};
  border-radius: 4px;
  cursor: pointer;
`;
const IconWrap = styled.div`
  padding-right: 1rem;
`;
const ButtonIcon = styled.img`
  height: 100%;
`;
const ButtonText = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  text-align: center;
  margin-right: 0.5rem;
`;
const OrText = styled.div`
  font-size: ${fontSize.regular};
  font-weight: 400;
  margin: 0 1rem;
`;

export const PERSONAL_USAGE = 'personal';
export const BUSINESS_USAGE = 'business';

const NatureOfUsageToggle = ({
  natureOfUsage,
  handleButton,
  desktopLayout
}) => {
  return (
    <NatureOfUsageWrap desktopLayout={desktopLayout}>
      <NatureOfUsageButton
        onClick={() => handleButton(PERSONAL_USAGE)}
        active={natureOfUsage === PERSONAL_USAGE}
        data-testid={`button-${PERSONAL_USAGE}`}
      >
        <IconWrap>
          <ButtonIcon src={NAUHomeAndLife} alt="Personal" />
        </IconWrap>
        <ButtonText>Personal</ButtonText>
      </NatureOfUsageButton>
      <OrText desktopLayout={desktopLayout}>OR</OrText>
      <NatureOfUsageButton
        onClick={() => handleButton(BUSINESS_USAGE)}
        active={natureOfUsage === BUSINESS_USAGE}
        data-testid={`button-${BUSINESS_USAGE}`}
      >
        <IconWrap>
          <ButtonIcon src={NAUBusiness} alt="Business" />
        </IconWrap>
        <ButtonText>Business</ButtonText>
      </NatureOfUsageButton>
    </NatureOfUsageWrap>
  );
};

export default NatureOfUsageToggle;
