import { UPDATE_CREDITS } from '../reducers/constants/credits';
import { fetchCreditsRemaining } from '../api/credits';
import { getAuthToken } from '../libraries/authentication';

export const updateCredits = (creditsRemaining, creditsExpiration) => ({
  type: UPDATE_CREDITS,
  creditsRemaining: Number(creditsRemaining),
  creditsExpiration
});

export const getCreditsRemaining = () => {
  if (!getAuthToken()) return { type: null };
  return async dispatch => {
    const response = await fetchCreditsRemaining();

    if (response?.isSuccess) {
      const { creditsExpiration, creditsRemaining } = response;
      dispatch(updateCredits(creditsRemaining, creditsExpiration));
    }
  };
};
