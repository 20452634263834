import { del } from 'idb-keyval';
import jwt from 'jsonwebtoken';
import { unregisterCordovaPush } from './cordovaNotification';
import Pusher from 'pusher-js';

const today = new Date();
const expiry = new Date(today.getTime() + 30 * 86400 * 1000); // plus 30 days

const resetOnLogout = () => {
  window.analytics.reset();
  del('user-id');
  window.localStorage.removeItem('user-phone-verified');
  window.localStorage.removeItem('user-phone-number');
  window.localStorage.removeItem('user-email-address');
  window.localStorage.removeItem('persist:root');
  window.localStorage.removeItem('goget-auth-token');
  window.localStorage.removeItem('is-first-time-goget-pay-tutorial-displayed');
  deleteCookie('goget-auth-token');
  deleteCookie('user-phone-verified');
  deleteCookie('user-phone-number');
  deleteCookie('user-email-address');
  deleteCookie('utm_campaign');
  deleteCookie('utm_medium');
  deleteCookie('utm_source');
  deleteCookie('atid');
};

export function setCookie(name, value) {
  if (window.cordova) {
    if (process.env.REACT_APP_SERVER_TYPE_EXT === 'develop') {
      return window.localStorage.setItem(name, value);
    }
    let tokenExpiry;
    if (value && value !== 'false' && name === 'goget-auth-token') {
      const decoded = jwt.verify(value, process.env.REACT_APP_JWT_SECRET);
      tokenExpiry = decoded.exp;
    }
    const lsToken = {
      value,
      exp: tokenExpiry || expiry / 1000
    };
    window.localStorage.setItem(
      name,
      name === 'goget-auth-token' ? JSON.stringify(lsToken) : value
    );
  } else if (process.env.REACT_APP_SERVER_TYPE_EXT === 'develop') {
    document.cookie = `${name}=${escape(
      value
    )}; expires=${expiry.toGMTString()}; path=/;`;
  } else {
    document.cookie = `${name}=${escape(
      value
    )}; expires=${expiry.toGMTString()}; path=/; Secure`;
  }
}

export function getCookie(name) {
  if (window.cordova) {
    if (process.env.REACT_APP_SERVER_TYPE_EXT === 'develop') {
      return window.localStorage.getItem(name);
    }
    const token =
      name === 'goget-auth-token'
        ? JSON.parse(window.localStorage.getItem(name))
        : window.localStorage.getItem(name);
    if (token) {
      if (name === 'goget-auth-token' && token.exp < today.getTime() / 1000) {
        resetOnLogout();
        setTimeout(() => {
          unregisterCordovaPush();
        }, 5000);
        return null;
      }
      return name === 'goget-auth-token' ? token.value : token;
    } else {
      return null;
    }
  } else {
    const re = new RegExp(`${name}=([^;]+)`);
    const value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  }
}

export function getAuthToken() {
  return getCookie('goget-auth-token');
}

export function deleteCookie(name) {
  if (window.cordova) {
    window.localStorage.removeItem(name);
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export function logout() {
  unregisterCordovaPush();
  resetOnLogout();
  setTimeout(() => {
    window.location.reload();
    window.localStorage.removeItem('persist:root');
  }, 700);
}

export const initPusher = userId => {
  if (!userId || !getAuthToken()) {
    return {
      channel: undefined,
      pusher: undefined
    };
  }

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: 'ap1',
    channelAuthorization: {
      endpoint: `${process.env.REACT_APP_SERVER_PATH}/pusher/auth?channel_name=presence-channel-${userId}`,
      headers: {
        'Authorization': getAuthToken()
      }
    }
  });
  const channel = pusher?.subscribe(`presence-channel-${userId}`);

  return {
    channel,
    pusher
  };
};
