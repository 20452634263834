import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';
import { isEmail } from 'validator';
import { push } from 'connected-react-router';
import Div100vh from 'react-div-100vh';
import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import { resetPassword } from '../../actions/auth';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const Description = styled.div`
  font-size: ${fontSize.large};
  line-height: 21px;
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;

class ForgotPassword extends Component {
  state = {
    email: ''
  };

  componentDidMount() {
    const { location } = this.props;

    if (location && location.state && location.state.email) {
      this.setState({
        email: location.state.email
      });
    }

    window.analyticsPage('forgot_password', { platform: 'pwa' });
  }

  handleOnChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  handleClick = () => {
    const { email } = this.state;
    if (email) {
      this.props.resetPassword(email);
    }
  };

  onBack = () => {
    const { history } = this.props;
    history.push('/login');
  };

  render() {
    const { email } = this.state;
    const { desktopLayout } = this.props;
    const emailValid = !email || isEmail(email);
    return (
      <>
        <FixedHeaderAppBar
          isMobile={!desktopLayout}
          title="Forgot password"
          onBackClick={this.onBack}
        />
        <Container
          desktopLayout={desktopLayout}
          style={{ height: 'calc(100rvh - 64px)' }}
        >
          <Card desktopLayout={desktopLayout}>
            <div>
              <Description>
                Enter your email address. We’ll e-mail you instructions on how
                to reset your password.
              </Description>
              <TextField
                name="email"
                onChange={e => this.handleOnChange(e)}
                label="Email"
                value={email}
                margin="normal"
                variant="outlined"
                fullWidth
                helperText={
                  !emailValid ? 'Please enter a valid email address.' : ''
                }
                error={!emailValid}
              />
            </div>
            <BtnWrapper>
              <CtaButton disabled={!email} onClick={this.handleClick}>
                SEND ME AN EMAIL NOW
              </CtaButton>
            </BtnWrapper>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = dispatch => ({
  resetPassword: email => dispatch(resetPassword(email)),
  push: () => dispatch(push())
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
