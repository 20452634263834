import React, { Component } from 'react';
import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import Div100vh from 'react-div-100vh';

const SpinnerWrap = styled(Div100vh)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Spinner = styled.div`
  flex: none;
`;

class LoadingData extends Component {
  componentDidMount() {
    this.checkLoading();
    this.updateLoading = setInterval(this.checkLoading, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.updateLoading);
  }

  checkLoading = () => {
    if (window.localStorage.getItem('loading-localStorage') === 'false') {
      window.location.reload();
    }
  };

  render() {
    return (
      <SpinnerWrap>
        <Spinner>
          <CircularProgress size={40} />
        </Spinner>
      </SpinnerWrap>
    );
  }
}

export default LoadingData;
