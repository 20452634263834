/* eslint-disable */
import { getProfile } from '../actions/profile';
import { getAuthToken } from './authentication';

export function getUrlParameter(location, name) {
  const queryParams = new URLSearchParams(location?.search);
  return queryParams.get(name);
}

export function resetState() {
  window.localStorage.removeItem('persist:root');
  setTimeout(() => {
    window.location.reload();
    window.localStorage.removeItem('persist:root');
    if (getAuthToken()) {
      this.props.dispatch(getProfile());
    }
  }, 700);
}

export function detectIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    window.location.href = '/ie.html';
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    window.location.href = '/ie.html';
  }
}

export function setSrcPath(path) {
  if (
    window.cordova &&
    path &&
    !(path.includes('http://') || path.includes('https://'))
  ) {
    return `${window.cordova.file.applicationDirectory}www${path}`;
  } else {
    return path;
  }
}

export const trimFilename = (filename, delimiter, replacement) => {
  if (!filename || !delimiter || !replacement) return;

  const splitFile = filename.split(delimiter);

  if (splitFile[0]?.length >= 18) {
    return `${splitFile[0].slice(0, 18)}${replacement}`;
  } else {
    return filename;
  }
};

export const timer = ms => new Promise(res => setTimeout(res, ms));

export const trimMaxLength = (string, maxLength) => {
  if (String(string).length > maxLength) {
    return `${string.substring(0, maxLength)}...`;
  } else {
    return string;
  }
};

export const needUpadte = (prep => {
  prep = t =>
    `${t}`
      // treat non-numerical characters as lower version
      // replacing them with a negative number based on charcode of first character
      .replace(
        /[^0-9\.]+/g,
        c =>
          `.${
            c
              .replace(/[\W_]+/, '')
              .toLowerCase()
              .charCodeAt(0) - 65536
          }.`
      )
      // remove trailing "." and "0" if followed by non-numerical characters (1.0.0b);
      .replace(/(?:\.0+)*(\.-[0-9]+)(\.[0-9]+)?\.*$/g, '$1$2')
      .split('.');

  return (a, b, c, i, r) => {
    a = prep(a);
    b = prep(b);
    for (i = 0, r = 0, c = Math.max(a.length, b.length); i < c && !r; i++) {
      r = -1 * ((a[i] = ~~a[i]) < (b[i] = ~~b[i])) + (a[i] > b[i]);
    }
    return r === 1;
  };
})();
