/* eslint-disable */
import { getCookie } from '../../libraries/authentication';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';
import { isEmail, isMobilePhone } from 'validator';
import Div100vh from 'react-div-100vh';
import { register } from '../../actions/auth';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import CtaButton from '../ui/CtaButton';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import GGPopover from '../ui/GGPopover';
import NatureOfUsageToggle from '../ui/NatureOfUsageToggle';
import { industries } from '../kyc/constants/kycServices';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const TextFieldWrapper = styled.div`
  margin-top: 24px;
`;
const PhoneTextField = styled(TextField)`
  margin-top: 0 !important;
`;
const TaxDescription = styled.div`
  font-size: ${fontSize.large};
  text-align: center;
  line-height: 20px;
  margin-bottom: 2rem;
  margin-top: 3rem;
`;
const TermsWrapper = styled.a`
  color: ${colors.primary};
`;
const StyledTextField = styled(TextField)`
  > div {
    padding-right: unset;
  }
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;
const EmailTextFieldWrapper = styled.div``;

const FormTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
`;

class SignUp extends Component {
  state = {
    email: '',
    name: '',
    password: '',
    phone_num: '60',
    referral_code: '',
    company_name: '',
    industry: '',
    showNewPassword: false,
    nature_of_usage: 'personal',
    popOverOpen: true
  };

  componentDidMount() {
    window.analyticsPage('sign_up', { platform: 'pwa' });
    this.name.focus();

    const currentReferralCode = getCookie('posterReferralCode');
    if (currentReferralCode) {
      this.setState({ referral_code: currentReferralCode, popOverOpen: false });
    }
  }

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClickSignUp = () => {
    this.props.register(this.state);
  };

  onBack = () => {
    const { history } = this.props;
    history.push('/home');
  };

  handleEnterPressed = e => {
    const { name, password, phone_num, nature_of_usage } = this.state;
    const code = e.keyCode || e.which;
    if (code === 13) {
      if (e.target.name === 'name') {
        this.email.focus();
      }
      if (e.target.name === 'email') {
        this.phone_num.focus();
      }
      if (e.target.name === 'phone_num') {
        this.password.focus();
      }
      if (e.target.name === 'password' && nature_of_usage === 'business') {
        this.company_name.focus();
      }
      if (
        name &&
        password &&
        phone_num &&
        this.password === document.activeElement
      ) {
        this.handleClickSignUp();
      }
    }
  };

  handleClosePopOver = () => {
    this.setState({ popOverOpen: false });
  };

  handleNatureOfUsageSelection = value => {
    this.setState({ nature_of_usage: value });
  };

  render() {
    const {
      email,
      name,
      password,
      phone_num,
      referral_code,
      company_name,
      industry,
      showNewPassword,
      nature_of_usage
    } = this.state;
    const { desktopLayout, loading } = this.props;
    const emailValid = !email || isEmail(email);
    const phoneValid =
      phone_num === '60' || isMobilePhone(phone_num, ['ms-MY']);
    const businessDetailsValid = company_name && industry;

    return (
      <>
        <FixedHeaderAppBar
          isMobile={!desktopLayout}
          title="Sign up"
          onBackClick={this.onBack}
        />
        <Container
          desktopLayout={desktopLayout}
          style={{ height: 'calc(100rvh - 64px)' }}
        >
          <Card desktopLayout={desktopLayout}>
            <div>
              <TextFieldWrapper>
                <FormTitle>Select your nature of usage:</FormTitle>
              </TextFieldWrapper>
              <TextFieldWrapper>
                <NatureOfUsageToggle
                  desktopLayout={desktopLayout}
                  natureOfUsage={nature_of_usage}
                  handleButton={this.handleNatureOfUsageSelection}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <FormTitle>Please fill in the following:</FormTitle>
                <TextField
                  name="name"
                  onChange={e => this.handleOnChange(e)}
                  label="Name"
                  value={name}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onKeyPress={e => this.handleEnterPressed(e)}
                  inputRef={el => (this.name = el)}
                />
              </TextFieldWrapper>
              <EmailTextFieldWrapper>
                <TextField
                  name="email"
                  inputRef={el => (this.email = el)}
                  onKeyPress={e => this.handleEnterPressed(e)}
                  onChange={e => this.handleOnChange(e)}
                  label="Email"
                  value={email}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  helperText={
                    !emailValid && 'Please enter a valid email address.'
                  }
                  error={!emailValid}
                />
              </EmailTextFieldWrapper>
              <TextFieldWrapper>
                <PhoneTextField
                  name="phone_num"
                  type="tel"
                  inputRef={el => (this.phone_num = el)}
                  onKeyPress={e => this.handleEnterPressed(e)}
                  onChange={e => this.handleOnChange(e)}
                  label="Phone No."
                  value={phone_num}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  helperText={
                    !phoneValid && 'Please enter a valid phone number.'
                  }
                  error={!phoneValid}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                <StyledTextField
                  variant="outlined"
                  inputRef={el => (this.password = el)}
                  onKeyPress={e => this.handleEnterPressed(e)}
                  type={showNewPassword ? 'text' : 'password'}
                  label="Password"
                  value={password}
                  name="password"
                  helperText={
                    password.length !== 0 && password.length < 8
                      ? 'Please enter a password that is atleast 8 characters long.'
                      : ''
                  }
                  error={password.length !== 0 && password.length < 8}
                  onChange={e => this.handleOnChange(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() =>
                            this.setState(state => ({
                              showNewPassword: !state.showNewPassword
                            }))
                          }
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </TextFieldWrapper>
              <TextFieldWrapper>
                {nature_of_usage === 'business' && (
                  <>
                    <FormTitle>Business details:</FormTitle>
                    <TextField
                      name="company_name"
                      onChange={e => this.handleOnChange(e)}
                      label="Company Name"
                      value={company_name}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      onKeyPress={e => this.handleEnterPressed(e)}
                      inputRef={el => (this.company_name = el)}
                    />
                    <TextField
                      select
                      name="industry"
                      label="Industry"
                      margin="normal"
                      onChange={e => this.handleOnChange(e)}
                      value={industry}
                      variant="outlined"
                      fullWidth
                    >
                      {industries.map(industry => {
                        return (
                          <MenuItem key={industry} value={industry}>
                            {industry}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </>
                )}
                <TextField
                  name="referral_code"
                  inputRef={el => {
                    this.referral_code = el;
                  }}
                  onKeyPress={e => this.handleEnterPressed(e)}
                  onChange={e => this.handleOnChange(e)}
                  onFocus={this.handleClosePopOver}
                  label="Referral Code"
                  value={referral_code}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <GGPopover
                  open={this.state.popOverOpen}
                  render={() => {
                    return (
                      <Box onClick={this.handleClosePopOver}>
                        <Typography>
                          Got a referral code? Enter it here to get rewarded!
                        </Typography>
                        <Box display="flex" justifyContent="flex-end">
                          <Button style={{ color: 'white' }}>GOT IT</Button>
                        </Box>
                      </Box>
                    );
                  }}
                />
              </TextFieldWrapper>
              <TaxDescription>
                By creating an account, you accept our{' '}
                <TermsWrapper
                  href={`${process.env.REACT_APP_STATIC_PATH}/terms_conditions/`}
                  target="_blank"
                >
                  Terms of Service
                </TermsWrapper>
              </TaxDescription>
            </div>
            <BtnWrapper>
              <CtaButton
                disabled={
                  !password ||
                  (nature_of_usage === 'business' && !businessDetailsValid)
                }
                onClick={this.handleClickSignUp}
              >
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} />
                ) : (
                  'SIGN UP'
                )}
              </CtaButton>
            </BtnWrapper>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => ({
  register: params => dispatch(register(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
