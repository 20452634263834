import colors from '../style/colors';

export const openPaymentLink = (url, push, onError) => {
  if (window.cordova) {
    let inAppBrowserRef;
    const target = '_blank';
    const options = `beforeload=get,hidenavigationbuttons=yes,closebuttoncaption=Cancel,toolbarcolor=${colors.greyDark},closebuttoncolor=${colors.primary}`;
    inAppBrowserRef = window.cordova.InAppBrowser.open(url, target, options);
    inAppBrowserRef.addEventListener('beforeload', (params, callback) => {
      if (params.url.startsWith(process.env.REACT_APP_HOST_PATH)) {
        const { pathname } = new URL(params.url);
        inAppBrowserRef.close();
        inAppBrowserRef = undefined;
        push(pathname);
      } else {
        callback(params.url);
      }
    });
    inAppBrowserRef.addEventListener('exit', onError);
  } else {
    window.location.href = url;
  }
};
