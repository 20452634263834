import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import axios from 'axios';
import { push } from 'connected-react-router';
import moment from 'moment';
import ImageSlider from '../../ui/ImageSlider';
import { getAuthToken } from '../../../libraries/authentication';
import fontSize from '../../../style/fontSize';
import { openPaymentLink } from '../../../libraries/openLink';
import { purchaseCreditPlan } from '../../../api/credits';

const SliderWrapper = styled.div`
  width: ${props => (props.ipadLayout ? '500px' : '350px')};
  margin: 0 auto;
`;
const BannerTitle = styled.div`
  display: ${props => props.ipadLayout && 'none'};
  font-size: ${fontSize.large};
  text-align: left;
  margin-top: ${props => props.ipadLayout && '1.5rem'};
  margin-bottom: 24px;
  font-weight: 600;
`;

class DesktopBanner extends Component {
  state = {
    images: []
  };

  componentDidMount() {
    const { isBusinessAccount } = this.props;

    let banners = [
      '/RecruitDesktopBanner.png',
      '/CashlessDesktopBanner.png',
      '/ReferAndEarnDesktop.png',
      '/OpenEverydayDesktop.png',
      '/SameDayBannerDesktop.png'
    ];
    const currentDate = moment();

    if (
      !isBusinessAccount &&
      currentDate.isBetween(moment('2021-01-18'), moment('2021-04-14'))
    ) {
      banners = ['/ConsumerDesktopV2.png'].concat(banners);
    }

    if (currentDate.isBetween(moment('2021-03-29'), moment('2021-05-17'))) {
      banners = ['/RayaDesktop.png'].concat(banners);
    }

    if (currentDate.isBetween(moment('2021-07-12'), moment('2021-09-30'))) {
      banners = ['/GoGetLokalDesktop.png'].concat(banners);
    }

    if (currentDate.isSameOrBefore(moment('2021-10-31'))) {
      banners = ['/Back2BusinessDesktop.png'].concat(banners);
    }

    this.setState({ images: banners });

    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/private/v1/festival_promos`)
      .then(({ data }) => {
        if (
          data &&
          data.data &&
          data.data.festival_banners &&
          data.data.festival_banners.desktop_img
        ) {
          this.setState({
            images: [data.data.festival_banners.desktop_img, ...banners]
          });
        }
      });
  }

  handleBannerClick = async (e, image) => {
    const { push, isBusinessAccount } = this.props;

    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'hero_banner',
      platform: 'pwa'
    });

    if (image && image.includes('SameDayBannerDesktop.png')) {
      push('/dispatch');
    } else if (image && image.includes('RecruitDesktopBanner.png')) {
      push('/part_timer/recruit');
    } else if (image && image.includes('ReferAndEarnDesktop.png')) {
      push('/referral');
    } else if (image && image.includes('HalloweenDesktop.jpg')) {
      push('/stores');
    } else if (image && image.includes('OpenEverydayDesktop.png')) {
      window.open('https://gogetmy.zendesk.com/hc/en-us/articles/900000373146');
    } else if (image && image.includes('CashlessDesktopBanner.png')) {
      push('/credits');
    } else if (image.includes('RayaDesktop.png')) {
      push('/rewards');
    } else if (image.includes('GoGetLokalDesktop.png')) {
      push('/food_shopping');
    } else if (image.includes('Back2BusinessDesktop.png')) {
      push('/part_timer');
    } else if (image.includes('ConsumerDesktopV2.png')) {
      if (window.cordova) {
        const response = await purchaseCreditPlan('54756ec2');

        if (response?.isSuccess) {
          openPaymentLink(response?.redirectLink, push, () => {});
        }
      } else {
        push('/credits?public_id=54756ec2');
      }
    } else {
      push('/credits?business=true');
    }
  };

  render() {
    const { ipadLayout } = this.props;
    const { images } = this.state;
    return (
      <>
        <SliderWrapper ipadLayout={ipadLayout}>
          <BannerTitle ipadLayout={ipadLayout}>What&apos;s new</BannerTitle>
          <ImageSlider
            isDesktopLayout={!ipadLayout}
            clickable="true"
            handleBannerClick={this.handleBannerClick}
            images={images}
          />
        </SliderWrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopBanner);
