export const REQUEST_JOBS = 'REQUEST_JOBS';
export const RECEIVE_JOBS = 'RECEIVE_JOBS';
export const RECEIVE_JOBS_SEARCH = 'RECEIVE_JOBS_SEARCH';
export const RESET_TOTAL = 'RESET_TOTAL';
export const UPDATE_RECEIVED_TAB = 'UPDATE_RECEIVED_TAB';
export const NO_UPDATES = 'NO_UPDATES';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const RESET_JOBS = 'RESET_JOBS';
export const SET_ACTIVE_JOB_ID = 'SET_ACTIVE_JOB_ID';
export const SET_ACTIVE_JOB_LOADING = 'SET_ACTIVE_JOB_LOADING';
