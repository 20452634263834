import React, { Suspense } from 'react';
import { Switch as ReactSwitch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { isTablet } from 'react-device-detect';

import DesktopLayout from './components/desktopLayout/DesktopLayout';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import PageLoadingBar from './components/ui/PageLoadingBar';
import ScrollToTop from './components/ScrollToTop';
import LoadingData from './components/loadingData/LoadingData';

import Discover from './components/discover/Discover';
import EditPhoneAndEmail from './components/auth/EditPhoneAndEmail';
import Page404 from './components/ui/Page404';
import PhoneVerification from './components/auth/PhoneVerification';

import { getAuthToken, getCookie } from './libraries/authentication';

import { privateRouters, authRouters } from './services/routers';
import { publicRouters } from './services/routers/publicRouters';

const PrivateRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={routeProps =>
        getAuthToken() !== null &&
        getCookie('user-phone-verified') === 'true' ? (
          <Component {...routeProps} {...ComponentProps} />
        ) : window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );
};

const AuthRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      getAuthToken() === null ? (
        window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Component {...props} {...ComponentProps} />
        )
      ) : getCookie('user-phone-verified') === 'true' ? (
        <Redirect to="/" />
      ) : (
        <Redirect to="/phone_verification" />
      )
    }
  />
);

const PhoneVerificationRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      getAuthToken() === null ? (
        window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Redirect to="/login" />
        )
      ) : getCookie('user-phone-verified') === 'true' ? (
        <Redirect to="/" />
      ) : window.localStorage.getItem('loading-localStorage') === 'true' ? (
        <LoadingData />
      ) : (
        <Component {...props} {...ComponentProps} />
      )
    }
  />
);

const Routers = props => {
  const { mobile, isTabletBrowser } = props;
  return (
    <DesktopLayout isMobile={mobile}>
      <ErrorBoundary desktopLayout={!mobile}>
        <Suspense fallback={<PageLoadingBar />}>
          <ScrollToTop />
          <ReactSwitch>
            <Switch>
              <Route
                path="/"
                render={routeProps => (
                  <Discover {...routeProps} desktopLayout={!mobile} />
                )}
                exact
              />
              <Route
                path="/edit_phone_and_email"
                render={routeProps => (
                  <EditPhoneAndEmail {...routeProps} desktopLayout={!mobile} />
                )}
                exact
              />
              <PhoneVerificationRoute
                path="/phone_verification"
                component={PhoneVerification}
                componentProps={{ desktopLayout: !mobile }}
                exact
              />
              {publicRouters.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  render={routeProps => (
                    <Component {...routeProps} desktopLayout={!mobile} />
                  )}
                  exact
                />
              ))}
              {privateRouters.map(privateRouter => (
                <PrivateRoute
                  key={privateRouter.path}
                  path={privateRouter.path}
                  component={
                    (mobile || isTablet || isTabletBrowser) &&
                    privateRouter?.mobileComponent
                      ? privateRouter?.mobileComponent
                      : privateRouter.component
                  }
                  componentProps={{ desktopLayout: !mobile, isTablet }}
                  exact
                />
              ))}
              {authRouters.map(authRouter => (
                <AuthRoute
                  key={authRouter.path}
                  path={authRouter.path}
                  component={authRouter.component}
                  componentProps={{ desktopLayout: !mobile }}
                  exact
                />
              ))}
              <Route
                render={routeProps => (
                  <Page404 {...routeProps} desktopLayout={!mobile} />
                )}
              />
            </Switch>
          </ReactSwitch>
        </Suspense>
      </ErrorBoundary>
    </DesktopLayout>
  );
};

export default Routers;
