import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import axios from 'axios';
import _ from 'lodash';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import Place from '@material-ui/icons/Place';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';
import ShopIcon from './our-merchants-icon.svg';
import SegmentHeader from './SegmentHeader';
import FixedHeaderAppBar from '../../ui/FixedHeaderAppBar';

import { getAuthToken } from '../../../libraries/authentication';

const SpinnerWrap = styled.div`
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Spinner = styled.div`
  flex: none;
`;
const StyledContentWrap = styled.div`
  width: 100% !important;
  margin: 0 !important;
  min-width: ${props =>
    !props.desktopLayout ? '300px' : props.ipadLayout ? '500px' : '600px'};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const StyledPaper = styled(Paper)`
  flex-direction: column;
  border-radius: 8px !important;
  width: 148px;
  min-height: 220px;
  order: 1;
  box-shadow: none !important;
  border: 1px solid ${colors.grey};
  cursor: pointer;
  margin: 8px;
`;
const ItemButton = styled(CardActionArea)`
  text-transform: none !important;
  display: block !important;
  padding-top: 0px !important;
  line-height: 1.5 !important;
  width: 100%;
  pointer-events: none;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const CardContentWrap = styled.div`
  padding: 8px;
  text-align: left;
`;
const Category = styled.div`
  font-size: ${fontSize.regular};
  margin-bottom: 8px;
`;
const Title = styled.div`
  font-size: ${fontSize.large};
  font-weight: 700;
  min-height: 2rem;
  line-height: ${fontSize.xxLarge};
  margin-bottom: 8px;
  white-space: pre-line;
`;
const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -2px;
`;
const PlaceIcon = styled(Place)`
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 4px;
`;
const Location = styled.div`
  font-size: ${fontSize.regular};
`;
const Card = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;
const ChipWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Chip = styled.div`
  font-size: ${fontSize.regular};
  border: ${props =>
    props.isActive
      ? `1px solid ${colors.primary}`
      : `1px solid ${colors.grey}`};
  border-radius: 16px;
  line-height: 1.25;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: ${props => !props.isFirst && '8px'};
  margin-right: 8px;
  text-align: center;
  cursor: pointer;
`;
const TilesTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fontSize.xLarge};
  margin: 16px;
  margin-bottom: 4px;
  width: 100%;
`;
const TitleTnc = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fontSize.regular};
  margin: 16px;
  margin-top: 4px;
  width: 100%;
`;
const Container = styled.div`
  flex: 1;
  padding-left: ${props => !props.ipadLayout && '16px'};
  padding-right: ${props => !props.ipadLayout && '32px'};
  display: flex;
  margin: 0 auto;
  width: ${props => props.ipadLayout && 'calc(100% - 16px)'};
  max-width: ${props => (props.ipadLayout ? '500px' : '600px')};
`;

const DesktopContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 8px auto;
  max-width: 1200px;
  padding: 8px;
`;

const Icon = styled.img`
  width: 20px;
`;

const ContentWrap = styled.div`
  display: ${props => props.isLoading && 'none'};
  .menu-wrapper--inner {
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

class MerchantShowcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMerchants: [],
      isLoading: true,
      selectedChip: 0
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.getMerchants();
  }

  getMerchants = () => {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/merchant_showcases`;
    const request = axios({
      method: 'GET',
      url
    });

    request.then(({ data }) => {
      if (data && data.data) {
        this.setState({
          allMerchants: data.data,
          isLoading: false
        });
      }
    });
  };

  onClickMerchant = (merchantName, merchantLink) => {
    const { isBusinessAccount } = this.props;
    const merchantNameFormatted = merchantName
      .split(' ')
      .join('_')
      .trim()
      .toLowerCase();
    if (getAuthToken() === null) {
      window.analytics.track('merchant_showcase_link', {
        category: 'discovery_visitor',
        label: 'merchant_showcase_link',
        merchant_name: merchantNameFormatted,
        platform: 'pwa'
      });
    } else {
      // eslint-disable-next-line
      if (isBusinessAccount) {
        window.analytics.track('merchant_showcase_link', {
          category: 'discovery_ba',
          label: 'merchant_showcase_link',
          merchant_name: merchantNameFormatted,
          platform: 'pwa'
        });
      } else {
        window.analytics.track('merchant_showcase_link', {
          category: 'discovery_consumer',
          label: 'merchant_showcase_link',
          merchant_name: merchantNameFormatted,
          platform: 'pwa'
        });
      }
    }
    this.onAnalyticsTrack('promo_banner');
    window.open(merchantLink);
  };

  handleChipClick = value => {
    this.setState({
      selectedChip: value
    });
  };

  onClickSeeAll = () => {
    const { history } = this.props;
    this.onAnalyticsTrack('promo_see_all');
    history.push('/partners');
  };

  onAnalyticsTrack = label => {
    const { isBusinessAccount } = this.props;
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label,
      platform: 'pwa'
    });
  };

  render() {
    const { allMerchants, isLoading, selectedChip } = this.state;

    const { desktopLayout, ipadLayout, short, history } = this.props;

    if (short) {
      if (desktopLayout) {
        return (
          <Container ipadLayout={ipadLayout}>
            <Card>
              <ShortContent
                merchants={allMerchants.slice(0, 6)}
                isLoading={isLoading}
                onClickMerchant={this.onClickMerchant}
                scrollRef={this.scrollRef}
                desktopLayout
                onClickSeeAll={this.onClickSeeAll}
              />
            </Card>
          </Container>
        );
      } else {
        return (
          <ShortContent
            merchants={allMerchants.slice(0, 6)}
            isLoading={isLoading}
            onClickMerchant={this.onClickMerchant}
            scrollRef={this.scrollRef}
            onClickSeeAll={this.onClickSeeAll}
          />
        );
      }
    } else if (desktopLayout) {
      return (
        <>
          <FixedHeaderAppBar
            showWallet="true"
            title="Exclusive Promotions"
            onBackClick={() => {
              history.push('/');
            }}
          />
          <DesktopContainer>
            <Card>
              <FullContent
                allMerchants={allMerchants}
                selectedChip={selectedChip}
                handleChipClick={this.handleChipClick}
                isLoading={isLoading}
                desktopLayout={desktopLayout}
                ipadLayout={ipadLayout}
                onClickMerchant={this.onClickMerchant}
              />
            </Card>
          </DesktopContainer>
        </>
      );
    } else {
      return (
        <>
          <FixedHeaderAppBar
            isMobile="true"
            title="Exclusive Promotions"
            onBackClick={() => {
              history.push('/');
            }}
          />
          <FullContent
            allMerchants={allMerchants}
            selectedChip={selectedChip}
            handleChipClick={this.handleChipClick}
            isLoading={isLoading}
            desktopLayout={desktopLayout}
            ipadLayout={ipadLayout}
            onClickMerchant={this.onClickMerchant}
          />
        </>
      );
    }
  }
}

const ShortContent = props => {
  const {
    merchants,
    isLoading,
    onClickMerchant,
    scrollRef,
    desktopLayout,
    onClickSeeAll
  } = props;
  return (
    <>
      <SegmentHeader
        title="Exclusive Promotions"
        subtitle="Check out these deals from our partners."
        desktopLayout={desktopLayout}
        icon={<Icon src={ShopIcon} />}
        rightArrowClick={
          scrollRef &&
          scrollRef.current &&
          scrollRef.current.handleArrowClickRight
        }
        leftArrowClick={
          scrollRef && scrollRef.current && scrollRef.current.handleArrowClick
        }
        seeAllClick={onClickSeeAll}
      />
      {isLoading && (
        <SpinnerWrap>
          <Spinner>
            <CircularProgress size={40} />
          </Spinner>
        </SpinnerWrap>
      )}
      <ContentWrap isLoading={isLoading}>
        <ScrollMenu
          data={HorizontalMerchants(merchants, onClickMerchant)}
          wheel={false}
          alignCenter={false}
          alignOnResize={false}
          ref={scrollRef}
          scrollBy={2}
          inertiaScrolling
        />
      </ContentWrap>
    </>
  );
};

const FullContent = props => {
  const {
    allMerchants,
    selectedChip,
    handleChipClick,
    isLoading,
    desktopLayout,
    ipadLayout,
    onClickMerchant
  } = props;

  const foodMerchants = _.filter(allMerchants, merchant => {
    return merchant.category === 'F&B';
  });

  const retailMerchants = _.filter(allMerchants, merchant => {
    return merchant.category === 'RETAIL';
  });

  return (
    <>
      <TilesTitle>Check out these deals from our partners.</TilesTitle>
      <TitleTnc>
        <a
          href="https://gogetmy.zendesk.com/hc/en-us/articles/900000681023-GoGet-Stay-Home-Partner-Promotion-T-Cs"
          target="_blank"
          rel="noopener noreferrer"
        >
          T&C applies
        </a>
      </TitleTnc>
      <ChipWrapper>
        <Chip
          isFirst
          isActive={selectedChip === 0}
          onClick={() => handleChipClick(0)}
        >
          All
        </Chip>
        <Chip isActive={selectedChip === 1} onClick={() => handleChipClick(1)}>
          F&B
        </Chip>
        <Chip isActive={selectedChip === 2} onClick={() => handleChipClick(2)}>
          Retail
        </Chip>
      </ChipWrapper>
      {isLoading ? (
        <SpinnerWrap>
          <Spinner>
            <CircularProgress size={40} />
          </Spinner>
        </SpinnerWrap>
      ) : (
        <StyledContentWrap
          desktopLayout={desktopLayout}
          ipadLayout={ipadLayout}
        >
          {selectedChip === 0 && (
            <RenderMerchants
              merchants={allMerchants}
              desktopLayout={desktopLayout}
              onClickMerchant={onClickMerchant}
            />
          )}
          {selectedChip === 1 && (
            <RenderMerchants
              merchants={foodMerchants}
              desktopLayout={desktopLayout}
              onClickMerchant={onClickMerchant}
            />
          )}
          {selectedChip === 2 && (
            <RenderMerchants
              merchants={retailMerchants}
              desktopLayout={desktopLayout}
              onClickMerchant={onClickMerchant}
            />
          )}
        </StyledContentWrap>
      )}
    </>
  );
};

const HorizontalMerchants = (merchants, onClickMerchant) => {
  return merchants.map(merchant => {
    return <RenderCard merchant={merchant} onClickMerchant={onClickMerchant} />;
  });
};

const RenderMerchants = props => {
  const { merchants, onClickMerchant } = props;
  return (
    <>
      {_.map(merchants, merchant => (
        <RenderCard merchant={merchant} onClickMerchant={onClickMerchant} />
      ))}
    </>
  );
};

const RenderCard = props => {
  const { merchant, onClickMerchant } = props;
  return (
    <StyledPaper
      onClick={() => onClickMerchant(merchant.name, merchant.link_path)}
    >
      <ItemButton>
        <StyledImage src={merchant.image_path} />
        <CardContentWrap>
          <Category>
            {merchant.category === 'F&B' ? 'Food & Beverage' : 'Retail'}
          </Category>
          <Title>{merchant.name}</Title>
          <LocationWrapper>
            <PlaceIcon />
            <Location>{merchant.coverage}</Location>
          </LocationWrapper>
        </CardContentWrap>
      </ItemButton>
    </StyledPaper>
  );
};

const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

export default connect(mapStateToProps)(MerchantShowcase);
