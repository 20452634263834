import _ from 'lodash';
import moment from 'moment';

const overallJobFeeCalculation = (job, fee) => {
  const scheduleFeesArray = _.map(job.schedules, schedule => {
    const hours =
      (moment(schedule.end_at) - moment(schedule.start_at)) / 360000 / 10;

    const oneJobFee =
      Math.ceil(fee * (hours < 1 ? 1 : hours)) * schedule.days.length;

    return oneJobFee * schedule.no_of_gogetters;
  });

  return _.sum(scheduleFeesArray);
};

const overallScheduleFeeCalculation = (schedule, fee) => {
  const hours =
    (moment(schedule.end_at) - moment(schedule.start_at)) / 360000 / 10;

  const oneJobFee =
    Math.ceil(fee * (hours < 1 ? 1 : hours)) * schedule.days.length;

  return oneJobFee * schedule.no_of_gogetters;
};

const oneJobFeeCalculation = (schedule, fee) => {
  const hours =
    (moment(schedule.end_at) - moment(schedule.start_at)) / 360000 / 10;

  const oneJobFee =
    Math.ceil(fee * (hours < 1 ? 1 : hours)) * schedule.days.length;

  return oneJobFee;
};

export {
  overallJobFeeCalculation,
  overallScheduleFeeCalculation,
  oneJobFeeCalculation
};
