import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Check from '@material-ui/icons/Check';

import GGMessageBox from '../../ui/GGMessageBox';
import LearnMoreDialog from './LearnMoreDialog';
import ProgressBar from './ProgressBar';

import VerifiedIcon from '../../../assets/icons/VerifiedIcon.svg';
import VerificationFailedIcon from '../../../assets/icons/failedVerification.svg';

import { trimMaxLength } from '../../../libraries/ApplicationService';

import fontSize from '../../../style/fontSize';
import colors from '../../../style/colors';
import {
  KYC_0_PERCENT,
  KYC_33_PERCENT,
  KYC_66_PERCENT,
  KYC_NOT_STARTED,
  KYC_STATUS_PENDING,
  KYC_STATUS_REJECTED,
  KYC_STATUS_STARTED,
  KYC_TIER_ONE,
  KYC_TIER_TWO
} from '../constants/kycServices';

const Content = styled.div``;
const StyledText = styled.div`
  font-size: ${fontSize.large};
  margin-top: ${({ marginTop }) => marginTop && '0.5rem'};
  line-height: 21px;
`;
const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;
const Name = styled.span`
  color: ${colors.primary};
  font-weight: bold;
`;
const StyledLink = styled.span`
  color: ${colors.primary};
  font-size: ${fontSize.regular};
  margin-left: 0.25rem;
  cursor: pointer;
`;
const TextWrap = styled.div`
  width: ${({ withCta }) => (withCta ? '65%' : '100%')};
`;
const ButtonWrap = styled.div`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-end')};
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
`;
const AvatarWrap = styled.div`
  position: relative;
  margin-right: 1.5rem;
`;
const StyledAvatar = styled(Avatar)`
  && {
    width: 50px;
    height: 50px;
  }
`;
const StyledVerifiedIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
  filter: grayscale(${({ isVerified }) => (isVerified ? '0' : '100%')});
`;
const FailureWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0.75rem;
    min-width: 0;

    svg {
      font-size: 1rem;
    }
  }
`;
const ListItemText = styled.div`
  font-size: ${fontSize.regular};
`;

const TierCard = props => {
  const [isLearnMoreModalShowing, setIsLearnMoreModalShowing] = useState(false);
  const {
    name,
    avatar,
    withFooterCta,
    tier,
    isVerified,
    verificationStatus,
    failureReasons,
    retryPossible,
    desktopLayout
  } = props;

  const isVerificationFailed =
    !isVerified &&
    [KYC_STATUS_PENDING, KYC_STATUS_STARTED, KYC_STATUS_REJECTED].includes(
      verificationStatus
    ) &&
    failureReasons?.length > 0;

  const renderTierMessage = () => {
    switch (tier) {
      case KYC_TIER_ONE:
        return (
          <>
            Did you know a complete profile gets{' '}
            <strong>better and faster</strong> matches?
          </>
        );
      case KYC_TIER_TWO:
        return (
          <>
            Seal the deal now - last step to get{' '}
            <strong>better and faster</strong> matches!
          </>
        );
      default:
        return '';
    }
  };

  const renderHelperText = () => {
    switch (tier) {
      case KYC_TIER_ONE:
        return (
          <>
            <StyledText>
              <strong>Add a profile photo and short bio</strong>
            </StyledText>
            <StyledText marginTop>
              GoGetters are more confident claiming jobs when they see a
              complete profile
            </StyledText>
          </>
        );
      case KYC_TIER_TWO:
        return (
          <>
            <StyledText>
              <strong>Verify your identity</strong>
            </StyledText>
            <StyledText marginTop>
              GoGetters feel safer doing jobs posted by credible and authentic
              users
            </StyledText>
          </>
        );
      default:
        return '';
    }
  };

  const renderCta = () => {
    const linkLocation =
      tier === KYC_TIER_ONE ? '/edit_profile' : '/verify_customer';
    const linkText = tier === KYC_TIER_ONE ? 'Update Profile' : 'Verify Now';
    return (
      <Link to={linkLocation}>
        <Button color="primary" fullWidth>
          {linkText}
        </Button>
      </Link>
    );
  };

  const tierPercentage = () => {
    switch (tier) {
      case KYC_NOT_STARTED:
        return KYC_0_PERCENT;
      case KYC_TIER_ONE:
        return KYC_33_PERCENT;
      case KYC_TIER_TWO:
        return KYC_66_PERCENT;
      default:
        return KYC_0_PERCENT;
    }
  };

  const renderVerificationMessage = () => {
    if (isVerificationFailed) {
      return (
        <FailureWrap>
          <strong>Verification Failed</strong>
        </FailureWrap>
      );
    } else {
      return (
        <TextWrap>
          <StyledText>
            Verification process usually takes <strong>1-3 minutes</strong>, but
            may take up to 2 days. You will be notified once it's completed.
          </StyledText>
        </TextWrap>
      );
    }
  };

  if (
    tier === KYC_TIER_TWO &&
    verificationStatus &&
    verificationStatus !== KYC_STATUS_STARTED
  ) {
    return (
      <>
        <StyledContainer center={isVerificationFailed}>
          <AvatarWrap>
            <StyledAvatar src={avatar} alt={name || 'User Avatar'} />
            <StyledVerifiedIcon
              src={
                isVerificationFailed && !retryPossible
                  ? VerificationFailedIcon
                  : VerifiedIcon
              }
              alt="verified status"
              isVerified={
                (isVerificationFailed && !retryPossible) || isVerified
              }
            />
          </AvatarWrap>
          {renderVerificationMessage()}
        </StyledContainer>
        {isVerificationFailed && (
          <>
            {failureReasons?.map(reason => {
              return (
                <List dense>
                  <ListItem>
                    <StyledListItemIcon>
                      <Check />
                    </StyledListItemIcon>
                    <ListItemText>{reason}</ListItemText>
                  </ListItem>
                </List>
              );
            })}
            {retryPossible && (
              <Link to="/verify_customer">
                <Button color="primary" fullWidth>
                  Retry Verification
                </Button>
              </Link>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <LearnMoreDialog
        isOpen={isLearnMoreModalShowing}
        handleClose={() => setIsLearnMoreModalShowing(false)}
        desktopLayout={desktopLayout}
      />
      <Content>
        <StyledText>
          Hi <Name>{trimMaxLength(name, 29)}</Name>,
        </StyledText>
        <StyledText marginTop>
          {renderTierMessage()}{' '}
          <StyledLink onClick={() => setIsLearnMoreModalShowing(true)}>
            Learn more
          </StyledLink>
        </StyledText>
        <ProgressBar
          tierPercentage={tierPercentage()}
          avatar={avatar}
          isShowingAvatar={avatar}
        />
        <GGMessageBox position={{ x: tierPercentage(), y: 'top' }} withArrow>
          <FlexWrap>
            <TextWrap withCta={!withFooterCta}>{renderHelperText()}</TextWrap>
            {!withFooterCta && <ButtonWrap>{renderCta()}</ButtonWrap>}
          </FlexWrap>
        </GGMessageBox>
        {withFooterCta && renderCta()}
      </Content>
    </>
  );
};

export default TierCard;
