import PersonalOnDemand from '../../../assets/icons/personal_on_demand.svg';
import BusinessOperationalStaff from '../../../assets/icons/business_operational_staff.svg';
import BusinessBoostSales from '../../../assets/icons/business_boost_sales.svg';
import BusinessAdminTasks from '../../../assets/icons/business_admin_tasks.svg';
import BusinessLogistics from '../../../assets/icons/business_logistics.svg';
import BusinessExtras from '../../../assets/icons/business_extras.svg';

export const KYC_NOT_STARTED = 0;
// Nature of usage and service preferences.
export const KYC_TIER_ONE = 1;
// Updated profile photo and about me, has not started verification yet.
export const KYC_TIER_TWO = 2;
// User completed KYC successfully.
export const KYC_TIER_THREE = 3;
export const KYC_STATUS_STARTED = 'started';
export const KYC_STATUS_PENDING = 'pending';
export const KYC_STATUS_COMPLETED = 'completed';
export const KYC_STATUS_REJECTED = 'rejected';
export const ONFIDO_WELCOME_STEP = 'welcome';
export const ONFIDO_COMPLETE_STEP = 'complete';

export const KYC_0_PERCENT = 0;
export const KYC_33_PERCENT = 33;
export const KYC_66_PERCENT = 66;

export const KYCServices = {
  personal: {
    boxes: [
      {
        id: 1,
        title: 'Home & Life',
        icon: PersonalOnDemand,
        tags: [
          {
            id: 1,
            title: 'Shopping'
          },
          {
            id: 2,
            title: 'Dispatch'
          },
          {
            id: 3,
            title: 'Pick & Move'
          },
          {
            id: 5,
            title: 'Assemble Furniture'
          },
          {
            id: 8,
            title: 'Pet Care'
          },
          {
            id: 9,
            title: 'Personal Assistant'
          },
          {
            id: 10,
            title: 'Laundry Helper'
          }
        ]
      }
    ]
  },
  business: {
    boxes: [
      {
        id: 1,
        title: 'Hire operational staff for peak seasons',
        icon: BusinessOperationalStaff,
        tags: [
          {
            id: 1,
            title: 'Retail Staff'
          },
          {
            id: 2,
            title: 'Floor Staff'
          },
          {
            id: 3,
            title: 'Usher'
          },
          {
            id: 4,
            title: 'Catering Helper'
          },
          {
            id: 5,
            title: 'Manning Booth'
          },
          {
            id: 6,
            title: 'Booth Set Up'
          },
          {
            id: 7,
            title: 'Bulk Delivery Orders'
          },
          {
            id: 8,
            title: 'Florist Assistant'
          },
          {
            id: 9,
            title: 'Kitchen Helper'
          },
          {
            id: 10,
            title: 'Waiter or Server'
          }
        ]
      },
      {
        id: 2,
        title: 'Boost your sales and marketing',
        icon: BusinessBoostSales,
        tags: [
          {
            id: 1,
            title: 'Promoter'
          },
          {
            id: 2,
            title: 'Flyering'
          },
          {
            id: 3,
            title: 'Caller'
          },
          {
            id: 4,
            title: 'Sales Admin'
          },
          {
            id: 5,
            title: 'Merchant Acquisition'
          }
        ]
      },
      {
        id: 3,
        title: 'Get part timers for administrative tasks',
        icon: BusinessAdminTasks,
        tags: [
          {
            id: 1,
            title: 'Cheque Deposit'
          },
          {
            id: 2,
            title: 'Data Entry'
          },
          {
            id: 3,
            title: 'Stamping'
          },
          {
            id: 4,
            title: 'Admin Staff'
          },
          {
            id: 5,
            title: 'Deliver Documents'
          },
          {
            id: 6,
            title: 'Customer Service'
          }
        ]
      },
      {
        id: 4,
        title: 'Power up your logistics',
        icon: BusinessLogistics,
        tags: [
          {
            id: 1,
            title: 'Dispatch'
          },
          {
            id: 2,
            title: 'Packer'
          },
          {
            id: 3,
            title: 'General Labour'
          },
          {
            id: 4,
            title: 'Event Staff'
          },
          {
            id: 5,
            title: 'Warehouse Worker'
          }
        ]
      },
      {
        id: 5,
        title: 'Extra services for businesses',
        icon: BusinessExtras,
        tags: [
          {
            id: 1,
            title: 'Train & Build your own team'
          },
          {
            id: 2,
            title: 'API Integration'
          }
        ]
      }
    ]
  }
};

export const industries = [
  'Agency/PR',
  'Automotive',
  'Cake',
  'Education',
  'Electronics',
  'Events',
  'F&B/Catering',
  'Fashion/Beauty',
  'Finance/Banking/Fintech',
  'Florist',
  'Fresh/Produce/Groceries',
  'Gifts',
  'Healthcare',
  'Hotels/Tourism',
  'Manufacturing',
  'Property',
  'Retail',
  'Technology',
  'Telco',
  'Testing Services',
  'Others'
];
