import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Divider from '@material-ui/core/Divider';

import ScrollableTileContainer from './ScrollableTileContainer';
import Tile from './Tile';

import AdminStaff from '../../assets/adminStaff.svg';
import BoothSetUp from '../../assets/boothSetUp.svg';
import BulkUpload from '../../assets/bulkUpload.svg';
import BulkUpgrade from '../../assets/bulkOrderUpgrade.svg';
import Caller from '../../assets/caller.svg';
import CateringHelper from '../../assets/cateringHelper.svg';
import ChequeDeposit from '../../assets/chequeDeposit.svg';
import CustomerService from '../../assets/customerService.svg';
import DataEntry from '../../assets/dataEntry.svg';
import Dispatch from '../../assets/dispatch.svg';
import EventCrew from '../../assets/eventCrew.svg';
import FloorStaff from '../../assets/floorStaff.svg';
import FloristAssistant from '../../assets/floristAssistant.svg';
import Flyering from '../../assets/flyering.svg';
import GeneralLabour from '../../assets/generalLabour.svg';
import KitchenHelper from '../../assets/kitchenHelper.svg';
import Lazada from '../../assets/lazadahome.svg';
import ManningBooth from '../../assets/manningBooth.svg';
import MovingBulky from '../../assets/movingBulky.svg';
import PickerAndPacker from '../../assets/pickerAndPacker.svg';
import Promoter from '../../assets/promoter.svg';
import RetailStaff from '../../assets/retailStaff.svg';
import SalesAdmin from '../../assets/salesAdmin.svg';
import Salesperson from '../../assets/salesperson.svg';
import Stamping from '../../assets/stamping.svg';
import Usher from '../../assets/usher.svg';
import WaiterServer from '../../assets/waiterServer.svg';
import WarehouseWorker from '../../assets/warehouseWorker.svg';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

const SectionWrapper = styled.div`
  padding-top: 0.25rem;
`;
const SectionTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  padding: 0 1rem;
  margin-top: 0.5rem;
`;
const StyledDivider = styled(Divider)`
  && {
    height: 4px;
    background-color: ${colors.greyLight};
  }
`;

const BusinessTiles = props => {
  const {
    onClickBulk,
    onClickLazada,
    onClickDispatch,
    onClickManningBooth,
    onClickChequeDeposit,
    onClickPartTimer,
    isMobile,
    isBusinessAccount,
    handleClickCreateBulkUpload
  } = props;

  const logisticTiles = [
    {
      onClick: onClickDispatch,
      image: Dispatch,
      title: 'Dispatch',
      jobType: 'dispatch'
    },
    {
      onClick: !isBusinessAccount ? handleClickCreateBulkUpload : onClickBulk,
      image: !isBusinessAccount ? BulkUpgrade : BulkUpload,
      title: 'Bulk Order',
      jobType: 'bulk_upload',
      chip: {
        color: colors.redLight,
        text: 'Flat Rate'
      }
    },
    {
      onClick: () => onClickPartTimer('picker_and_packer'),
      image: PickerAndPacker,
      title: 'Packer',
      jobType: 'picker_and_packer'
    },
    {
      onClick: () => onClickPartTimer('warehouse_worker'),
      image: WarehouseWorker,
      title: 'Warehouse Worker',
      jobType: 'warehouse_worker'
    },
    {
      onClick: () => onClickPartTimer('general_labour'),
      image: GeneralLabour,
      title: 'General Labour',
      jobType: 'general_labour'
    },
    {
      onClick: () => onClickPartTimer('bulky', true),
      image: MovingBulky,
      title: 'Moving Bulky Items',
      jobType: 'bulky'
    },
    {
      onClick: () => onClickPartTimer('event_crew'),
      image: EventCrew,
      title: 'Event Staff',
      jobType: 'event_crew'
    },
    {
      isHidden: !props.isLazadaMerchant,
      onClick: onClickLazada,
      image: Lazada,
      title: 'Lazada Orders',
      jobType: 'lazada'
    }
  ];

  const operationTiles = [
    {
      onClick: () => onClickPartTimer('retail_staff'),
      image: RetailStaff,
      title: 'Retail Staff',
      jobType: 'retail_staff'
    },
    {
      onClick: () => onClickPartTimer('kitchen_helper'),
      image: KitchenHelper,
      title: 'Kitchen Helper',
      jobType: 'kitchen_helper'
    },
    {
      onClick: () => onClickPartTimer('floor_staff'),
      image: FloorStaff,
      title: 'Floor Staff',
      jobType: 'floor_staff'
    },
    {
      onClick: () => onClickPartTimer('waiter_server'),
      image: WaiterServer,
      title: 'Waiter or Server',
      jobType: 'waiter_server'
    },
    {
      onClick: () => onClickPartTimer('florist_assistant'),
      image: FloristAssistant,
      title: 'Florist Assistant',
      jobType: 'florist_assistant'
    },
    {
      onClick: onClickManningBooth,
      image: ManningBooth,
      title: 'Manning Booth',
      jobType: 'manning_booth'
    },
    {
      onClick: () => onClickPartTimer('catering_helper'),
      image: CateringHelper,
      title: 'Catering Helper',
      jobType: 'catering_helper'
    },
    {
      onClick: () => onClickPartTimer('usher', true),
      image: Usher,
      title: 'Usher',
      jobType: 'usher'
    },
    {
      onClick: () => onClickManningBooth(true),
      image: BoothSetUp,
      title: 'Booth Set Up',
      jobType: 'booth_set_up'
    }
  ];

  const adminTiles = [
    {
      onClick: () => onClickPartTimer('data_entry'),
      image: DataEntry,
      title: 'Data Entry',
      jobType: 'data_entry'
    },
    {
      onClick: onClickChequeDeposit,
      image: ChequeDeposit,
      title: 'Cheque Deposit',
      jobType: 'cheque_deposit'
    },
    {
      onClick: () => onClickPartTimer('stamping', true),
      image: Stamping,
      title: 'Stamping',
      jobType: 'stamping'
    },
    {
      onClick: () => onClickPartTimer('customer_service'),
      image: CustomerService,
      title: 'Customer Service',
      jobType: 'customer_service'
    },
    {
      onClick: () => onClickPartTimer('admin_staff'),
      image: AdminStaff,
      title: 'Admin Staff',
      jobType: 'admin_staff'
    }
  ];

  const salesMarketingTiles = [
    {
      onClick: () => onClickPartTimer('promoter'),
      image: Promoter,
      title: 'Promoter',
      jobType: 'promoter'
    },
    {
      onClick: () => onClickPartTimer('caller'),
      image: Caller,
      title: 'Caller',
      jobType: 'caller'
    },
    {
      onClick: () => onClickPartTimer('flyering'),
      image: Flyering,
      title: 'Flyering',
      jobType: 'flyering'
    },
    {
      onClick: () => onClickPartTimer('sales_admin'),
      image: SalesAdmin,
      title: 'Sales Admin',
      jobType: 'sales_admin'
    },
    {
      onClick: () => onClickPartTimer('salesperson'),
      image: Salesperson,
      title: 'Salesperson',
      jobType: 'salesperson'
    }
  ];

  return (
    <>
      <RenderSection title="Logistic" isMobile={isMobile}>
        {logisticTiles.map(logistic => {
          if (logistic?.isHidden) {
            return <></>;
          }
          return (
            <Tile
              key={logistic.title}
              onClick={logistic.onClick}
              mobileLayout={isMobile}
              image={logistic.image}
              title={logistic.title}
              jobType={logistic.jobType}
              isBusinessAccount={isBusinessAccount}
              chip={logistic?.chip}
            />
          );
        })}
      </RenderSection>
      <StyledDivider />
      <RenderSection title="Operations" isMobile={isMobile}>
        {operationTiles.map(operationTile => (
          <Tile
            key={operationTile.title}
            onClick={operationTile.onClick}
            mobileLayout={isMobile}
            image={operationTile.image}
            title={operationTile.title}
            jobType={operationTile.jobType}
            isBusinessAccount={isBusinessAccount}
          />
        ))}
      </RenderSection>
      <StyledDivider />
      <RenderSection title="Admin" isMobile={isMobile}>
        {adminTiles.map(adminTile => (
          <Tile
            key={adminTile.title}
            onClick={adminTile.onClick}
            mobileLayout={isMobile}
            image={adminTile.image}
            title={adminTile.title}
            jobType={adminTile.jobType}
            isBusinessAccount={isBusinessAccount}
          />
        ))}
      </RenderSection>
      <StyledDivider />
      <RenderSection title="Sales / Marketing" isMobile={isMobile}>
        {salesMarketingTiles.map(saleTile => (
          <Tile
            key={saleTile.title}
            onClick={saleTile.onClick}
            mobileLayout={isMobile}
            image={saleTile.image}
            title={saleTile.title}
            jobType={saleTile.jobType}
            isBusinessAccount={saleTile.isBusinessAccount}
          />
        ))}
      </RenderSection>
    </>
  );
};

const RenderSection = React.memo(props => (
  <SectionWrapper>
    <SectionTitle>{props.title}</SectionTitle>
    <ScrollableTileContainer isMobile={props.isMobile}>
      {props.children}
    </ScrollableTileContainer>
  </SectionWrapper>
));

const mapStateToProps = state => {
  return {
    isLazadaMerchant: state.user?.profile?.is_lazada_merchant
  };
};
export default React.memo(connect(mapStateToProps)(BusinessTiles));
