import { UPDATE_TRACKER } from './constants/gogetterTracking';

export const gogetterTrackingReducerDefaultState = {
  jobs: {}
};

export default (state = gogetterTrackingReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_TRACKER:
      const newJob = { [action.jobId]: action.tracker };
      return {
        ...state,
        jobs: {
          ...state.jobs,
          ...newJob
        }
      };
    default:
      return state;
  }
};
