import DateFnsUtils from '@date-io/date-fns';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import { getUrlParameter, resetState } from './libraries/ApplicationService';
import 'normalize.css/normalize.css';
import { SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axiosInterceptors from './services/axios/interceptors';
import styled from 'styled-components/macro';
import AppOpenAnalytics from './components/AppOpenAnalytics';
import AppTrackingHandler from './components/AppTrackingHandler';
import CurrentLocationTracker from './components/CurrentLocationTracker';
import ForceUpdate from './components/ForceUpdate';
import NotificationReceiver from './components/notification/NotificationReceiver';
import Snackbar from './components/Snackbar';
import ManageUrlParams from './components/ManageUrlParams';
import UpdateSnackbar from './components/UpdateSnackbar';
import Routers from './Routers';
import configureStore, { history } from './store/configueStore';
import './style/App.scss';
import colors from './style/colors';
import NewReferralIntroDialog from './components/ui/NewReferralIntroDialog';
import WindowSizeHandler from './components/WindowSizeHandler';
import CreditUpdatePusher from './libraries/CreditUpdatePusher';
import { setCookie } from './libraries/authentication';

const { persistor, store } = configureStore();

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff'
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.red
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ipad: 750,
      md: 960,
      desktop: 1200,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Open Sans', sans-serif",
    h5: {
      fontWeight: 700,
      fontSize: '1rem'
    },
    h6: {
      fontWeight: 700,
      fontSize: '0.875rem'
    }
  },
  props: {
    MuiButton: {
      disableRipple: true
    }
  }
});

const styles = {
  root: {
    top: '0 !important'
  }
};

const ViewWrap = styled.div`
  margin-bottom: ${props =>
    [
      '/',
      '/jobs',
      '/notifications',
      '/profile',
      '/home',
      '/templates',
      '/rewards'
    ].includes(props.history.location.pathname) && props.mobile
      ? '60px'
      : '0px'};
`;

axiosInterceptors.setupInterceptors(store, history);

class App extends Component {
  constructor(props) {
    super(props);

    let mobile = true;
    if (
      window.cordova &&
      window.device &&
      window.device.model &&
      window.device.model.toLowerCase().includes('ipad')
    ) {
      mobile = false;
    }
    this.state = {
      mobile,
      isTabletBrowser: false
    };
  }

  componentDidMount() {
    // TODO: move this version number to somewhere nicer
    const reduxVersion = 10;
    if (
      window &&
      window.localStorage &&
      window.localStorage.getItem('redux-version')
    ) {
      if (window.localStorage.getItem('redux-version') < reduxVersion) {
        resetState();
        window.localStorage.setItem('redux-version', reduxVersion);
      }
    } else {
      window.localStorage.setItem('redux-version', reduxVersion);
    }

    if (!window.cordova) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    const utmSource = getUrlParameter(history?.location, 'utm_source');
    const utmedium = getUrlParameter(history?.location, 'utm_medium');
    const utmCampaign = getUrlParameter(history?.location, 'utm_campaign');

    if (utmSource || utmedium || utmCampaign) {
      setCookie('utm_source', utmSource);
      setCookie('utm_medium', utmedium);
      setCookie('utm_campaign', utmCampaign);
    }
  }

  componentWillUnmount() {
    if (!window.cordova) {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  }

  updateWindowDimensions = () => {
    const { mobile } = this.state;
    if (window.innerWidth <= 750 && !mobile) {
      this.setState({ mobile: true });
    } else if (window.innerWidth > 750 && mobile) {
      this.setState({ mobile: false });
    }

    if (window.innerWidth > 1024) {
      this.setState({ isTabletBrowser: false });
    } else {
      this.setState({ isTabletBrowser: true });
    }
  };

  render() {
    const { classes } = this.props;
    const { mobile, isTabletBrowser } = this.state;
    return (
      <Provider store={store} context={ReactReduxContext}>
        <PersistGate loading={null} persistor={persistor}>
          <WindowSizeHandler>
            <AppTrackingHandler>
              <SnackbarProvider
                action={<CloseIcon />}
                classes={{
                  root: classes.root
                }}
                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <>
                  <NewReferralIntroDialog />
                  <ManageUrlParams history={history} />
                  <UpdateSnackbar />
                  <CurrentLocationTracker />
                  <NotificationReceiver mobile={mobile} history={history} />
                  <Snackbar />
                  <CreditUpdatePusher />
                  <AppOpenAnalytics />
                  <ForceUpdate />
                  <ConnectedRouter
                    history={history}
                    context={ReactReduxContext}
                  >
                    <ViewWrap history={history} mobile={mobile}>
                      <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Routers
                            mobile={mobile}
                            isTabletBrowser={isTabletBrowser}
                            history={history}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </ViewWrap>
                  </ConnectedRouter>
                </>
              </SnackbarProvider>
            </AppTrackingHandler>
          </WindowSizeHandler>
        </PersistGate>
      </Provider>
    );
  }
}

export default withStyles(styles)(App);
