import moment from 'moment';
import { enqueueSnackbar } from '../../actions/snackbar';

class RoutingService {
  constructor(
    reducerType,
    dispatch,
    locations,
    editTask,
    updateRoute,
    setRouting,
    updateDistance,
    rideId,
    serviceType,
    getFee,
    calculateTimeRange,
    categoryType
  ) {
    this.reducerType = reducerType;
    this.locations = locations;
    this.dispatch = dispatch;
    this.editTask = editTask;
    this.updateRoute = updateRoute;
    this.setRouting = setRouting;
    this.updateDistance = updateDistance;
    this.rideId = rideId;
    this.serviceType = serviceType;
    this.getFee = getFee;
    this.calculateTimeRange = calculateTimeRange;
    this.categoryType = categoryType;
  }

  route = () => {
    const platform_config = {
      app_id: process.env.REACT_APP_HERE_MAPS_APP_ID,
      app_code: process.env.REACT_APP_HERE_MAPS_APP_CODE,
      useCIT: process.env.NODE_ENV !== 'production',
      useHTTPS: true
    };

    const routingParameters = {
      // The routing mode:
      mode: 'balanced;car',
      // To retrieve the shape of the route we choose the route
      // representation mode 'display'
      representation: 'display',
      legAttributes: 'summary',
      routeAttributes: 'summary'
    };

    routingParameters.departure = moment(this.locations[0].start_at).format();

    this.locations.map(location => {
      if (location.location_lat) {
        routingParameters[
          `waypoint${location.order}`
        ] = `geo!${location.location_lat},${location.location_long}`;
      }
      return true;
    });

    const platform = new window.H.service.Platform(platform_config);
    const router = platform.getRoutingService();
    router.calculateRoute(routingParameters, this.onRouteResult, error => {
      // eslint-disable-next-line no-console
      console.error(error.message);
    });
  };

  onRouteResult = result => {
    if (!!result.response && !!result.response.route) {
      // Pick the first route from the response:
      const route = result.response.route[0];
      this.dispatch(this.updateRoute(this.reducerType, route));
      this.dispatch(
        this.updateDistance(this.reducerType, route.summary.distance)
      );
      this.dispatch(
        this.calculateTimeRange(
          this.reducerType,
          this.locations,
          route,
          this.rideId,
          this.serviceType,
          this.categoryType
        )
      );
      this.dispatch(this.setRouting(this.reducerType, false));
      this.dispatch(this.getFee(this.reducerType));
    } else if (!!result.subtype && result.subtype === 'InvalidInputData') {
      this.dispatch(
        enqueueSnackbar({
          message: 'Successfully removed a stop',
          options: {
            variant: 'success'
          }
        })
      );
    } else {
      this.dispatch(
        enqueueSnackbar({
          message:
            'Unable to route to this location. Please select a different location',
          options: {
            variant: 'error'
          }
        })
      );
    }
  };
}
export default RoutingService;
