import {
  PUSH_TRAINED_PAGE,
  SET_ACTIVE_ID,
  SET_LOADING_TEAMS,
  UPDATE_TEAMS
} from './constants/trained';

export const trainedReducerDefaultState = {
  loaded: false,
  teams: [],
  activeId: null,
  currentPage: 'teams',
  showHeaderTrained: true
};

export default (state = trainedReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_TEAMS:
      return {
        ...state,
        loaded: action.loaded,
        teams: action.teams
      };
    case SET_LOADING_TEAMS:
      return {
        ...state,
        loaded: false
      };
    case SET_ACTIVE_ID:
      return {
        ...state,
        activeId: action.activeId
      };
    case PUSH_TRAINED_PAGE:
      return {
        ...state,
        showHeaderTrained: action.showHeaderTrained,
        currentPage: action.currentPage
      };
    default:
      return state;
  }
};
