import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import styled from 'styled-components/macro';

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import CtaButton from '../ui/CtaButton';

import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

const StyledDialogSubTitle = styled(DialogTitle)`
  h6 {
    font-size: ${fontSize.large};
  }
  text-align: center;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
`;
const LearnMoreWrapper = styled.div`
  text-align: center;
  color: ${colors.blue};
  margin-top: 1rem;
  margin-buttom: 1rem;
`;
const BodyWrapper = styled.div`
  & > div {
    padding-top: 0 !important;
  }
  text-align: center;
`;
const Container = styled.div`
  max-width: 375px;
`;
const StyledDialogContentText = styled(DialogContentText)`
  line-height: 1rem !important;
`;

class EntryPopUp extends Component {
  state = {
    checked: false
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const {
      isAcknowledgementPopup,
      onClose,
      image,
      headerText,
      bodyText,
      learnMoreUrl,
      onButtonClick
    } = this.props;
    const { checked } = this.state;
    return (
      <Container>
        {!isAcknowledgementPopup && (
          <StyledIconButton onClick={onClose} aria-label="Close" size="small">
            <CancelIcon />
          </StyledIconButton>
        )}
        {image && (
          <ImageWrapper>
            <Image src={image} />
          </ImageWrapper>
        )}
        <StyledDialogSubTitle>{headerText}</StyledDialogSubTitle>
        <BodyWrapper>
          <DialogContent>
            <StyledDialogContentText>
              {bodyText &&
                bodyText.split('\n').map((item, key) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </StyledDialogContentText>
          </DialogContent>
          {learnMoreUrl && (
            <LearnMoreWrapper>
              <a href={learnMoreUrl}> Learn More </a>
            </LearnMoreWrapper>
          )}
          {isAcknowledgementPopup && (
            <>
              <div>
                <CheckboxWrapper>
                  <Checkbox
                    checked={checked}
                    onChange={this.handleChange('checked')}
                    value="checked"
                    color="primary"
                  />
                  <span>I Agree</span>
                </CheckboxWrapper>
              </div>
              <DialogActions>
                <CtaButton disabled={!checked} onClick={onButtonClick}>
                  OK
                </CtaButton>
              </DialogActions>
            </>
          )}
        </BodyWrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  push: () => dispatch(push())
});
export default connect(null, mapDispatchToProps)(EntryPopUp);
