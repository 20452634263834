import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateLocation } from '../actions/currentLocation';

class CurrentLocationTracker extends Component {
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(position => {
        this.props.updateLocation(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    }
  }

  render() {
    return <></>;
  }
}

export default connect(undefined, { updateLocation })(CurrentLocationTracker);
