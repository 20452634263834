import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ProfileReducer from './profileReducer';
import dispatchReducer from './dispatchReducer';
import jobsReducer from './jobsReducer';
import snackbarReducer from './snackbarReducer';
import currentLocationReducer from './currentLocationReducer';
import authReducer from './authReducer';
import chatReducer from './chatReducer';
import gogetterTrackingReducer from './gogetterTrackingReducer';
import notificationReducer from './notificationReducer';
import editJobReducer from './editJobReducer';
import creditsReducer from './creditsReducer';
import foodAndShoppingReducer from './foodAndShoppingReducer';
import partTimerReducer from './partTimerReducer';
import personalHelperReducer from './personalHelperReducer';
import chequeDepositReducer from './chequeDepositReducer';
import continueCreationReducer from './continueCreationReducer';
import manningBoothReducer from './manningBoothReducer';
import customPopupReducer from './customPopupReducer';
import favouritesReducer from './favouritesReducer';
import trainedReducer from './trainedReducer';
import layoutReducer from './layoutReducer';
import bulkOrderReducer from './bulkOrderReducer';
import ordersReducer from './ordersReducer';
import createHiringPostReducer from './createHiringPostReducer';
import editHiringPostReducer from './editHiringPostReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user: ProfileReducer,
    createJob: dispatchReducer,
    jobs: jobsReducer,
    snackbar: snackbarReducer,
    currentLocation: currentLocationReducer,
    auth: authReducer,
    chat: chatReducer,
    gogetterTracking: gogetterTrackingReducer,
    notification: notificationReducer,
    editJob: editJobReducer,
    credits: creditsReducer,
    createFoodAndShopping: foodAndShoppingReducer,
    createPartTimer: partTimerReducer,
    continueCreation: continueCreationReducer,
    createPersonalHelper: personalHelperReducer,
    createManningBooth: manningBoothReducer,
    createChequeDeposit: chequeDepositReducer,
    customPopup: customPopupReducer,
    favourites: favouritesReducer,
    teams: trainedReducer,
    layout: layoutReducer,
    createBulkOrder: bulkOrderReducer,
    orders: ordersReducer,
    createHiringPost: createHiringPostReducer,
    editHiringPost: editHiringPostReducer
  });
