import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setLayout, setIsTablet } from '../actions/layout';

// Had to create this wrapper as store wasn't available in App.js as thats where it gets injected,
// so trying to call on the dispatch method will throw an error that store is not available.

const WindowSizeHandler = props => {
  const [mobile, setMobile] = useState(true);

  const { dispatchSetDesktopLayout, dispatchSetIsTablet, children } = props;

  const updateWindowDimensions = () => {
    if (window.innerWidth <= 750 && !mobile) {
      dispatchSetDesktopLayout(false);
      setMobile(true);
    } else if (window.innerWidth > 750 && mobile) {
      dispatchSetDesktopLayout(true);
      setMobile(false);
    }

    // Handle Tablet View
    if (window.innerWidth > 1024) {
      dispatchSetIsTablet(false);
    } else {
      dispatchSetIsTablet(true);
    }
  };

  useEffect(() => {
    if (
      window.cordova &&
      window.device &&
      window.device.model &&
      window.device.model.toLowerCase().includes('ipad')
    ) {
      dispatchSetIsTablet(true);
      setMobile(false);
    }

    if (!window.cordova) {
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);
    }

    return function cleanup() {
      if (!window.cordova) {
        window.removeEventListener('resize', updateWindowDimensions);
      }
    };
  }, [mobile]);

  return <>{children}</>;
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetDesktopLayout: desktopLayout =>
      dispatch(setLayout(desktopLayout)),
    dispatchSetIsTablet: isTablet => dispatch(setIsTablet(isTablet))
  };
};

export default connect(null, mapDispatchToProps)(WindowSizeHandler);
