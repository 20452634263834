import { push } from 'connected-react-router';
import { createConversation, fetchConversations } from '../api/chat';
import {
  FETCH_CONVERSATION_FULFILLED,
  ADD_CONVERSATION_REJECTED
} from '../reducers/constants/chat';

export function getConversations(jobId) {
  return async (dispatch, getState) => {
    const updatedAt =
      getState().chat[jobId] && getState().chat[jobId].updatedAt;

    const response = await fetchConversations(jobId, updatedAt);

    if (response?.isSuccess) {
      await dispatch({
        type: FETCH_CONVERSATION_FULFILLED,
        messages: response?.messages,
        updatedAt: response?.updatedAt,
        gogetterLastSeen: response?.gogetterLastSeen,
        jobId
      });

      return {
        success: true
      };
    } else if (response?.forbidden) {
      dispatch(push('/'));
    }
  };
}

export function addConversation(jobId, content, files) {
  return async dispatch => {
    const response = await createConversation(jobId, content, files);

    if (response?.isSuccess) {
      dispatch(getConversations(jobId));
      return {
        success: true
      };
    } else {
      await dispatch({
        type: ADD_CONVERSATION_REJECTED,
        payload: response?.error
      });
      return {
        success: false
      };
    }
  };
}
