import { UPDATE_CREDITS } from './constants/credits';

export const creditsReducerDefaultState = {
  creditsRemaining: 0,
  creditsExpiration: undefined
};

export default (state = creditsReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_CREDITS:
      return {
        creditsRemaining: action.creditsRemaining,
        creditsExpiration: action.creditsExpiration
      };
    default:
      return state;
  }
};
