import { CONTINUE_CREATION } from './constants/continueCreation';

export const continueCreationReducerDefaultState = {
  path: undefined
};

export default (state = continueCreationReducerDefaultState, action) => {
  switch (action.type) {
    case CONTINUE_CREATION:
      return {
        ...state,
        path: action.page
      };
    default:
      return state;
  }
};
