import React from 'react';
import styled from 'styled-components/macro';

import colors from '../../style/colors';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${colors.greyLight};
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  position: relative;
`;

const Arrow = styled.span`
  position: absolute;
  top: ${({ position }) => position.y === 'top' && 0};
  bottom: ${({ position }) => position.y === 'top' && '-1em'};
  left: ${({ position }) => `${position.x}%`};
  transform: translateX(-50%);
  width: 3em;
  height: 3em;
  margin-top: -0.9em;

  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1em 1em 1em;
    border-color: transparent transparent ${colors.greyLight} transparent;
  }
`;

const GGMessageBox = props => {
  const { withArrow, position, children } = props;

  return (
    <Container position={position}>
      {withArrow && <Arrow position={position} />}
      {children}
    </Container>
  );
};

export default GGMessageBox;
