import { lazy } from 'react';

const Credits = lazy(() => import('../../components/credits/Credits'));
const Dispatch = lazy(() => import('../../components/dispatch/Main'));
const Tracking = lazy(() => import('../../components/tracking/Tracking'));
const MobileUpgradeAccount = lazy(() =>
  import('../../components/gogetBusiness/MobileUpgradeAccount')
);
const ManningBoothCreate = lazy(() =>
  import('../../components/helper/manningBooth/ManningBoothCreate')
);
const HiringPostCreate = lazy(() =>
  import('../../components/hiringPost/HiringPostCreate')
);
const ShortTermLongTermSelection = lazy(() =>
  import('../../components/helper/ui/ShortTermLongTermSelection')
);
const PartTimerCreate = lazy(() =>
  import('../../components/helper/partTimer/PartTimerCreate')
);
const ChequeDepositCreate = lazy(() =>
  import('../../components/chequeDeposit/ChequeDepositCreate')
);
const PersonalHelperCreate = lazy(() =>
  import('../../components/helper/personalHelper/PersonalHelperCreate')
);
const FoodAndShoppingCreate = lazy(() =>
  import('../../components/foodAndShopping/FoodAndShoppingCreate')
);
const MerchantShowcase = lazy(() =>
  import('../../components/discover/ui/MerchantShowcase')
);
const BulkOrderCreate = lazy(() =>
  import('../../components/bulkOrder/BulkOrderCreate')
);

export const publicRouters = [
  {
    path: '/upgrade_account',
    component: MobileUpgradeAccount
  },
  {
    path: '/dispatch',
    component: Dispatch
  },
  {
    path: '/manning_booth/gig',
    component: ManningBoothCreate
  },
  {
    path: '/manning_booth/recruit',
    component: HiringPostCreate
  },
  {
    path: '/manning_booth',
    component: ShortTermLongTermSelection
  },
  {
    path: '/part_timer/gig',
    component: PartTimerCreate
  },
  {
    path: '/part_timer/recruit',
    component: HiringPostCreate
  },
  {
    path: '/part_timer',
    component: ShortTermLongTermSelection
  },
  {
    path: '/cheque_deposit',
    component: ChequeDepositCreate
  },
  {
    path: '/personal_helper',
    component: PersonalHelperCreate
  },
  {
    path: '/food_shopping',
    component: FoodAndShoppingCreate
  },
  {
    path: '/credits',
    component: Credits
  },
  {
    path: '/partners',
    component: MerchantShowcase
  },
  {
    path: '/bulk_order',
    component: BulkOrderCreate
  },
  {
    path: '/track/:publicId',
    component: Tracking
  }
];
