import {
  UPDATE_DESKTOP_LAYOUT,
  UPDATE_TABLET_LAYOUT
} from './constants/layout';

export const layoutReducerDefaultState = {
  desktopLayout: false,
  isTablet: false
};

export default (state = layoutReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_DESKTOP_LAYOUT:
      return {
        ...state,
        desktopLayout: action.desktopLayout
      };
    case UPDATE_TABLET_LAYOUT:
      return {
        ...state,
        isTablet: action.isTablet
      };
    default:
      return state;
  }
};
