import React from 'react';
import styled from 'styled-components/macro';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';

import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

import FavouriteIcon from '../../assets/FavouriteIcon.svg';
import PromoCodeHintPopover from '../../ui/PromoCodeHintPopover';

import { getAuthToken } from '../../../libraries/authentication';

const StyledButtonGroup = styled(ButtonGroup)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    width: calc(100% - 4px);
    margin: ${({ mobile }) => (mobile ? 'none' : '1rem auto')};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;
const StyledButton = styled(Button)`
  text-transform: none !important;
  width: 33%;
  height: 42px;
  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.23);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
const StyledIconImage = styled.img`
  width: ${({ mobile }) => (mobile ? '20px' : '30px')};
  height: ${({ mobile }) => (mobile ? '20px' : '30px')};
`;
const TemplatesIcon = styled(LibraryBooksIcon)`
  && {
    width: ${({ mobile }) => (mobile ? '18px' : '24px')};
    height: ${({ mobile }) => (mobile ? '18px' : '24px')};
    color: ${colors.primary};
  }
`;
const RewardsIcon = styled(CardGiftcardIcon)`
  && {
    width: ${({ mobile }) => (mobile ? '19px' : '26px')};
    height: ${({ mobile }) => (mobile ? '19px' : '26px')};
    color: ${colors.primary};
  }
`;
const StyledTitle = styled.div`
  text-align: center;
  font-size: ${({ mobile }) =>
    mobile ? `${fontSize.regular}` : `${fontSize.large}`};
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px !important;
  color: ${({ comingSoon }) =>
    comingSoon ? `${colors.grey}` : `${colors.black}`};
`;
const StyledLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const UpsellWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;
const StyledUpsell = styled.div`
  margin: 0 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: ${colors.greyLightest};
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
`;
const NumberChip = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey};
  font-size: ${fontSize.regular};
`;
const StepBreak = styled(Divider)`
  flex: 1;
  margin: 0 0.5rem;
`;
const StepText = styled.div`
  font-size: ${fontSize.regular};
  font-weight: 500;
  margin-left: 0.25rem;
`;

const HotButtons = props => {
  const { isBusinessAccount, dispatchPush, mobile } = props;

  const handleLinkClick = type => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: type,
      platform: 'pwa'
    });
    dispatchPush(`/${type}`);
  };

  if (!getAuthToken()) {
    return (
      <UpsellWrapper>
        <StyledUpsell>
          <NumberChip>1</NumberChip>
          <StepText>Post Job</StepText>
          <StepBreak />
          <NumberChip>2</NumberChip>
          <StepText>Get Matched</StepText>
          <StepBreak />
          <NumberChip>3</NumberChip>
          <StepText>Hire</StepText>
        </StyledUpsell>
      </UpsellWrapper>
    );
  }

  return (
    <StyledButtonGroup variant="text" mobile={mobile}>
      <RenderButton
        icon={<StyledIconImage src={FavouriteIcon} mobile={mobile} />}
        title="GoGetters"
        mobile={mobile}
        onClick={() => handleLinkClick('favourites')}
      />
      <RenderButton
        icon={<TemplatesIcon mobile={mobile} />}
        title="Templates"
        mobile={mobile}
        onClick={() => handleLinkClick('templates')}
      />
      <RenderButton
        icon={<RewardsIcon mobile={mobile} />}
        title="Rewards"
        mobile={mobile}
        onClick={() => handleLinkClick('rewards')}
      />
      <PromoCodeHintPopover />
    </StyledButtonGroup>
  );
};

const RenderButton = React.memo(props => {
  const { title, mobile, icon, onClick } = props;
  return (
    <StyledButton mobile={mobile}>
      <StyledLink onClick={onClick}>
        {icon}
        <StyledTitle mobile={mobile}>{title}</StyledTitle>
      </StyledLink>
    </StyledButton>
  );
});

const mapStateToProps = state => {
  return {
    isBusinessAccount: state.user?.profile?.is_business_account
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchPush: url => dispatch(push(url))
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(HotButtons)
);
