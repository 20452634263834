// VEHICLE ID'S
export const BIKE_RIDE_ID = 1;
export const CAR_RIDE_ID = 2;

// PAYMENT METHOD
export const PAYMENT_METHOD_CREDITS = 'credits';

// JOB SERVICE TYPE
export const JOB_SERVICE_TYPE_DISPATCH = 'dispatch';

// DISPATCH ITEM TYPE
export const DISPATCH_ITEM_OTHERS = 'others';

// TASK TYPE
export const TASK_TYPE_PICK_UP = 'Pick up';
export const TASK_TYPE_DROP_OFF = 'Drop off';

// ITEM WEIGHT DISPATCH
export const BULKY_ITEM_ONE_TO_TEN = '1_10kg';
export const BULKY_ITEM_TEN_TO_TWENTY_FIVE = '10_25kg';

// INTERVALS
export const UPDATE_BULK_ORDERS_INTERVAL = 10000;
