import React from 'react';
import styled from 'styled-components/macro';

import colors from '../../style/colors';

const PageDivider = styled.hr`
  border: 4px solid ${colors.greyLight};
`;

export const DIVIDER_TEST_ID = 'mobile-divider';

const MobilePageDivider = () => {
  return <PageDivider data-testid={DIVIDER_TEST_ID} />;
};

export default MobilePageDivider;
