import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GGPopper from './GGPopper';
import { getAuthToken } from '../../libraries/authentication';
import { requestConfigInterceptor } from '../../services/axios/interceptors';

const PromoCodeHintPopover = ({ promoCode }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    if (!getAuthToken()) return;
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/promo_codes/check_eligibility`;

    // Use separaate axios instance to prevent interceptor from showing snackbar it errors.
    const customAxios = axios.create();
    customAxios.interceptors.request.use(requestConfigInterceptor);
    customAxios
      .post(url, {
        promoCode: 'firstjob5off',
        type: 'dispatch'
      })
      .then(() => {
        setOpen(true);
      })
      .catch(() => {
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    const ele = document.getElementById('promo-code-hint-anchor');
    if (ele) {
      setAnchorEl(ele);
    }
  }, []);

  if (!anchorEl) {
    return null;
  }

  return (
    <GGPopper
      open={!promoCode && open}
      anchorEl={anchorEl}
      arrowPosition="bottom"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box mr={1}>
          <Typography>RM 5 for you</Typography>
        </Box>
        <Button onClick={() => setOpen(false)} style={{ color: 'white' }}>
          <strong>GOT IT</strong>
        </Button>
      </Box>
    </GGPopper>
  );
};
export default PromoCodeHintPopover;
