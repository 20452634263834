import JobTrackingService from '../services/here/JobTrackingService';
import GogetterTrackingService from '../services/here/GogetterTrackingService';
import { UPDATE_TRACKER } from '../reducers/constants/gogetterTracking';

export const updateTracker = (jobId, tracker) => ({
  type: UPDATE_TRACKER,
  jobId,
  tracker
});

export const getGogetterTracker = jobId => {
  return (dispatch, getState) => {
    const gogetterTrackingService = new GogetterTrackingService(
      dispatch,
      getState,
      jobId
    );
    gogetterTrackingService.route();
  };
};

export const getJobTracker = (gogetterLocation, deliveryLocation, publicId) => {
  return dispatch => {
    const jobTrackingService = new JobTrackingService(
      gogetterLocation,
      deliveryLocation,
      dispatch,
      updateTracker,
      publicId
    );
    jobTrackingService.route();
  };
};
