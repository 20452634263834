import moment from 'moment';
import _ from 'lodash';
import { enqueueSnackbar } from '../../actions/snackbar';

class ShortestRouteService {
  constructor(
    reducerType,
    dispatch,
    locations,
    updateOriginalTaskAndRoute,
    updateTaskAndRoute,
    setShortestRouting,
    updateDistance,
    getState,
    editTask,
    getFee,
    rideId,
    updateRoute,
    job,
    route,
    calculateTimeRange,
    setNonFlexiTasks
  ) {
    this.reducerType = reducerType;
    this.dispatch = dispatch;
    this.locations = locations;
    this.updateOriginalTaskAndRoute = updateOriginalTaskAndRoute;
    this.updateTaskAndRoute = updateTaskAndRoute;
    this.setShortestRouting = setShortestRouting;
    this.updateDistance = updateDistance;
    this.getState = getState;
    this.editTask = editTask;
    this.getFee = getFee;
    this.rideId = rideId;
    this.updateRoute = updateRoute;
    this.job = job;
    this.route = route;
    this.calculateTimeRange = calculateTimeRange;
    this.setNonFlexiTasks = setNonFlexiTasks;
    this.state = {
      permutations: [],
      distances: [],
      shortestRoute: [],
      shortestPermutation: [],
      originalTasks: [],
      originalRoute: []
    };
  }

  getRoute = () => {
    if (!!this.route && !!this.route.shape && this.route.shape.length > 1) {
      const { permutations, distances, originalTasks, originalRoute } =
        this.state;

      this.dispatch(this.setShortestRouting(this.reducerType, true));

      // function to get route combinations
      const permutator = length => {
        const firstOrder = [];
        for (let i = 0; i < length; i++) {
          firstOrder.push(i);
        }
        const permute = (array, taskOrder = []) => {
          if (array.length === 0 && taskOrder[0] === 0) {
            permutations.push(taskOrder);
          } else {
            for (let j = 0; j < array.length; j++) {
              const current = array.slice();
              const next = current.splice(j, 1);
              permute(current.slice(), taskOrder.concat(next));
            }
          }
        };
        permute(firstOrder);
      };

      // Save original tasks and route for Undo cases
      originalTasks.push(this.job.tasks);
      originalRoute.push(this.route);
      distances.push(this.route.summary.distance);

      const platform_config = {
        app_id: process.env.REACT_APP_HERE_MAPS_APP_ID,
        app_code: process.env.REACT_APP_HERE_MAPS_APP_CODE,
        useCIT: process.env.NODE_ENV !== 'production',
        useHTTPS: true
      };

      const routingParameters = {
        // The routing mode:
        mode: 'balanced;car',
        // To retrieve the shape of the route we choose the route
        // representation mode 'display'
        representation: 'display',
        legAttributes: 'summary',
        routeAttributes: 'summary'
      };

      routingParameters.departure = moment(this.locations[0].start_at).format();

      const platform = new window.H.service.Platform(platform_config);
      const router = platform.getRoutingService();

      permutator(this.locations.length);

      permutations.map(permutation => {
        if (permutation !== permutations[0]) {
          routingParameters.waypoint0 = `geo!${this.locations[0].location_lat},${this.locations[0].location_long}`;
          routingParameters.waypoint1 = `geo!${
            this.locations[permutation[1]].location_lat
          },${this.locations[permutation[1]].location_long}`;
          routingParameters.waypoint2 = `geo!${
            this.locations[permutation[2]].location_lat
          },${this.locations[permutation[2]].location_long}`;
          if (this.locations.length === 4) {
            routingParameters.waypoint3 = `geo!${
              this.locations[permutation[3]]?.location_lat
            },${this.locations[permutation[3]]?.location_long}`;
          } else if (this.locations.length === 5) {
            routingParameters.waypoint3 = `geo!${
              this.locations[permutation[3]].location_lat
            },${this.locations[permutation[3]].location_long}`;
            routingParameters.waypoint4 = `geo!${
              this.locations[permutation[4]]?.location_lat
            },${this.locations[permutation[4]]?.location_long}`;
          }

          router.calculateRoute(
            routingParameters,
            result => this.onRouteResult(result, permutation),
            error => {
              // eslint-disable-next-line no-console
              console.error(error.message);
            }
          );
        }
        return true;
      });
    }
  };

  onRouteResult = async (result, permutation) => {
    if (!!result.response && !!result.response.route) {
      const {
        distances,
        shortestRoute,
        shortestPermutation,
        permutations,
        originalTasks,
        originalRoute
      } = this.state;
      const newRoute = result.response.route[0];
      const currentDistance = newRoute.summary.distance;
      const originalDistance = distances[0];
      const originalPermutation = permutations[0];

      distances.push(currentDistance);

      const minDistance = _.min(distances);

      if (
        currentDistance === minDistance &&
        currentDistance !== originalDistance
      ) {
        shortestRoute.push(newRoute);
        shortestPermutation.push(permutation);
      } else if (originalDistance === minDistance) {
        shortestRoute.push(originalRoute[0]);
        shortestPermutation.push(originalPermutation);
      }

      if (distances.length === permutations.length) {
        if (
          shortestPermutation[0] !== originalPermutation &&
          (currentDistance !== 0 ||
            newRoute.leg[1].summary.distance !== 0 ||
            newRoute.leg[2].summary.distance !== 0)
        ) {
          await shortestPermutation[0].map(async order => {
            const { tasks } = this.job;
            const index = _.indexOf(shortestPermutation[0], order);
            if (order > 0) {
              await this.dispatch(
                this.editTask(this.reducerType, index, {
                  location: tasks[order].location,
                  location_lat: tasks[order].location_lat,
                  location_long: tasks[order].location_long,
                  parking: tasks[order].parking,
                  edo: tasks[order].edo,
                  recipient_name: tasks[order].recipient_name,
                  recipient_phone_num: tasks[order].recipient_phone_num,
                  sender_name: tasks[order].sender_name,
                  sender_email: tasks[order].sender_email,
                  location_notes: tasks[order].location_notes,
                  reference: tasks[order].reference
                })
              );
            }
            await this.dispatch(this.setNonFlexiTasks(this.reducerType));
            return true;
          });

          await this.dispatch(
            this.updateRoute(this.reducerType, shortestRoute[0])
          );
          await this.dispatch(
            this.updateDistance(this.reducerType, minDistance)
          );
          await this.dispatch(
            this.calculateTimeRange(
              this.reducerType,
              this.locations,
              shortestRoute[0],
              this.rideId,
              this.job.service_type
            )
          );
          await this.dispatch(this.getFee(this.reducerType));
          await this.dispatch(
            enqueueSnackbar({
              message: 'Successfully optimised route to shortest distance.',
              options: {
                variant: 'success'
              }
            })
          );
          await this.dispatch(
            this.updateOriginalTaskAndRoute(
              this.reducerType,
              originalTasks[0],
              originalRoute[0]
            )
          );
          await this.dispatch(this.setShortestRouting(this.reducerType, false));
        } else {
          await this.dispatch(
            enqueueSnackbar({
              message: 'This route is already the shortest distance.',
              options: {
                variant: 'success'
              }
            })
          );
          await this.dispatch(this.setShortestRouting(this.reducerType, false));
        }
      }
    }
  };
}

export default ShortestRouteService;
