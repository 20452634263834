import React from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import _ from 'lodash';

import { setSrcPath } from '../../libraries/ApplicationService';

const ImageSliderWrapper = styled.div`
  .slick-dots {
    position: relative;
    bottom: 28px;
    li {
      margin: 0;
      button:before {
        font-size: 4px;
      }
    }
  }
  .slick-slide img {
    &:focus {
      outline: none;
    }
    outline: none;
    width: 100%;
    cursor: pointer;
  }
  .slick-slide div {
    outline: none;
    height: ${props => props.desktop && '198px'};
  }
  .slick-list {
    border-radius: ${props => props.desktop && '8px'};
    overflow: hidden;
  }
  .slick-arrow {
    display: none !important;
  }
  margin: 0 auto;
  overflow: hidden;
`;
const StyledImg = styled.img`
  margin: auto;
`;

export const IMAGE_TEST_ID = 'image-slide';

const ImageSlider = props => {
  const { images, clickable, handleBannerClick, isDesktopLayout } = props;

  const renderImages = () => {
    return _.map(images, (image, index) => (
      <StyledImg
        key={index}
        alt="description"
        onClick={
          clickable
            ? e => {
                handleBannerClick(e, image);
              }
            : () => {}
        }
        src={setSrcPath(image)}
        style={{ margin: 'auto' }}
        data-testid={`${IMAGE_TEST_ID}-${index}`}
      />
    ));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000
  };

  return (
    <ImageSliderWrapper desktop={isDesktopLayout}>
      <Slider {...settings}>{renderImages()}</Slider>
    </ImageSliderWrapper>
  );
};

export default React.memo(ImageSlider);
