import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import colors from '../style/colors';

const UpdateSnackbar = () => {
  return (
    <Snackbar
      id="sw-update-snackbar"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open
      message={<span>A new version of GoGet is available</span>}
      action={[
        <Button
          style={{ color: colors.primary }}
          id="sw-update-reload"
          key={Math.random()}
        >
          Refresh
        </Button>
      ]}
    />
  );
};

export default UpdateSnackbar;
