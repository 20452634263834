import React from 'react';
import styled from 'styled-components/macro';

import DialogActions from '@material-ui/core/DialogActions';

import CtaButton from '../ui/CtaButton';

import ApiIcon from './assets/ApiIcon.svg';
import TrainingPortalIcon from './assets/TrainingPortalIcon.svg';
import FlatRate from './assets/flatRate.svg';
import InsuranceCare from './assets/insuranceCare.svg';
import SupportIcon from './assets/supportIcon.svg';
import FlexibleIcon from './assets/flexibleTopupIcon.svg';
import UnlimitedApplicantsIcon from './assets/unlimitedJobApplicantsIcon.svg';

import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

const BodyWrapper = styled.div`
  ${props => !props.nonPadded && 'padding-top: 0;'}
  ${props => !props.nonPadded && 'padding-left: 2rem;'}
  ${props => !props.nonPadded && 'padding-right: 2rem;'}
  ${props => !props.nonPadded && 'padding-bottom: 1rem;'}
`;
const TextWrapper = styled.div`
  line-height: 21px;
  flex: 1;
`;
const ItemWrapper = styled.div`
  ${props => !props.noMarginTop && 'margin-top: 2rem;'}
  display: flex;
  align-items: center;
`;
const ItemText = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;
const ItemSubTile = styled.div`
  font-size: ${fontSize.large};
  font-weight: 400;
`;
const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const StyledDialogActions = styled(DialogActions)`
  margin-top: 2rem;
`;
const Contact = styled.div`
  text-align: center;
  font-size: ${fontSize.large};
  a:link {
    color: ${colors.primary};
  }
  line-height: 20px;
  padding-top: 8px;
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  justify-content: center;
  background-color: ${colors.redLight};
  color: ${colors.white};
  text-transform: uppercase;
  font-size: ${fontSize.regular};
  font-weight: 600;
  padding: 0 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

export const WRAPPER_TEST_ID = 'items-wrapper';
export const UPGRADE_BUTTON_TEST_ID = 'ctabutton-button';
export const LEARN_MORE_TEST_ID = 'learn-more-button';

const Items = props => {
  const { onClick, hideUpgrade, standard, nonPadded } = props;
  return (
    <BodyWrapper nonPadded={nonPadded} data-testid={WRAPPER_TEST_ID}>
      {standard ? (
        <RenderItems image={FlexibleIcon} title="Flexible Top-up" noMarginTop />
      ) : (
        <>
          <RenderItems
            image={FlatRate}
            title="Flat Rate Pricing"
            subTitle="Enjoy as low as RM7 per stop with more than 10 deliveries."
          />
          <RenderItems
            badge="New"
            image={UnlimitedApplicantsIcon}
            title="Unlimited Job Applicants"
            subTitle="Get unlimited applicants for all your GoGet Recruit job listings."
          />
          <RenderItems
            image={TrainingPortalIcon}
            title="Training Portal"
            subTitle="Train a group of GoGetters to be part of your team."
            noMarginTop={hideUpgrade}
          />
          <RenderItems
            image={ApiIcon}
            title="API Integration"
            subTitle="Connect with GoGet for seamless deliveries."
          />
          <RenderItems
            image={InsuranceCare}
            title="Extra Insurance"
            subTitle="Up to RM400 for each delivery."
          />
          <RenderItems
            image={SupportIcon}
            title="Priority Support"
            subTitle="Dedicated internal team to provide help."
          />
          {!hideUpgrade && (
            <>
              <StyledDialogActions>
                <CtaButton onClick={onClick} color="primary">
                  UPGRADE MY ACCOUNT
                </CtaButton>
              </StyledDialogActions>

              <Contact>
                Customer Enquiries: <a href="tel:+60329359064">+603-29359064</a>{' '}
                or <a href="mailto:business@goget.my">business@goget.my</a>
                <br />
                Business Hours: Mon - Fri: 9am - 6pm (excl pub hol)
              </Contact>
            </>
          )}
        </>
      )}
    </BodyWrapper>
  );
};
function RenderItems(props) {
  const { image, title, subTitle, noMarginTop, badge } = props;
  return (
    <ItemWrapper noMarginTop={noMarginTop}>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <TextWrapper>
        {badge && <Badge>{badge}</Badge>}
        <ItemText>{title}</ItemText>
        <ItemSubTile>
          <span>{subTitle}</span>
        </ItemSubTile>
      </TextWrapper>
    </ItemWrapper>
  );
}

export default Items;
