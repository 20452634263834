import {
  HIRING_POST_CHANGE_PAGE,
  HIRING_POST_DISMISS_FIRST_ENTRY,
  HIRING_POST_EDIT_ABANDON_DETAILS,
  HIRING_POST_EDIT_JOB_DETAILS,
  HIRING_POST_END_FETCH_FEE,
  HIRING_POST_END_JOB_CREATE,
  HIRING_POST_RESET_STATE,
  HIRING_POST_SET_FIRST_ENTRY,
  HIRING_POST_START_FETCH_FEE,
  HIRING_POST_START_JOB_CREATE,
  HIRING_POST_UPDATE_FEE_BREAKDOWN
} from './constants/hiringPost';
import {
  APPLICANT_GENDER_ANYONE,
  EMPLOYMENT_TYPE_PART_TIME,
  JOB_DETAILS_PAGE,
  SALARY_FREQUENCY_MONTHLY,
  SALARY_TYPE_RANGE,
  MALAYSIAN_AND_NON_MALAYSIAN
} from '../components/hiringPost/constants';

export const createHiringPostReducerDefaultState = {
  page: JOB_DETAILS_PAGE,
  firstEntry: false,
  isPostingJob: false,
  isFetchingFee: false,
  isFeeLoaded: false,
  feeBreakdown: undefined,
  abandonDetails: undefined,
  job_details: {
    title: undefined,
    helper_type: undefined,
    employment_type: EMPLOYMENT_TYPE_PART_TIME,
    duration_months: undefined,
    hours_per_week: undefined,
    city: undefined,
    state: undefined,
    salary_type: SALARY_TYPE_RANGE,
    salary_range_start: undefined,
    salary_range_end: undefined,
    salary_range_timeframe: SALARY_FREQUENCY_MONTHLY,
    description: undefined,
    gender: APPLICANT_GENDER_ANYONE,
    nationality: MALAYSIAN_AND_NON_MALAYSIAN,
    age_range_start: undefined,
    age_range_end: undefined,
    additional_requirements: [],
    benefits: [],
    application_email_notification: false
  }
};

export default (state = createHiringPostReducerDefaultState, action) => {
  switch (action.type) {
    case HIRING_POST_CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case HIRING_POST_EDIT_JOB_DETAILS:
      return {
        ...state,
        job_details: {
          ...state.job_details,
          ...action.update
        }
      };
    case HIRING_POST_SET_FIRST_ENTRY:
      return {
        ...state,
        firstEntry: true
      };
    case HIRING_POST_DISMISS_FIRST_ENTRY:
      return {
        ...state,
        firstEntry: false
      };
    case HIRING_POST_START_JOB_CREATE:
      return {
        ...state,
        isPostingJob: true
      };
    case HIRING_POST_END_JOB_CREATE:
      return {
        ...state,
        isPostingJob: false
      };
    case HIRING_POST_RESET_STATE:
      return {
        ...createHiringPostReducerDefaultState
      };
    case HIRING_POST_START_FETCH_FEE:
      return {
        ...state,
        isFetchingFee: true,
        isFeeLoaded: false
      };
    case HIRING_POST_END_FETCH_FEE:
      return {
        ...state,
        isFetchingFee: false
      };
    case HIRING_POST_UPDATE_FEE_BREAKDOWN:
      return {
        ...state,
        isFeeLoaded: true,
        feeBreakdown: action.feeBreakdown
      };
    case HIRING_POST_EDIT_ABANDON_DETAILS:
      return {
        ...state,
        abandonDetails: action.abandonDetails
      };
    default:
      return state;
  }
};
