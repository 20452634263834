import React from 'react';

import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import fontSize from '../../../style/fontSize';

const Title = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 600;
  margin-left: 8px;
  flex: 1;
`;

const SubTitle = styled.div`
  font-size: ${fontSize.large};
  padding-top: 4px;
  padding-bottom: 4px;
`;

const TitleWrap = styled.div`
  padding-bottom: 4px;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

const SegmentHeader = props => {
  const {
    title,
    subtitle,
    icon,
    desktopLayout,
    rightArrowClick,
    leftArrowClick,
    seeAllClick
  } = props;

  return (
    <Content>
      <TitleWrap>
        {icon}
        <Title>{title}</Title>

        {desktopLayout ? (
          <>
            <Button color="primary" variant="outlined" onClick={seeAllClick}>
              See All
            </Button>
            {leftArrowClick && (
              <IconButton color="primary" onClick={leftArrowClick}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {rightArrowClick && (
              <IconButton color="primary" onClick={rightArrowClick}>
                <ArrowForwardIcon />
              </IconButton>
            )}
          </>
        ) : (
          <Button color="primary" onClick={seeAllClick}>
            See All
          </Button>
        )}
      </TitleWrap>
      <SubTitle>{subtitle}</SubTitle>
    </Content>
  );
};

export default SegmentHeader;
