import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import BulkDialogDrawer from './BulkDialogDrawer';
import BusinessTiles from './BusinessTiles';
import HotButtons from './HotButtons';
import PersonalTiles from './PersonalTiles';

import colors from '../../../style/colors';

import { changePage, setFirstEntry } from '../../../actions/createJob';

const Container = styled.div`
  margin: ${({ isDesktop, isTablet }) =>
    isTablet ? '0 0.5rem' : isDesktop ? '1rem 2rem 1rem 1rem' : '0rem'};
`;
const HotButtonsWrapper = styled.div`
  position: relative;
  bottom: 13px;
  padding: ${({ isMobile }) => isMobile && '0 0.5rem'};
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 2rem;
`;
const StyledTab = styled(Tab)`
  && {
    text-transform: none;
  }
`;
const TabButton = styled(Button)`
  && {
    flex: 1;
    border: ${({ isSelected }) =>
      isSelected ? `1px solid ${colors.primary}` : `1px solid ${colors.grey}`};
    color: ${({ isSelected }) => (isSelected ? colors.primary : colors.black)};
    text-transform: none;
    position: relative;

    .MuiButton-label {
      display: flex;
      flex-direction: column;
    }
  }
`;
const StyledCard = styled(Card)`
  && {
    border-radius: 8px;
    margin-top: ${({ isMobile }) => (isMobile ? '0' : '0.5rem')};
  }
`;

const BUSINESS_TILES = 0;
const PERSONAL_TILES = 1;
const LOCAL_STORAGE_TILE_PREF = 'goget-tile-preference';

const DiscoveryJobTiles = props => {
  const {
    currentBulkOrderJobDetails,
    currentHiringPostJobDetails,
    firstDispatchJobTask,
    firstManningBoothJobTask,
    firstChequeDepositJobTask,
    firstPersonalHelperJobTask,
    firstPartTimerJobTask,
    firstFoodShoppingJobTask,
    dispatchSetFirstEntry,
    dispatchChangePage,
    isBusinessAccount,
    isMobile,
    isTablet
  } = props;
  const [selectedTab, setSelectedTab] = useState(
    Number(localStorage.getItem(LOCAL_STORAGE_TILE_PREF)) || BUSINESS_TILES
  );
  const [isBulkMenuOpen, setIsBulkMenuOpen] = useState(false);

  const history = useHistory();
  const isDesktop = !isTablet && !isMobile;

  const handleChangeTab = value => {
    localStorage.setItem(LOCAL_STORAGE_TILE_PREF, value);
    setSelectedTab(value);
  };

  const handleClickCreateBulkUpload = () => {
    if (
      currentBulkOrderJobDetails?.address?.location ||
      currentBulkOrderJobDetails?.instruction
    ) {
      dispatchSetFirstEntry('BULK_ORDER');
    } else {
      dispatchChangePage('BULK_ORDER', 'introduction');
    }
    history.push('/bulk_order');
  };

  const handleClickLazada = () => {
    history.push('/lazada_orders_management');
  };

  const handleClickDispatch = () => {
    if (firstDispatchJobTask.location_lat) {
      dispatchSetFirstEntry('DISPATCH');
    }
    history.push('/dispatch');
  };

  const handleClickChequeDeposit = () => {
    if (firstChequeDepositJobTask && firstChequeDepositJobTask.name) {
      dispatchSetFirstEntry('CHEQUE_DEPOSIT');
    }
    history.push('/cheque_deposit');
  };

  const handleClickFoodShopping = () => {
    if (firstFoodShoppingJobTask.name) {
      dispatchSetFirstEntry('FOOD_SHOPPING');
    }
    history.push('/food_shopping');
  };

  const handleClickManningBooth = (isShortTermOnly = false) => {
    if (firstManningBoothJobTask.name) {
      dispatchSetFirstEntry('MANNING_BOOTH');
    }
    if (currentHiringPostJobDetails?.description) {
      dispatchSetFirstEntry('HIRING_POST');
    }
    history.push(
      `/manning_booth${isShortTermOnly ? '/gig' : ''}?task=manning_booth`
    );
  };

  const handleClickPersonalHelper = task => {
    if (firstPersonalHelperJobTask.location) {
      dispatchSetFirstEntry('PERSONAL_HELPER');
    }
    history.push(`/personal_helper${task ? `?task=${task}` : ''}`);
  };

  const handleClickPartTimer = (task, isShortTermOnly = false) => {
    if (firstPartTimerJobTask.location) {
      dispatchSetFirstEntry('PART_TIMER');
    }
    if (currentHiringPostJobDetails?.description) {
      dispatchSetFirstEntry('HIRING_POST');
    }
    history.push(
      `/part_timer${isShortTermOnly ? '/gig' : ''}${
        task ? `?task=${task}` : ''
      }`
    );
  };

  return (
    <Container isDesktop={isDesktop} isTablet={isTablet}>
      <BulkDialogDrawer
        isOpen={isBulkMenuOpen}
        handleClose={() => setIsBulkMenuOpen(false)}
        onClickCreate={handleClickCreateBulkUpload}
        onClickManage={() => history.push('/manage_bulk_orders')}
        desktopLayout={!isMobile}
      />
      <HotButtonsWrapper isMobile={isMobile}>
        <HotButtons mobile={isMobile} />
      </HotButtonsWrapper>
      <StyledCard isMobile={isMobile} elevation={!isMobile || isTablet ? 2 : 0}>
        {isMobile && !isTablet ? (
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => handleChangeTab(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            style={{
              borderBottom: `1px solid ${colors.grey}`
            }}
          >
            <StyledTab label="Business" value={BUSINESS_TILES} />
            <StyledTab label="Personal" value={PERSONAL_TILES} />
          </Tabs>
        ) : (
          <ButtonWrapper>
            <TabButton
              variant="outlined"
              onClick={() => handleChangeTab(BUSINESS_TILES)}
              isSelected={selectedTab === BUSINESS_TILES}
              fullWidth
            >
              Business
            </TabButton>
            <TabButton
              variant="outlined"
              onClick={() => handleChangeTab(PERSONAL_TILES)}
              isSelected={selectedTab === PERSONAL_TILES}
              fullWidth
            >
              Personal
            </TabButton>
          </ButtonWrapper>
        )}
        {selectedTab === BUSINESS_TILES ? (
          <BusinessTiles
            isBusinessAccount={isBusinessAccount}
            onClickBulk={() => setIsBulkMenuOpen(true)}
            onClickLazada={handleClickLazada}
            onClickDispatch={handleClickDispatch}
            onClickManningBooth={handleClickManningBooth}
            onClickChequeDeposit={handleClickChequeDeposit}
            onClickPersonalHelper={handleClickPersonalHelper}
            onClickPartTimer={handleClickPartTimer}
            isMobile={isMobile && !isTablet}
            handleClickCreateBulkUpload={handleClickCreateBulkUpload}
          />
        ) : (
          <PersonalTiles
            isMobile={isMobile && !isTablet}
            onClickPersonalHelper={handleClickPersonalHelper}
            onClickDispatch={handleClickDispatch}
            onClickFoodShopping={handleClickFoodShopping}
          />
        )}
      </StyledCard>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    isBusinessAccount: state?.user?.profile?.is_business_account,
    currentBulkOrderJobDetails: state?.createBulkOrder?.jobDetails,
    currentHiringPostJobDetails: state?.createHiringPost?.job_details,
    firstDispatchJobTask: state?.createJob?.job?.tasks[0],
    firstManningBoothJobTask: state?.createManningBooth?.job?.tasks[0],
    firstChequeDepositJobTask: state?.createChequeDeposit?.job?.tasks[1],
    firstPersonalHelperJobTask: state?.createPersonalHelper?.job?.tasks[0],
    firstPartTimerJobTask: state?.createPartTimer?.job?.tasks[0],
    firstFoodShoppingJobTask: state?.createFoodAndShopping?.job?.tasks[0]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFirstEntry: reducer => dispatch(setFirstEntry(reducer)),
    dispatchChangePage: (type, page) => dispatch(changePage(type, page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryJobTiles);
