import React from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import LearnMoreDesktop from '../assets/learn-more-desktop.png';
import LearnMoreMobile from '../assets/learn-more-mobile.png';

import fontSize from '../../../style/fontSize';

const DialogTitle = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: ${fontSize.large};
  font-weight: bold;
  margin-bottom: 1rem;
`;

const LearnMoreImage = styled.img`
  width: 100%;
`;

const ButtonWrap = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const LearnMoreDialog = props => {
  const { isOpen, handleClose, desktopLayout } = props;

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogContent>
        <DialogTitle>Journey to the Verified community</DialogTitle>
        <LearnMoreImage
          src={desktopLayout ? LearnMoreDesktop : LearnMoreMobile}
          alt="Learn More"
        />
        <ButtonWrap>
          <Button color="primary" onClick={handleClose}>
            Got It
          </Button>
        </ButtonWrap>
      </DialogContent>
    </Dialog>
  );
};

export default LearnMoreDialog;
