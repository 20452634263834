import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import BluePopUpHiringPost from './BluePopUpHiringPost';

import { getCookie, setCookie } from '../../libraries/authentication';

const StyledButton = styled(Button)`
  text-transform: capitalize;
  height: 40px;
  @media (max-width: 750px) {
    flex-direction: column-reverse;
    height: 48px;
  }
`;

export const SWITCH_BUTTON_TEST_ID = 'switch-button';

const SwitchButton = () => {
  const history = useHistory();
  const isJobsPage = history.location.pathname === '/jobs';
  const [isLongtermJob, setIsLongtermJob] = useState(!isJobsPage);

  useEffect(() => {
    if (history.location.pathname.includes('/jobs')) {
      setIsLongtermJob(false);
      setCookie('isShowingGoGetRecruit', 'false');
    } else {
      setIsLongtermJob(true);
      setCookie('isShowingGoGetRecruit', 'true');
    }
  }, [isLongtermJob, history.location.pathname]);

  const handleNavigation = () => {
    if (!isLongtermJob) {
      history.push('/hiring_posts');
    } else {
      history.push('/jobs');
    }
  };

  return (
    <>
      <BluePopUpHiringPost />
      <StyledButton
        id="new-goget-recruit"
        variant="outlined"
        onClick={handleNavigation}
        endIcon={<SwapHorizIcon />}
        data-testid={SWITCH_BUTTON_TEST_ID}
      >
        {isLongtermJob || getCookie('isShowingGoGetRecruit') === 'true'
          ? 'Recruit'
          : 'Hourly'}
      </StyledButton>
    </>
  );
};

export default SwitchButton;
