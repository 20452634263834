import axios from 'axios';
import { getCookie } from '../libraries/authentication';
import {
  SERVER_URL,
  failedResponse,
  successResponse,
  HTTP_STATUS_OK
} from './apiHelpers';

export const createSession = async userData => {
  try {
    const url = `${SERVER_URL}/private/v1/sessions`;
    const response = await axios.post(url, userData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        authUser: response?.data?.data?.user,
        authToken: response?.data?.data?.auth_token,
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const createSocialSession = async userData => {
  try {
    const url = `${SERVER_URL}/private/v1/sessions/login_integration`;
    const response = await axios.post(url, userData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        authToken: response?.data?.data?.auth_token,
        authUser: response?.data?.data?.user,
        newUser: response?.data?.data?.new_user,
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const createUser = async userData => {
  try {
    const url = `${SERVER_URL}/private/v2/users`;
    const response = await axios.post(url, userData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data,
        authToken: response?.data?.data?.auth_token,
        authUser: response?.data?.data?.user
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const verifyPhoneNumber = async confirmCode => {
  try {
    const url = `${SERVER_URL}/private/v1/users/phone_verification`;

    const response = await axios.post(url, {
      ...confirmCode,
      utm_object: {
        utm_source: getCookie('utm_source'),
        utm_medium: getCookie('utm_medium'),
        utm_campaign: getCookie('utm_campaign')
      }
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        authUser: response?.data?.data?.user,
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const resetPasswordRequest = async email => {
  try {
    const url = `${SERVER_URL}/private/v2/users/reset_password`;
    const response = await axios.post(url, {
      user: {
        email
      }
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const validateResetPasswordToken = async token => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/private/v2/users/validate_token`,
      {
        token
      }
    );

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updatePassword = async (token, password) => {
  try {
    const response = await axios.patch(
      `${SERVER_URL}/private/v2/users/update_password`,
      {
        token,
        password
      }
    );

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message,
        email: response?.data?.data?.email
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const resendVerificationCode = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/users/resend_code`;

    const response = await axios.post(url, {});

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const updatePhoneAndEmail = async userInfo => {
  try {
    const {
      email,
      phoneNum,
      natureOfUsage,
      industry,
      companyName,
      referralCode
    } = userInfo;
    const response = await axios.post(
      `${SERVER_URL}/private/v2/users/update_phone_and_email`,
      {
        referral_code: referralCode || null,
        phone_num: phoneNum,
        nature_of_usage: natureOfUsage,
        email: email || null,
        business: {
          industry: industry || null,
          company_name: companyName || null
        },
        utm_object: {
          utm_source: getCookie('utm_source'),
          utm_medium: getCookie('utm_medium'),
          utm_campaign: getCookie('utm_campaign')
        }
      }
    );

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message,
        user: response?.data?.data?.user
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};

export const createPosterReferralCode = async referralData => {
  try {
    const url = `${SERVER_URL}/private/v1/poster_referrals`;

    const response = await axios.post(url, referralData);

    if (response?.status === 204) {
      return successResponse({
        isTempReferral: true
      });
    } else if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        userData: response?.data?.data,
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({ error });
  }
};
