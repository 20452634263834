import _ from 'lodash';

import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_FULFILLED,
  FETCH_CONVERSATION_REJECTED,
  ADD_CONVERSATION_REJECTED
} from './constants/chat';

export const chatReducerDefaultState = {
  chat: {}
};

export default (state = chatReducerDefaultState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATION:
      const newDefault = {
        [action.jobId]: {
          loaded: false,
          updatedAt: undefined,
          conversations: {},
          gogetterLastSeen: undefined
        }
      };
      return {
        ...state,
        ...newDefault
      };
    case FETCH_CONVERSATION_FULFILLED:
      const newMessage = _.mapKeys(action.messages, 'id');
      let allMessages;
      if (state[action.jobId]) {
        allMessages = {
          ...state[action.jobId].conversations,
          ...newMessage
        };
      } else {
        allMessages = newMessage;
      }
      const new_chat = {
        [action.jobId]: {
          loaded: true,
          conversations: allMessages,
          updatedAt: action.updatedAt,
          gogetterLastSeen: action.gogetterLastSeen
        }
      };
      return {
        ...state,
        ...new_chat
      };
    case FETCH_CONVERSATION_REJECTED:
      return { ...state, loading: false, loaded: false, error: action.payload };

    case ADD_CONVERSATION_REJECTED:
      return { ...state, loading: false, loaded: false, error: action.payload };

    default:
      return state;
  }
};
