import axios from 'axios';
import {
  SERVER_URL,
  successResponse,
  failedResponse,
  HTTP_STATUS_OK,
  HTTP_STATUS_FORBIDDEN
} from './apiHelpers';

export const fetchConversations = async (jobId, updatedAt) => {
  if (!jobId) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v1/jobs/${jobId}/messages_v2${
      updatedAt ? `?updatedAt=${updatedAt}` : ''
    }`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        messages: response?.data?.data?.messages,
        updatedAt: response?.data?.data?.updatedAt,
        gogetterLastSeen: response?.data?.data?.gogetterLastSeen
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    if (error?.response?.status === HTTP_STATUS_FORBIDDEN) {
      return failedResponse({
        forbidden: true
      });
    }

    return failedResponse();
  }
};

export const createConversation = async (jobId, content, files) => {
  if (!jobId) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v1/jobs/${jobId}/messages`;
    const requestData = {
      message: {
        content,
        files: [
          {
            url: files
          }
        ]
      }
    };

    const response = await axios.post(url, requestData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse({
      error
    });
  }
};
