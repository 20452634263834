import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { initPusher } from './authentication';
import { getNotifications } from '../actions/notification';

const NotifcationUpdatePusher = props => {
  const { channel, pusher } = initPusher(props?.userId);
  useEffect(() => {
    channel?.bind('notification_update', data => {
      if (data?.data?.is_notification_updated) {
        props.getNotifications(props?.updatedAt);
      }
    });

    return () => {
      pusher?.unsubscribe(`presence-channel-${props?.userId}`);
    };
  });

  return <></>;
};

const mapStateToProps = state => ({
  userId: state.user?.profile?.id
});

const mapDispatchToProps = dispatch => ({
  getNotifications: updatedAt => dispatch(getNotifications(updatedAt))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotifcationUpdatePusher);
