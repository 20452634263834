import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { parse } from 'qs';
import axios from 'axios';

import {
  setCookie,
  getCookie,
  getAuthToken
} from '../../libraries/authentication';
import {
  setFirstEntry,
  dismissFirstEntry,
  resetState,
  editHelperType
} from '../../actions/createJob';

import { enqueueSnackbar } from '../../actions/snackbar';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import MobileDiscover from './mobile/MobileDiscover';
import DesktopDiscover from './desktop/DesktopDiscover';
import LastSeenUpdate from '../LastSeenUpdate';
import TabMenu from '../Tab';
import ServiceInterestsDialog from '../ui/ServiceInterestsDialog';

import { getUrlParameter } from '../../libraries/ApplicationService';

class Discover extends Component {
  state = {
    isModalOpen: false,
    link: '',
    jobType: '',
    randomIndex: _.random(0, 7),
    isShowingNatureOfUsageModal: false
  };

  componentDidMount() {
    const { authUser, location } = this.props;

    if (getAuthToken() && authUser?.id && !authUser.nature_of_usage) {
      this.setState({
        isShowingNatureOfUsageModal: true
      });
    }

    const utmSource = getUrlParameter(location, 'utm_source');
    const utmedium = getUrlParameter(location, 'utm_medium');
    const utmCampaign = getUrlParameter(location, 'utm_campaign');

    if (utmSource || utmedium || utmCampaign) {
      setCookie('utm_source', utmSource);
      setCookie('utm_medium', utmedium);
      setCookie('utm_campaign', utmCampaign);
    }

    this.handleReferralCodeParams();

    window.analyticsPage('discover', { platform: 'pwa' });
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handleBrowserBack = e => {
    e.preventDefault();
    this.props.history.push('/');
  };

  toggleModal = () => {
    this.setState(state => ({
      isModalOpen: !state.isModalOpen
    }));
  };

  onClickJobTile = (e, link, jobType) => {
    this.toggleModal();
    this.setState({
      link,
      jobType
    });
  };

  handleRedirectBA = () => {
    const { link } = this.state;
    const jwt = getCookie('goget-auth-token');
    let url;
    if (link.includes('?')) {
      url = `${link}&jwt=${jwt}`;
    } else {
      url = `${link}?jwt=${jwt}`;
    }
    window.location.href = url;
  };

  snakeCase = string => {
    return string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  };

  onClickPartTimerUnavailable = () => {
    const { jobType } = this.state;
    const {
      editPartTimerType,
      currentPartTimerType,
      dispatchDismissFirstEntry,
      dispatchResetState,
      push
    } = this.props;

    if (currentPartTimerType === this.snakeCase(jobType)) {
      dispatchDismissFirstEntry('PART_TIMER');
    } else {
      dispatchResetState('PART_TIMER');
      editPartTimerType(this.snakeCase(jobType));
    }
    push('/part_timer');
  };

  onClickPersonalHelperUnavailable = () => {
    const { jobType } = this.state;
    const {
      editPersonalHelperType,
      currentPersonalHelperType,
      dispatchDismissFirstEntry,
      dispatchResetState,
      push
    } = this.props;

    if (currentPersonalHelperType === this.snakeCase(jobType)) {
      dispatchDismissFirstEntry('PERSONAL_HELPER');
    } else {
      dispatchResetState('PERSONAL_HELPER');
      editPersonalHelperType(this.snakeCase(jobType));
    }
    push('/personal_helper');
  };

  applyReferralCode = (posterReferralCode, message) => {
    const { dispatchEnqueueSnackbar } = this.props;
    setCookie('posterReferralCode', posterReferralCode);
    dispatchEnqueueSnackbar({
      message,
      options: {
        variant: 'success'
      }
    });
  };

  handleReferralCodeParams = () => {
    const { location } = this.props;
    const currentReferralCode = getCookie('posterReferralCode');
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const posterReferralCode = queryParams.referral;

    if (!posterReferralCode || posterReferralCode === currentReferralCode)
      return;

    if (getAuthToken()) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/private/v1/users/poster_referral_code_check?poster_referral_code=${posterReferralCode}`
        )
        .then(() =>
          this.applyReferralCode(
            posterReferralCode,
            'Referral code filled. Please go to the Rewards page to activate code.'
          )
        );
    } else {
      this.applyReferralCode(
        posterReferralCode,
        `${posterReferralCode} code applied!`
      );
    }
  };

  handleCloseNatureOfUsageModal = () => {
    this.setState({
      isShowingNatureOfUsageModal: false
    });
  };

  render() {
    const { desktopLayout, isBusinessAccount, authUser, history } = this.props;
    const { jobType, isModalOpen, randomIndex, isShowingNatureOfUsageModal } =
      this.state;
    return (
      <>
        <ServiceInterestsDialog
          isOpen={isShowingNatureOfUsageModal}
          handleDismiss={this.handleCloseNatureOfUsageModal}
          isDiscover
        />
        <LastSeenUpdate />
        <FixedHeaderAppBar
          isMobile={!desktopLayout}
          showWallet="true"
          showLogo={!desktopLayout}
          title={desktopLayout && 'Create Job'}
          showSignupButton="true"
        />
        {desktopLayout ? (
          <DesktopDiscover
            onClickBA={this.handleRedirectBA}
            isBusinessAccount={isBusinessAccount}
            authUser={authUser}
            onClickJobTile={this.onClickJobTile}
            open={isModalOpen}
            onClose={this.toggleModal}
            onClickPartTimerUnavailable={this.onClickPartTimerUnavailable}
            onClickPersonalHelperUnavailable={
              this.onClickPersonalHelperUnavailable
            }
            jobType={jobType}
            randomIndex={randomIndex}
            history={history}
            onClickChequeDeposit={this.onClickChequeDeposit}
          />
        ) : (
          <>
            <MobileDiscover
              onClickBA={this.handleRedirectBA}
              onClickChequeDeposit={this.onClickChequeDeposit}
              isBusinessAccount={isBusinessAccount}
              authUser={authUser}
              onClickJobTile={this.onClickJobTile}
              open={isModalOpen}
              onClose={this.toggleModal}
              onClickPartTimerUnavailable={this.onClickPartTimerUnavailable}
              onClickPersonalHelperUnavailable={
                this.onClickPersonalHelperUnavailable
              }
              jobType={jobType}
              randomIndex={randomIndex}
              history={history}
            />
            <TabMenu history={history} mobile />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user &&
      state.user.profile &&
      state.user.profile.is_business_account,
    currentPersonalHelperType:
      state.createPersonalHelper &&
      state.createPersonalHelper.job &&
      state.createPersonalHelper.job.helper_type,
    currentPartTimerType:
      state.createPartTimer &&
      state.createPartTimer.job &&
      state.createPartTimer.job.helper_type,
    authUser: !!state.user && state.user.profile
  };
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  dispatchSetFirstEntry: reducer => dispatch(setFirstEntry(reducer)),
  dispatchDismissFirstEntry: reducer => dispatch(dismissFirstEntry(reducer)),
  dispatchResetState: reducer => dispatch(resetState(reducer)),
  dispatchEnqueueSnackbar: notification =>
    dispatch(enqueueSnackbar(notification)),
  editPersonalHelperType: type =>
    dispatch(editHelperType('PERSONAL_HELPER', type)),
  editPartTimerType: type => dispatch(editHelperType('PART_TIMER', type))
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Discover)
);
