import { UPDATE_LOCATION } from './constants/currentLocation';

export const currentLocationReducerDefaultState = {
  lat: undefined,
  'long': undefined
};

export default (state = currentLocationReducerDefaultState, action) => {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        lat: action.lat,
        'long': action.long
      };
    default:
      return state;
  }
};
