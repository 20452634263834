import TextField from '@material-ui/core/TextField';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  createPosterReferral,
  PhoneConfirmation,
  resendCode
} from '../../actions/auth';
import { getCookie, logout } from '../../libraries/authentication';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const Description = styled.div`
  font-size: ${fontSize.large};
  line-height: 21px;
`;
const PhoneWrapper = styled(Link)`
  color: ${colors.primary};
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-input {
    letter-spacing: 24px;
    text-align: center;
  }
`;

class PhoneVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmCode: '',
      time: {},
      seconds: 30,
      isSubmitting: false
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    const timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
    window.analyticsPage('phone_verification', { platform: 'pwa' });
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  };

  resendCode = () => {
    this.props.resendCode();
  };

  handleChange = e => {
    const { history, continueCreation } = this.props;
    this.setState(
      {
        confirmCode: e.target.value
      },
      () => {
        const { confirmCode } = this.state;
        if (confirmCode.length === 6) {
          this.setState({ isSubmitting: true });
          this.props.PhoneConfirmation(
            { confirm_code: confirmCode },
            phoneVerified => {
              this.setState({ isSubmitting: false });
              if (phoneVerified) {
                this.props.createPosterReferral();
                if (continueCreation && continueCreation !== undefined) {
                  history.push(continueCreation);
                } else {
                  history.push('/');
                }
              } else {
                history.push('/phone_verification');
              }
            }
          );
        }
      }
    );
  };

  onBack = () => {
    const { history } = this.props;
    logout();
    history.push('/home');
  };

  secondsToTime = secs => {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  render() {
    const { time, confirmCode, isSubmitting } = this.state;
    const { desktopLayout } = this.props;
    return (
      <>
        <FixedHeaderAppBar
          isMobile={!desktopLayout}
          title="SMS verification"
          onBackClick={this.onBack}
        />
        <Container
          desktopLayout={desktopLayout}
          style={{ height: 'calc(100rvh - 64px)' }}
        >
          <Card desktopLayout={desktopLayout}>
            <div>
              {getCookie('user-phone-number') ? (
                <Description>
                  Enter the code we sent via SMS to your registered phone
                  number:
                  <PhoneWrapper to="/edit_phone_and_email">
                    +{getCookie('user-phone-number')}
                  </PhoneWrapper>
                </Description>
              ) : (
                this.onBack()
              )}
              <div id="form">
                <StyledTextField
                  id="outlined-bare"
                  disabled={isSubmitting}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  value={confirmCode}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <BtnWrapper>
              {time.s === 0 ? (
                <CtaButton onClick={this.resendCode}>RESEND CODE</CtaButton>
              ) : (
                <CtaButton disabled>{time.s} SEC RESEND CODE</CtaButton>
              )}
            </BtnWrapper>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    continueCreation: state.createJob && state.continueCreation.path
  };
};

export default connect(mapStateToProps, {
  PhoneConfirmation,
  resendCode,
  push,
  createPosterReferral
})(PhoneVerification);
