import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import LinearProgress from '@material-ui/core/LinearProgress';
import Div100vh from 'react-div-100vh';
import BackgroundSlider from './BackgroundSlider';
import DesktopBackgroundSlider from './DesktopBackgroundSlider';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import Facebook from './Facebook';
import AppleLogin from './AppleLogin';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import TabMenu from '../Tab';

const HomeDiv = styled(Div100vh)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  // justify-content: ${props => (props.desktopLayout ? 'center' : '')};
`;
const ButtonWrapper = styled.div`
  flex: 0;
  box-sizing: border-box;
  margin-top: ${props => (props.desktopLayout ? '24px' : '16px')};
  margin-bottom: ${props => (props.desktopLayout ? '24px' : '32px')};
  margin-left: ${props => (props.desktopLayout ? '24px' : '0px')};
  margin-right: ${props => (props.desktopLayout ? '24px' : '0px')};
  padding-left: 16px;
  padding-right: 16px;
  position: ${props => (props.desktopLayout ? 'block' : 'absolute')};
  bottom: 61px;
  width: ${props => (props.desktopLayout ? '' : '100%')};
  &.isRenderingOnlyButtons {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    ${({ desktopLayout }) =>
      desktopLayout
        ? `
      margin-left: 0;
      margin-right: 0;
    `
        : `
      position: unset;
      bottom: 0;
      width: unset;
    `}
  }
`;

const StyledDesktopCenteringDiv = styled(Div100vh)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
`;
const StyledSignUpButton = styled(Button)`
  && {
    width: 100%;
    box-shadow: none;
    border-radius: 6px;
    height: 39px;
    font-weight: 600;
    color: white;
    font-size: ${props => (props.desktopLayout ? '' : fontSize.regular)};
  }
`;
const StyledLogInButton = styled(Button)`
  && {
    background-color: white;
    color: #00b4ad;
    width: 100%;
    border-radius: 6px;
    height: 39px;
    font-weight: 600;
    border: 1px solid ${colors.grey};
    font-size: ${props => (props.desktopLayout ? '' : fontSize.regular)};
  }
`;
const StyledContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

class Home extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    window.analyticsPage('sign_up_log_in', { platform: 'pwa' });
  }

  onUpdateLoading = load => {
    this.setState({ loading: load });
  };

  render() {
    const { url, desktopLayout, history, isRenderingOnlyButtons } = this.props;
    const { loading } = this.state;

    const renderView = () => (
      <RenderView
        isFacebookRedirect={
          (url.includes('?code=') && url.includes('facebookdirect')) || loading
        }
        googleLoginBtn={this.googleLoginBtn}
        desktopLayout={desktopLayout}
        onUpdateLoading={this.onUpdateLoading}
        onCordovaAppleSignIn={this.onCordovaAppleSignIn}
        isRenderingOnlyButtons={isRenderingOnlyButtons}
      />
    );

    if (isRenderingOnlyButtons) {
      return <React.Fragment>{renderView()}</React.Fragment>;
    }

    return (
      <>
        {desktopLayout && (
          <FixedHeaderAppBar
            isMobile={!desktopLayout}
            showLogo={!desktopLayout}
            title={desktopLayout ? 'Home' : ''}
          />
        )}
        {renderView()}
        <TabMenu history={history} mobile={!desktopLayout} />
      </>
    );
  }
}

function RenderView(props) {
  const {
    isFacebookRedirect,
    desktopLayout,
    onUpdateLoading,
    isRenderingOnlyButtons
  } = props;
  if (isFacebookRedirect) {
    return (
      <div>
        <LinearProgress />
        <Facebook hide />
      </div>
    );
  }

  const buttonContainer = () => {
    return (
      <ButtonWrapper
        desktopLayout={desktopLayout}
        className={isRenderingOnlyButtons ? 'isRenderingOnlyButtons' : ''}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Link to="/login">
              <StyledLogInButton desktopLayout={desktopLayout}>
                Log In
              </StyledLogInButton>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/sign_up">
              <StyledSignUpButton
                color="primary"
                variant="contained"
                desktopLayout={desktopLayout}
              >
                Sign Up
              </StyledSignUpButton>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Facebook onUpdateLoading={onUpdateLoading} />
              <AppleLogin />
            </Box>
          </Grid>
        </Grid>
      </ButtonWrapper>
    );
  };

  if (isRenderingOnlyButtons) {
    return <React.Fragment>{buttonContainer(desktopLayout)}</React.Fragment>;
  } else if (!desktopLayout) {
    return (
      <>
        <HomeDiv
          style={{ height: 'calc(100rvh - 61px)' }}
          desktopLayout={desktopLayout}
        >
          <BackgroundSlider />
          {buttonContainer(desktopLayout)}
        </HomeDiv>
      </>
    );
  }
  return (
    <StyledDesktopCenteringDiv style={{ height: 'calc(100rvh - 64px)' }}>
      <StyledContainer maxWidth="md">
        <DesktopBackgroundSlider
          buttonContainer={buttonContainer}
          desktopLayout={desktopLayout}
        />
      </StyledContainer>
    </StyledDesktopCenteringDiv>
  );
}

const mapStateToProps = state => {
  return {
    url: state.router && state.router.location && state.router.location.search
  };
};

export default connect(mapStateToProps, { push })(Home);
