import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { updateTracker } from '../../actions/gogetterTracking';

class GogetterTrackingService {
  constructor(dispatch, getState, job_id) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.job_id = job_id;
    // this.gogetterLocation = undefined;
    // this.nextTaskOrder = undefined;
  }

  shouldBeTracked() {
    const hasTracking = !!this.getState().gogetterTracking.jobs[this.job_id];
    const isInProgress =
      !!this.getState().jobs.items[this.job_id] &&
      this.getState().jobs.items[this.job_id].status === 'in_progress';
    const isAccepted =
      !!this.getState().jobs.items[this.job_id] &&
      this.getState().jobs.items[this.job_id].status === 'approved';
    const hasTasks =
      isAccepted &&
      this.getState().jobs.items[this.job_id].tasks &&
      this.getState().jobs.items[this.job_id].tasks.length > 0;
    let startsWithinTwentyMins = false;

    if (isAccepted && hasTasks) {
      const now = moment();
      const startAt = this.getState().jobs.items[this.job_id].tasks[0].start_at;
      startsWithinTwentyMins = now.isSameOrAfter(
        moment(startAt).subtract(20, 'minutes')
      );
    }

    if (hasTracking || isInProgress || startsWithinTwentyMins) {
      return true;
    } else {
      return false;
    }
  }

  route = () => {
    if (this.shouldBeTracked()) {
      const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/jobs/${this.job_id}/gogetter_location`;

      const request = axios({
        method: 'GET',
        url
      });
      request
        .then(({ data }) => {
          this.gogetterLocation = data.data.gogetter_location;
          this.nextTaskOrder = data.data.next_task;

          if (
            !data.data.trackable &&
            !!this.getState().gogetterTracking.jobs[this.job_id]
          ) {
            this.dispatch(updateTracker(this.job_id, null));
          } else if (
            !this.getState().gogetterTracking.jobs[this.job_id] ||
            !_.isEqual(
              this.gogetterLocation,
              this.getState().gogetterTracking.jobs[this.job_id]
                .gogetterLocation
            ) ||
            this.nextTaskOrder !==
              this.getState().gogetterTracking.jobs[this.job_id].nextTaskOrder
          ) {
            const nextTask = this.getState().jobs.items[this.job_id].tasks.find(
              task => task.order === this.nextTaskOrder
            );
            const platform_config = {
              app_id: process.env.REACT_APP_HERE_MAPS_APP_ID,
              app_code: process.env.REACT_APP_HERE_MAPS_APP_CODE,
              useCIT: process.env.NODE_ENV !== 'production',
              useHTTPS: true
            };

            const routingParameters = {
              // The routing mode:
              mode: 'balanced;car',
              // To retrieve the shape of the route we choose the route
              // representation mode 'display'
              representation: 'display',
              routeAttributes: 'summary'
            };

            routingParameters.departure = moment().add(5, 'minute').format();

            routingParameters.waypoint0 = `geo!${this.gogetterLocation.lat},${this.gogetterLocation.long}`;

            routingParameters.waypoint1 = `geo!${nextTask.location_lat},${nextTask.location_long}`;

            const platform = new window.H.service.Platform(platform_config);
            const router = platform.getRoutingService();
            router.calculateRoute(
              routingParameters,
              this.onRouteResult,
              error => {
                // eslint-disable-next-line no-console
                console.error(error.message);
              }
            );
          }
        })
        .catch(() => {});
    }
  };

  onRouteResult = result => {
    if (result && result.response && result.response.route) {
      // Pick the first route from the response:
      const route = result.response.route[0];
      const tracker = {
        gogetterLocation: this.gogetterLocation,
        nextTaskOrder: this.nextTaskOrder,
        routeShape: route.shape,
        eta: route.summary.trafficTime
      };
      this.dispatch(updateTracker(this.job_id, tracker));
    }
  };
}
export default GogetterTrackingService;
