import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { getCookie } from '../../libraries/authentication';

const DEVELOP_ENVIRONMENT = 'develop';
const PRODUCTION_ENVIRONMENT = 'production';
const DEVELOP_SAMPLE_RATE = 0.1;
const PRODUCTION_SAMPLE_RATE = 0.2;
const BLACKLISTED_ERRORS = [
  'Network Error',
  '[timeout]: Could not fetch meta-info from map tile service',
  '[timeout]: Could not fetch copyrights from map tile service',
  'Request failed with status code',
  "Failed to execute 'transaction' on 'IDBDatabase'",
  'Request aborted',
  'QuotaExceededError',
  'H.Map#setViewBounds',
  'H.map.Group#removeObject',
  'Registration failed - push service error',
  "Unexpected token '<'"
];

const configureSentry = (env, sampleRate) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_GIT_REV_COUNT,
    environment: env,
    ignoreErrors: BLACKLISTED_ERRORS,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: sampleRate
  });
};

export const initializeSentry = () => {
  Sentry.setUser({
    email:
      getCookie('user-email-address') ||
      window.localStorage.getItem('user-email-address') ||
      ''
  });

  if (process.env.REACT_APP_SERVER_TYPE_EXT === DEVELOP_ENVIRONMENT) {
    configureSentry(DEVELOP_ENVIRONMENT, DEVELOP_SAMPLE_RATE);
  } else if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
    configureSentry(PRODUCTION_ENVIRONMENT, PRODUCTION_SAMPLE_RATE);
  }
};
