import React from 'react';
import styled from 'styled-components/macro';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Avatar from '@material-ui/core/Avatar';
import Close from '@material-ui/icons/Close';

import Items from './Items';

import Crown from '../assets/crown.svg';

import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 8px !important;
    min-width: ${props => props.desktop && '400px'};
  }
`;
const StyledDialogTitle = styled.div`
  display: flex;
  font-size: ${fontSize.xLarge};
  padding: 1rem;
  font-weight: 600;
  align-items: center;
`;
const StyledIconButton = styled(IconButton)`
  padding: 8px !important;
`;
const Header = styled.div`
  flex: 1;
`;
const UpgradeMessageWrapper = styled.div`
  background: ${colors.yellow};
  color: ${colors.black};
  cursor: default;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2rem;
`;
const UpgrateToBAIconWrapper = styled.div`
  width: 100%;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  line-height: 21px;
`;
const Title = styled.div`
  font-size: ${fontSize.xLarge};
  margin: 0 1rem;
  line-height: 19px;
`;
const CloseIcon = styled(Close)`
  color: ${colors.black} !important;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;
const RightWrapper = styled.div``;
const StyledButton = styled(Button)`
  color: ${colors.white} !important;
  box-shadow: none !important;
  font-family: 'Open Sans', sans-serif;
`;

export const TITLE_TEST_ID = 'dialog-title-upgrade';
export const CLOSE_BUTTON_TEST_ID = 'dialog-upgrade-close';

const UpgradeAccountModal = props => {
  const { open, onClose, onClick, desktopLayout } = props;
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      desktop={desktopLayout}
    >
      <StyledDialogTitle data-testid={TITLE_TEST_ID}>
        <Header>GoGet Business</Header>
        <StyledIconButton>
          <CloseIcon onClick={onClose} data-testid={CLOSE_BUTTON_TEST_ID} />
        </StyledIconButton>
      </StyledDialogTitle>
      <UpgradeMessageWrapper>
        <UpgrateToBAIconWrapper>
          <LeftWrapper>
            <Avatar alt="crown" src={Crown} />
            <TextWrapper>
              <Title>Upgrade and unlock benefits!</Title>
            </TextWrapper>
          </LeftWrapper>
          <RightWrapper>
            <StyledButton onClick={onClick} variant="contained" color="primary">
              UPGRADE
            </StyledButton>
          </RightWrapper>
        </UpgrateToBAIconWrapper>
      </UpgradeMessageWrapper>
      <Items onClick={onClick} />
    </StyledDialog>
  );
};

export default UpgradeAccountModal;
