import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';

import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import MaterialIcon from '@material/react-material-icon';
import Button from '@material-ui/core/Button';
import MaterialButton from '@material/react-button';
import MobileStepper from '@material-ui/core/MobileStepper';

import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import styles from './styles.module.scss';

import { getAuthToken } from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import { setSrcPath } from '../../libraries/ApplicationService';

import SwitchButton from '../hiringPost/SwitchButton';

const cx = classNames.bind(styles);

const StyledToolbar = styled(Toolbar)`
  height: 64px;
  box-shadow: unset !important;
  border-bottom: ${({ hideBorder }) =>
    !hideBorder && `1px solid ${colors.greyLight} !important`};
  margin-top: ${({ hideBorder }) => hideBorder && '4px'};
  & > div {
    min-height: ${props =>
      props.ismobile === 'true'
        ? '64px !important'
        : '64px !important'}; // TODO: keep the minHeight from 90px to 64px until further discussion
    padding-left: ${props =>
      props.ismobile === 'true' ? '8px !important' : ''};
    padding-right: ${props =>
      props.ismobile === 'true' ? '8px !important' : ''};
  }
`;
const StyledIconButton = styled(IconButton)`
  color: ${colors.black} !important;
  margin-right: 1rem !important;
`;
const WalletWrapper = styled.span`
  line-height: 32px;
  display: inline-block;
  border-radius: 3px;
  width: fit-content;
`;
const WalletIcon = styled(AccountBalanceWallet)`
  color: ${colors.black};
  position: relative;
  top: 7px;
  padding-left: 10px;
  padding-right: 10px;
`;
const RM = styled.span`
  color: ${colors.black};
`;
const Balance = styled.span`
  color: ${colors.black};
  padding-right: 11px;
`;
const ButtonWrapper = styled(Button)`
  height: 47px;
`;
const Container = styled.div`
  flex-grow: 1 !important;
  background-color: white;
`;
const GogetLogo = styled.img`
  cursor: pointer;
  width: 90px;
  height: 90px;
  @media only screen and (max-width: 380px) {
    width: 65px;
    height: 65px;
  }
  padding-left: 8px;
`;
const IconButtonStyled = styled(IconButton)`
  font-weight: 300 !important;
  margin-left: unset !important;
`;

const Title = styled(Typography)`
  && {
    display: block;
    font-weight: 400;
    font-size: ${fontSize.xLarge};
    margin-right: 1rem;
  }
`;
const BarRight = styled.div`
  display: flex;
  align-items: center;
`;
const StyledBadge = styled(Badge)`
  span {
    color: ${colors.white};
  }
`;
const AvatarWrapper = styled.div``;
const StyledAvatar = styled(Avatar)`
  border: 1px solid ${colors.primary};
`;
const StyledAvatarText = styled(StyledAvatar)`
  background: ${colors.primary} !important;
`;
const StyledMobileStepper = styled(MobileStepper)`
  background: none !important;
`;
const StyledMaterialButton = styled(MaterialButton)`
  font-size: ${props => props.ismobile === 'true' && fontSize.large};
  & > i {
    height: ${props => props.ismobile === 'true' && 'unset !important'};
  }
`;
const CreditsButton = styled(StyledMaterialButton)`
  margin-right: ${props =>
    props.ismobile === 'true' ? '3px' : '10px !important'};
`;
const Wallet = styled.div`
  cursor: pointer;
`;

const FixedHeaderAppBar = props => {
  const {
    isMobile,
    showLogo,
    onBackClick,
    title,
    name,
    showSignupButton,
    creditsRemaining,
    unreadCount,
    showWallet,
    avatar,
    activeStep,
    steps,
    creditsPage,
    disableBackButton,
    isBusinessAccount,
    fixedPosition,
    dispatchPush,
    triggerMenuButton,
    isSwitchJobviewShowing
  } = props;

  const onClickSignup = () => {
    window.analytics.track('sign_up_log_in', {
      category: 'top_navi',
      label: 'discovery',
      platform: 'pwa'
    });
    dispatchPush('/home');
  };

  const onWalletClick = () => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'credits',
      platform: 'pwa'
    });

    dispatchPush('/credits');
  };

  return (
    <Container
      className={cx({ sticky: fixedPosition && isMobile })}
      id="header"
    >
      <StyledToolbar
        ismobile={isMobile ? 'true' : 'false'}
        hideBorder={isSwitchJobviewShowing && isMobile}
      >
        {onBackClick && (
          <IconButtonStyled
            id="fixed-header-back"
            edge="start"
            color="inherit"
            data-testid="fixed-header-back"
            onClick={onBackClick}
            disabled={disableBackButton}
          >
            <ArrowBackIos />
          </IconButtonStyled>
        )}
        {title && (
          <Title variant="h6" noWrap>
            {title}
          </Title>
        )}
        {isSwitchJobviewShowing && <SwitchButton />}
        {showLogo && (
          <Link to="/">
            <GogetLogo src={setSrcPath('/black_logo.png')} />
          </Link>
        )}
        <Container />
        <BarRight>
          {isMobile && steps !== undefined && activeStep !== undefined && (
            <StyledMobileStepper
              variant="dots"
              steps={steps}
              position="static"
              activeStep={activeStep}
            />
          )}
          {!!triggerMenuButton && <MoreVertIcon onClick={triggerMenuButton} />}
          {getAuthToken() ? (
            showWallet && (
              <>
                <ButtonWrapper>
                  <Wallet onClick={onWalletClick}>
                    <WalletWrapper>
                      <WalletIcon />
                      <RM>RM</RM>
                      <Balance>{Number(creditsRemaining).toFixed(2)}</Balance>
                    </WalletWrapper>
                  </Wallet>
                </ButtonWrapper>
                {!isMobile && (
                  <>
                    <Link to="/notifications">
                      <StyledIconButton>
                        <StyledBadge
                          max={99}
                          badgeContent={
                            unreadCount && unreadCount !== 0 && unreadCount
                          }
                          color="primary"
                        >
                          <NotificationsIcon />
                        </StyledBadge>
                      </StyledIconButton>
                    </Link>
                    <Link to="/profile">
                      <AvatarWrapper>
                        {avatar ? (
                          <StyledAvatar alt="image" src={avatar && avatar} />
                        ) : (
                          <StyledAvatarText>
                            {name && name.charAt(0).toUpperCase()}
                          </StyledAvatarText>
                        )}
                      </AvatarWrapper>
                    </Link>
                  </>
                )}
              </>
            )
          ) : (
            <>
              {showSignupButton && (
                <>
                  {!creditsPage && (
                    <Link to="/credits">
                      <CreditsButton
                        ismobile={isMobile ? 'true' : 'false'}
                        icon={<MaterialIcon icon="account_balance_wallet" />}
                      >
                        Credits
                      </CreditsButton>
                    </Link>
                  )}
                  <StyledMaterialButton
                    ismobile={isMobile ? 'true' : 'false'}
                    unelevated
                    onClick={onClickSignup}
                  >
                    SIGN UP / LOG IN
                  </StyledMaterialButton>
                </>
              )}
            </>
          )}
        </BarRight>
      </StyledToolbar>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    avatar: state.user?.profile?.avatar,
    name: state.user?.profile?.name,
    creditsRemaining: state.credits?.creditsRemaining,
    unreadCount: state.notification?.unreadCount,
    isBusinessAccount: state.user?.profile?.is_business_account
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchPush: url => dispatch(push(url))
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(FixedHeaderAppBar)
);
