import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components/macro';
import { times } from 'lodash';
import colors from '../../style/colors';
import { setSrcPath } from '../../libraries/ApplicationService';

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  .slick-slider {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  .slick-list {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }
  .slick-slide > div {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
`;

const StyledDiv = styled.div`
  background-size: cover;
  background-image: url(${props => props.image});
  background-position: center center;
`;
const LogoImg = styled.img`
  height: 60px;
  margin-top: 26px;
  margin-left: 22px;
`;
const StyledTypography = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 1.5rem;
    color: white;
    margin-top: 26px;
    margin-left: 22px;
    margin-right: 22px;
  }
`;
const StyledDotContainer = styled.div`
  margin-top: 15px;
  margin-left: 22px;
  margin-right: 22px;
  display: flex;
`;
const StyledDot = styled.div`
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 6px;
  background-color: ${props => (props.active ? colors.primary : 'white')};
`;

const BackgroundSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    {
      label: 'Access and manage an unbeatable talent pool.',
      src: '/SignUpBackgroundMobile-1.jpg'
    },
    {
      label: 'Find the best person for your job.',
      src: '/SignUpBackgroundMobile-2.jpg'
    },
    {
      label: 'Get help at affordable rates.',
      src: '/SignUpBackgroundMobile-3.jpg'
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  const renderCustomDots = () => {
    return times(items.length).map((_, index) => {
      return <StyledDot active={currentIndex === index} />;
    });
  };

  return (
    <SliderWrapper>
      <Slider
        {...settings}
        beforeChange={(_, newIndex) => setCurrentIndex(newIndex)}
      >
        {items.map(item => {
          return (
            <StyledDiv image={setSrcPath(item.src)}>
              <LogoImg src={setSrcPath('/white_logo.svg')} alt="logo" />
              <StyledTypography>{item.label}</StyledTypography>
              <StyledDotContainer>{renderCustomDots()}</StyledDotContainer>
            </StyledDiv>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default BackgroundSlider;
