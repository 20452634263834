import { push } from 'connected-react-router';
import {
  fetchBusinessStatus,
  fetchProfile,
  fetchReferralStatus,
  fetchReviews,
  updateUserPassword,
  updateUserProfile,
  getUserKycTier
} from '../api/profile';

import {
  getCookie,
  setCookie,
  getAuthToken
} from '../libraries/authentication';

import { enqueueSnackbar } from './snackbar';

import {
  START_FETCH,
  END_FETCH,
  FETCH_REJECTED,
  FETCH_REVIEWS_FULFUILED,
  UPDATE_PROFILE_DETAILS
} from '../reducers/constants/profile';

export const updateProfileDetails = payload => ({
  type: UPDATE_PROFILE_DETAILS,
  payload
});

export const updateUserKycDetails = (profile, userData) => {
  return dispatch => {
    const updatedProfile = {
      ...profile,
      ...userData,
      business_details: {
        ...profile?.business_details,
        ...userData?.business_details
      }
    };
    dispatch(updateProfileDetails(updatedProfile));
  };
};

export const getBusinessStatus = () => {
  return async dispatch => {
    const response = await fetchBusinessStatus();

    if (response?.isSuccess) {
      dispatch(updateProfileDetails(response?.profileDetails));
    }
  };
};

export function getProfile() {
  return async dispatch => {
    dispatch({ type: START_FETCH });
    const response = await fetchProfile();

    if (response?.isSuccess) {
      dispatch(updateProfileDetails(response?.profile));
      dispatch({
        type: END_FETCH
      });
    } else {
      dispatch({ type: FETCH_REJECTED });
    }
  };
}

export function getReviews() {
  return async dispatch => {
    dispatch({ type: START_FETCH });
    const response = await fetchReviews();

    if (response?.isSuccess) {
      dispatch({
        type: FETCH_REVIEWS_FULFUILED,
        payload: response?.reviews
      });
    } else {
      dispatch({ type: FETCH_REJECTED });
    }
  };
}

export function updateProfile(params) {
  return async dispatch => {
    const response = await updateUserProfile(params);

    if (response?.isSuccess) {
      await dispatch({
        type: END_FETCH
      });
      await dispatch(updateProfileDetails(response?.user));
      if (
        !response?.user?.phone_verified &&
        getCookie('user-phone-number') !== response?.user?.phone_num
      ) {
        setCookie('user-phone-verified', response?.user?.phone_verified);
        setCookie('user-phone-number', response?.user?.phone_num);
        dispatch(push('/phone_verification'));
      } else {
        dispatch(push('/profile'));
        await dispatch(
          enqueueSnackbar({
            message: 'Profile updated!',
            options: {
              variant: 'success'
            }
          })
        );
      }
      return {
        success: true
      };
    } else {
      await dispatch({ type: FETCH_REJECTED });
      return {
        success: false
      };
    }
  };
}

export function updatePassword(password) {
  return async dispatch => {
    const response = await updateUserPassword(password);

    if (response?.isSuccess) {
      await dispatch(
        enqueueSnackbar({
          message: response?.message,
          options: {
            variant: 'success'
          }
        })
      );
      dispatch(push('/edit_profile'));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  };
}

export function getReferralStatus() {
  return async dispatch => {
    if (!getAuthToken()) return;

    const response = await fetchReferralStatus();

    if (response?.isSuccess) {
      dispatch(
        updateProfileDetails({
          ...response?.referralData
        })
      );
    }
  };
}

export const getKycStatus = () => {
  return async dispatch => {
    const response = await getUserKycTier();

    if (response?.isSuccess) {
      dispatch(updateProfileDetails({ ...response?.data }));
    }
  };
};
