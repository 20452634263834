import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import compareVersions from 'compare-versions';
import { socialLogin } from '../../actions/auth';
import { enqueueSnackbar } from '../../actions/snackbar';
import AppleSignin from './assets/apple-logo.png';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const ButtonWrap = styled.div`
  width: 100%;
  height: 38px;
  background: ${colors.black};
  border-radius: 8px;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonText = styled.div`
  font-size: ${fontSize.medium};
  color: ${colors.white};
`;

const StyledImg = styled.img`
  height: 22px;
  margin-right: 8px;
  letter-spacing
`;

class AppleLogin extends Component {
  onCordovaAppleSignIn = () => {
    const { socialLogin, enqueueSnackbar } = this.props;
    if (window.cordova) {
      window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        response => {
          const user = {
            uid: response.user,
            user: {
              name: `${response.fullName.givenName} ${response.fullName.familyName}`,
              email: response.email
            },
            type: 'apple'
          };
          socialLogin(user);
        },
        error => {
          if (error.code !== '1001') {
            enqueueSnackbar({
              message:
                error.localizedFailureReason || error.localizedDescription,
              options: {
                variant: 'error'
              }
            });
          }
        }
      );
    }
  };

  render() {
    return (
      <>
        {window.cordova &&
          window.device.version &&
          compareVersions.compare(window.device.version, '13.0', '>=') && (
            <ButtonWrap onClick={this.onCordovaAppleSignIn}>
              <StyledImg src={AppleSignin} alt="Sign in with Apple" />
              <ButtonText>CONTINUE WITH APPLE</ButtonText>
            </ButtonWrap>
          )}
      </>
    );
  }
}

export default connect(null, { socialLogin, enqueueSnackbar })(AppleLogin);
