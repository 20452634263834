export const BULK_ORDER_UPDATE_JOB_DETAILS = 'BULK_ORDER_UPDATE_JOB_DETAILS';
export const BULK_ORDER_UPDATE = 'BULK_ORDER_UPDATE';
export const BULK_ORDER_CANCEL_PROCESSING = 'BULK_ORDER_CANCEL_PROCESSING';
export const BULK_ORDER_EDIT_DROPOFF = 'BULK_ORDER_EDIT_DROPOFF';
export const BULK_ORDER_RESET_DROPOFFS = 'BULK_ORDER_RESET_DROPOFFS';
export const BULK_ORDER_ADD_DROPOFF = 'BULK_ORDER_ADD_DROPOFF';
export const BULK_ORDER_REMOVE_DROPOFF = 'BULK_ORDER_REMOVE_DROPOFF';
export const BULK_ORDER_UNDO_REMOVE = 'BULK_ORDER_UNDO_REMOVE';
export const BULK_ORDER_RESET_STATE = 'BULK_ORDER_RESET_STATE';
export const BULK_ORDER_CHANGE_PAGE = 'BULK_ORDER_CHANGE_PAGE';
export const BULK_ORDER_SET_FIRST_ENTRY = 'BULK_ORDER_SET_FIRST_ENTRY';
export const BULK_ORDER_DISMISS_FIRST_ENTRY = 'BULK_ORDER_DISMISS_FIRST_ENTRY';
export const BULK_ORDER_EDIT_IMAGE = 'BULK_ORDER_EDIT_IMAGE';
export const BULK_REORDER_DROPOFF_TOP = 'BULK_REORDER_DROPOFF_TOP';
export const BULK_ORDER_CREATION_START = 'BULK_ORDER_CREATION_START';
export const BULK_ORDER_CREATION_END = 'BULK_ORDER_CREATION_END';
