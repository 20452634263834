import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import GGPopper from '../ui/GGPopper';

const BluePopUpHiringPost = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    const hasViewed = localStorage.getItem('new-goget-recruit');

    if (!hasViewed) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    const el = document.getElementById('new-goget-recruit');
    if (el) {
      setAnchorEl(el);
    }
  }, []);

  const handleClick = () => {
    setIsOpen(false);
    localStorage.setItem('new-goget-recruit', true);
  };

  if (!anchorEl) {
    return null;
  }

  return (
    <GGPopper open={isOpen} anchorEl={anchorEl} arrowPosition="top">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={1}>
          <Typography variant="p">
            NEW! Switch between Hourly and Recruit jobs here
          </Typography>
        </Box>
        <Button
          onClick={() => handleClick()}
          style={{ color: 'white', width: 150 }}
        >
          <strong>GOT IT</strong>
        </Button>
      </Box>
    </GGPopper>
  );
};

export default BluePopUpHiringPost;
