import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuthToken } from '../libraries/authentication';

class AppOpenAnalytics extends Component {
  componentDidMount() {
    const { isBusinessAccount } = this.props;

    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('open_app', {
      category: userType,
      label: getAuthToken() ? 'y' : 'n',
      page_path: window.location.pathname,
      platform: 'pwa',
      properties: {
        nonInteraction: 1
      }
    });
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => ({
  isBusinessAccount:
    state.user && state.user.profile && state.user.profile.is_business_account
});

export default connect(mapStateToProps)(AppOpenAnalytics);
