import moment from 'moment';
import { enqueueSnackbar } from '../../actions/snackbar';

class JobTrackingService {
  constructor(
    gogetterLocation,
    deliveryLocation,
    dispatch,
    updateTracker,
    publicId
  ) {
    this.gogetterLocation = gogetterLocation;
    this.deliveryLocation = deliveryLocation;
    this.dispatch = dispatch;
    this.updateTracker = updateTracker;
    this.publicId = publicId;
  }

  route = () => {
    const platform_config = {
      app_id: process.env.REACT_APP_HERE_MAPS_APP_ID,
      app_code: process.env.REACT_APP_HERE_MAPS_APP_CODE,
      useCIT: process.env.NODE_ENV !== 'production',
      useHTTPS: true
    };

    const routingParameters = {
      // The routing mode:
      mode: 'balanced;car',
      // To retrieve the shape of the route we choose the route
      // representation mode 'display'
      representation: 'display',
      legAttributes: 'summary',
      routeAttributes: 'summary'
    };

    routingParameters.departure = moment().format();
    const platform = new window.H.service.Platform(platform_config);
    const router = platform.getRoutingService();

    if (this.gogetterLocation.lat) {
      routingParameters.waypoint0 = `geo!${this.gogetterLocation.lat},${this.gogetterLocation.long}`;
      routingParameters.waypoint1 = `geo!${this.deliveryLocation.lat},${this.deliveryLocation.long}`;

      router.calculateRoute(routingParameters, this.onRouteResult, error => {
        // eslint-disable-next-line no-console
        console.error(error.message);
      });
    }
  };

  onRouteResult = result => {
    if (!!result.response && !!result.response.route) {
      const route = result.response.route[0];
      const arrivalTime = Math.ceil(route.summary.trafficTime / 60) + 2;

      const tracker = {
        routeShape: route.shape,
        arrivalTime
      };

      this.dispatch(this.updateTracker(this.publicId, tracker));
    } else {
      this.dispatch(
        enqueueSnackbar({
          message: 'Unable to locate GoGetter. Please try again shortly.',
          options: {
            variant: 'error'
          }
        })
      );
    }
  };
}
export default JobTrackingService;
