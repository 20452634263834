import moment from 'moment';
import { oneJobFeeCalculation } from './massJobCalculationService';

const massJobMapper = (job, feeBreakdown) => {
  const mappedJob = {
    ...job,
    payment_confirmation: false
  };

  const mappedSchedules = job?.schedules?.map(schedule => {
    return {
      fee:
        job?.schedules?.length === 1 && job?.schedules[0]?.no_of_gogetters === 1
          ? feeBreakdown
          : oneJobFeeCalculation(schedule, feeBreakdown),
      no_of_gogetters: schedule.no_of_gogetters,
      fee_calcs: [{}],
      tasks: [
        ...schedule.days.map((day, idx) => {
          const startAt = moment(day)
            .hour(moment(schedule.start_at).hour())
            .minute(moment(schedule.start_at).minute())
            .startOf('minute');
          let endAt = moment(day)
            .hour(moment(schedule.end_at).hour())
            .minute(moment(schedule.end_at).minute())
            .startOf('minute');
          if (moment(schedule.end_at).day() > moment(schedule.start_at).day()) {
            endAt = endAt.add(1, 'day');
          }
          return {
            ...job.tasks[0],
            order: idx,
            start_at: startAt.toISOString(),
            end_at: endAt.toISOString()
          };
        })
      ]
    };
  });

  mappedJob.schedules = mappedSchedules;
  delete mappedJob.fees;
  delete mappedJob.tasks;

  return {
    job: mappedJob
  };
};

export { massJobMapper };
