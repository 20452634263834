import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import {
  Assignment,
  Home,
  Work,
  Person,
  ExpandLess,
  ExpandMore,
  ArrowForward,
  FindInPage
} from '@material-ui/icons';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import { push } from 'connected-react-router';

import { getBusinessStatus } from '../../actions/profile';
import { changePage } from '../../actions/createJob';

import UpgradeAccountModal from '../gogetBusiness/UpgradeAccountModal';

import {
  getAuthToken,
  logout,
  getCookie
} from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import Crown from '../assets/crown.svg';
import VerifiedGogetters from '../assets/verifiedGogetters.svg';

import DialogGogetterApp from '../ui/DialogGogetterApp';
import NewProfilePopper from '../ui/NewProfilePopper';

import { setSrcPath } from '../../libraries/ApplicationService';

const StyledContainer = styled.div`
  cursor: default;
  height: 100%;
`;
const StyledBody = styled.div`
  width: ${props => (props.isMobile ? '' : 'calc(100% - 185px)')};
  margin-left: ${props => (props.isMobile ? '' : '185px')};
`;
const StyledDrawer = styled(Drawer)`
  > div:first-child {
    background: ${colors.primary};
    width: 185px;
  }
  display: ${props =>
    props.ismobile === 'true' || props.isTracker ? 'none' : 'block'};
`;
const GogetLogo = styled.img`
  cursor: pointer;
  width: 106px;
  margin: 0 auto;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  color: ${colors.white} !important;
  font-size: ${fontSize.xLarge} !important;
  min-width: 2rem !important;
`;
const StyledListItemText = styled(ListItemText)`
  padding: 0 !important;
  > span:first-child {
    color: ${colors.white};
    font-size: ${fontSize.xLarge};
  }
`;
const NestedListItem = styled(ListItem)`
  > div:first-child {
    padding-left: 4px;
  }
`;
const StyledNestedListItemText = styled(ListItemText)`
  > span:first-child {
    color: ${colors.white};
    font-size: ${fontSize.large};
  }
`;
const IconExpandMore = styled(ExpandMore)`
  color: ${colors.white} !important;
`;
const IconExpandLess = styled(ExpandLess)`
  color: ${colors.white} !important;
`;
const StyledCard = styled(Card)`
  background: ${colors.yellow} !important;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 8px !important;
  margin-bottom: 1rem;
  box-shadow: none !important;
`;
const StyledCardActionArea = styled(CardActionArea)`
  display: flex !important;
  align-items: center !important;
  height: 4rem;
  padding-left: 8px !important;
  padding-right: 8px !important;
`;
const CardText = styled.div`
  line-height: 21px;
  text-align: left;
  padding-left: 8px;
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;
const StyledArrowForward = styled(ArrowForward)`
  color: ${colors.black} !important;
  width: 20px !important;
  height: 20px !important;
`;
const StyledArrowForwardGG = styled(ArrowForward)`
  color: ${colors.white} !important;
  width: 20px !important;
  height: 20px !important;
`;
const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const GogetterImageWrapper = styled.div`
  height: 5rem;
  width: 5rem;
  margin: 0 auto;
`;
const GogetterCard = styled(Card)`
  border-radius: 8px !important;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: none !important;
`;
const StyledCardContent = styled.div`
  display: flex;
  background: ${colors.greenLight};
  color: ${colors.white};
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
`;
const LeftSideBarFooter = styled.div`
  position: absolute;
  bottom: 3rem;
  width: 100%;
`;

class DesktopLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      isModalOpen: false,
      pathname: this.props.url,
      isGogetterAppDialogOpen: false,
      isTracker: false
    };
    this.myAccountRef = React.createRef();
  }

  componentDidMount() {
    const { getBusinessStatus } = this.props;
    const { pathname } = this.state;

    getBusinessStatus();

    if (pathname.includes('track')) {
      this.setState({ isTracker: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        pathname: this.props.url
      });
    }
  }

  linkToDiscovery = () => {
    this.props.push('/');
  };

  linkToProfile = () => {
    this.props.push('/profile');
  };

  linkToJob = () => {
    if (getCookie('isShowingGoGetRecruit') === 'true') {
      this.props.push('/hiring_posts');
    } else {
      this.props.push('/jobs');
    }
  };

  linkToGgInspector = () => {
    this.props.push('/gg_inspector');
  };

  linkToLazada = () => {
    const { push } = this.props;
    push('/lazada_orders_management');
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  onClickModal = () => {
    const { isBusinessAccount } = this.props;

    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'business_upgrade',
      platform: 'pwa'
    });

    this.setState(prevState => {
      return { isModalOpen: !prevState.isModalOpen };
    });
  };

  onClickUpgradeMe = () => {
    const { push } = this.props;
    push('/credits?business=true');
    this.setState({
      isModalOpen: false
    });
  };

  handleClickGogetterApp = () => {
    const { push, isBusinessAccount } = this.props;

    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'be_a_gogetter',
      platform: 'pwa'
    });

    if (getAuthToken()) {
      this.setState({
        isGogetterAppDialogOpen: true
      });
    } else {
      push('/home');
    }
  };

  onCloseGogetterAppDialog = () => {
    this.setState({
      isGogetterAppDialogOpen: false
    });
  };

  render() {
    const { isModalOpen, open, isGogetterAppDialogOpen, isTracker } =
      this.state;
    const {
      children,
      isMobile,
      isBusinessAccount,
      isVerifiedGogetter,
      isLazadaMerchant,
      isGogetterInspector
    } = this.props;
    return (
      <StyledContainer isMobile={isMobile}>
        <StyledDrawer
          variant="permanent"
          anchor="left"
          ismobile={isMobile.toString()}
          isTracker={isTracker}
        >
          <GogetLogo
            onClick={this.linkToDiscovery}
            src={setSrcPath('/white_logo.svg')}
          />
          <List>
            <ListItem button onClick={this.linkToDiscovery}>
              <StyledListItemIcon>
                <Home />
              </StyledListItemIcon>
              <StyledListItemText primary="Create Job" />
            </ListItem>
            {getAuthToken() && (
              <>
                <ListItem button onClick={this.linkToJob}>
                  <StyledListItemIcon>
                    <Work />
                  </StyledListItemIcon>
                  <StyledListItemText primary="My Jobs" />
                </ListItem>
                {isGogetterInspector && (
                  <ListItem button onClick={this.linkToGgInspector}>
                    <StyledListItemIcon>
                      <FindInPage />
                    </StyledListItemIcon>
                    <StyledListItemText primary="GG Inspector" />
                  </ListItem>
                )}
                {isLazadaMerchant && (
                  <ListItem button onClick={this.linkToLazada}>
                    <StyledListItemIcon>
                      <Assignment />
                    </StyledListItemIcon>
                    <StyledListItemText primary="Lazada Order" />
                  </ListItem>
                )}
                <ListItem
                  ref={this.myAccountRef}
                  id="my-account-dt"
                  button
                  onClick={this.handleClick}
                >
                  <StyledListItemIcon>
                    <Person />
                  </StyledListItemIcon>
                  <StyledListItemText primary="My Account" />
                  {open ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NestedListItem button onClick={this.linkToProfile}>
                      <StyledNestedListItemText inset primary="Profile" />
                    </NestedListItem>
                    <a
                      href="https://gogetmy.zendesk.com/hc/en-us"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <NestedListItem button>
                        <StyledNestedListItemText
                          inset
                          primary="Help & Support"
                        />
                      </NestedListItem>
                    </a>
                    <a
                      href={`${process.env.REACT_APP_STATIC_PATH}/terms_conditions/`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <NestedListItem button>
                        <StyledNestedListItemText
                          inset
                          primary="Terms & Conditions"
                        />
                      </NestedListItem>
                    </a>
                    <NestedListItem onClick={logout} button>
                      <StyledNestedListItemText inset primary="Log Out" />
                    </NestedListItem>
                  </List>
                </Collapse>
              </>
            )}
          </List>

          <LeftSideBarFooter>
            {!isBusinessAccount && (
              <StyledCard onClick={this.onClickModal}>
                <StyledCardActionArea>
                  <ImageWrapper>
                    <Image src={Crown} />
                  </ImageWrapper>
                  <CardText>GoGet Business</CardText>
                  <StyledArrowForward />
                </StyledCardActionArea>
              </StyledCard>
            )}

            <GogetterCard onClick={this.handleClickGogetterApp}>
              <CardActionArea>
                <GogetterImageWrapper>
                  <Image src={VerifiedGogetters} alt="gogetters" />
                </GogetterImageWrapper>
                <StyledCardContent>
                  <CardText>
                    {isVerifiedGogetter
                      ? 'Switch to GoGetter'
                      : 'Earn in your free time'}
                  </CardText>
                  <StyledArrowForwardGG />
                </StyledCardContent>
              </CardActionArea>
            </GogetterCard>
          </LeftSideBarFooter>
          <NewProfilePopper
            anchorId={isMobile ? 'my-account-mb' : 'my-account-dt'}
            isMobile={isMobile}
          />
        </StyledDrawer>
        <StyledBody isMobile={isMobile}>{children}</StyledBody>
        <UpgradeAccountModal
          open={isModalOpen}
          onClick={this.onClickUpgradeMe}
          onClose={this.onClickModal}
        />
        <DialogGogetterApp
          open={isGogetterAppDialogOpen}
          onClose={this.onCloseGogetterAppDialog}
        />
      </StyledContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user &&
      state.user.profile &&
      state.user.profile.is_business_account,
    isVerifiedGogetter:
      state.user &&
      state.user.profile &&
      state.user.profile.is_verified_gogetter,
    creditsRemaining: state.credits.creditsRemaining,
    url:
      state.router && state.router.location && state.router.location.pathname,
    unreadCount: state.notification && state.notification.unreadCount,
    isLazadaMerchant:
      state.user && state.user.profile && state.user.profile.is_lazada_merchant,
    isGogetterInspector: state?.user?.profile?.is_gogetter_inspector
  };
};

const mapDispatchToProps = dispatch => ({
  push: url => dispatch(push(url)),
  getBusinessStatus: () => dispatch(getBusinessStatus()),
  changePage: (type, page) => dispatch(changePage(type, page))
});
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(DesktopLayout)
);
