import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Help from '@material-ui/icons/Help';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { getAuthToken } from '../../libraries/authentication';

import HelpSupport from './HelpSupport';

import colors from '../../style/colors';

const StyledButton = styled(Button)`
  && {
    color: ${({ desktop }) => (desktop ? colors.white : colors.primary)};
    background-color: ${({ desktop }) => !desktop && colors.white};
    text-transform: capitalize;
    border-radius: 21px;
    font-weight: 700;
    width: 5rem;
    position: ${props => props.desktop && 'fixed '};
    bottom: ${props => props.desktop && '1rem '};
    right: ${props => props.desktop && '1rem '};

    svg {
      color: ${({ desktop }) => !desktop && colors.primary}!important;
    }
  }
`;
const HelpIcon = styled(Help)`
  color: ${colors.white} !important;
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px !important;
  font-weight: 100 !important;
`;
const ContentWrapper = styled.div`
  padding: 1rem;
`;

class HelpButton extends Component {
  state = {
    anchorEl: null
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleClick = event => {
    const { isBusinessAccount } = this.props;
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'help',
      platform: 'pwa'
    });
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  render() {
    const { desktopLayout, isHelperJob, isRecruitJob } = this.props;
    const { anchorEl } = this.state;
    const openPopover = Boolean(anchorEl);
    return (
      <>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={this.handleClick}
          desktop={desktopLayout}
          disableElevation={!desktopLayout}
        >
          <HelpIcon />
          Help
        </StyledButton>
        {desktopLayout ? (
          <Popover
            onClose={this.handlePopoverClose}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            <ContentWrapper>
              <HelpSupport
                isHelperJob={isHelperJob}
                isRecruitJob={isRecruitJob}
              />
            </ContentWrapper>
          </Popover>
        ) : (
          <SwipeableDrawer
            open={openPopover}
            onClose={this.handlePopoverClose}
            anchor="bottom"
          >
            <ContentWrapper>
              <HelpSupport
                isHelperJob={isHelperJob}
                isRecruitJob={isRecruitJob}
              />
            </ContentWrapper>
          </SwipeableDrawer>
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

export default connect(mapStateToProps)(HelpButton);
