import { createHashHistory, createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/es/storage';

import { persistStore, persistReducer } from 'redux-persist';
import createRootReducer from '../reducers';

function getHistory() {
  if (window.cordova) {
    return createHashHistory();
  } else {
    return createBrowserHistory();
  }
}

export const history = getHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'chat',
    'createJob',
    'notification',
    'credits',
    'auth',
    'createFoodAndShopping',
    'createPartTimer',
    'continueCreation',
    'createPersonalHelper',
    'createChequeDeposit',
    'createManningBooth',
    'createBulkOrder',
    'createHiringPost',
    'editHiringPost',
    'favourites'
  ]
};
const reducers = persistReducer(persistConfig, createRootReducer(history));
const middlewares = [thunk, routerMiddleware(history)];

export default () => {
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
