import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Button from '@material-ui/core/Button';
import { socialLogin } from '../../actions/auth';
import { getCookie } from '../../libraries/authentication';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import { setSrcPath } from '../../libraries/ApplicationService';
import { enqueueSnackbar } from '../../actions/snackbar';

const FbButton = styled(Button)`
  background: #4267b2 !important;
  color: ${colors.white} !important;
  margin-top: 8px !important;
  font-family: 'Open Sans', sans-serif !important;
  width: 100%;
  height: 36px;
  font-weight: 100 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  @media only screen and (max-width: 750px) {
    font-size: ${fontSize.regular} !important;
  }
`;
const FbWrapper = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
`;

const FacebookLogo = styled.img`
  padding-right: 8px;
  width: 9px;
`;

class Facebook extends Component {
  responseFacebook = async response => {
    const { socialLogin } = this.props;
    const { id, email, name, picture } = response;
    const avatar = picture?.data?.url;
    const currentReferralCode = getCookie('posterReferralCode');
    const user = {
      uid: id,
      user: {
        name,
        email,
        avatar,
        temp_poster_referral_code: currentReferralCode
      },
      type: 'facebook'
    };
    await socialLogin(user);
  };

  onFBCordovaLogin = () => {
    const { onUpdateLoading } = this.props;
    onUpdateLoading(true);
    window.facebookConnectPlugin.login(
      ['email', 'public_profile'],
      this.fbLoginSuccess,
      this.onErrorResponse
    );
  };

  fbLoginSuccess = userData => {
    window.facebookConnectPlugin.api(
      `${userData.authResponse.userID}/?fields=id,email,name,picture`,
      ['email', 'public_profile'],
      this.responseFacebook,
      this.onErrorResponse
    );
  };

  onErrorResponse = error => {
    const { enqueueSnackbar, onUpdateLoading } = this.props;
    onUpdateLoading(false);
    enqueueSnackbar({
      message: error,
      options: {
        variant: 'error'
      }
    });
  };

  render() {
    const origin = window && window.location && window.location.origin;
    const redirectUri =
      typeof window !== 'undefined'
        ? origin + window.location.pathname
        : origin;

    const isSafariBrowser =
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0;
    if (window.cordova) {
      return (
        <FbWrapper hide={this.props.hide}>
          <FbButton onClick={this.onFBCordovaLogin}>
            <FacebookLogo src={setSrcPath('/auth/facebook-2.svg')} />
            CONTINUE WITH FACEBOOK
          </FbButton>
        </FbWrapper>
      );
    } else {
      return (
        <FbWrapper hide={this.props.hide}>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            autoLoad={false}
            redirectUri={redirectUri}
            disableMobileRedirect={!!isSafariBrowser}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <FbButton onClick={renderProps.onClick}>
                <FacebookLogo src={setSrcPath('/auth/facebook-2.svg')} />
                CONTINUE WITH FACEBOOK
              </FbButton>
            )}
          />
        </FbWrapper>
      );
    }
  }
}

export default connect(null, { socialLogin, enqueueSnackbar })(Facebook);
