import { DISPLAY_NEW_REFERRAL_INTRO_DIALOG } from './constants/customPopup';

export const customPopupReducerDefaultState = {
  newReferralIntroDialogOpen: false
};

export default (state = customPopupReducerDefaultState, action) => {
  switch (action.type) {
    case DISPLAY_NEW_REFERRAL_INTRO_DIALOG:
      return { ...state, newReferralIntroDialogOpen: true };
    default:
      return state;
  }
};
