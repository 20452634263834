import {
  START_FETCH,
  END_FETCH,
  FETCH_REJECTED,
  FETCH_REVIEWS_FULFUILED,
  UPDATE_PROFILE_DETAILS
} from './constants/profile';

export const profileReducerDefaultState = {
  reviews: [],
  profile: null,
  loading: false,
  loaded: false,
  error: null
};

export default (state = profileReducerDefaultState, action) => {
  switch (action.type) {
    case START_FETCH:
      return { ...state, loading: true };
    case END_FETCH:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case FETCH_REJECTED:
      return { ...state, loading: false, loaded: false };
    case FETCH_REVIEWS_FULFUILED:
      return {
        ...state,
        loading: false,
        loaded: true,
        reviews: action.payload
      };
    case UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
