import axios from 'axios';
import { push } from 'connected-react-router';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { getAuthToken } from '../../../libraries/authentication';
import ImageSlider from '../../ui/ImageSlider';
import { openPaymentLink } from '../../../libraries/openLink';
import { purchaseCreditPlan } from '../../../api/credits';

const SliderWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

class MobileBanner extends Component {
  state = {
    images: []
  };

  componentDidMount() {
    const { isBusinessAccount } = this.props;

    let banners = [
      '/RecruitMobileBanner.png',
      '/CashlessMobileBanner.png',
      '/ReferAndEarnMobile.png',
      '/OpenEverydayMobile.png',
      '/SameDayBanner.png'
    ];

    const currentDate = moment();

    if (
      !isBusinessAccount &&
      currentDate.isBetween(moment('2021-01-18'), moment('2021-04-14'))
    ) {
      banners = ['/ConsumerMobileV2.png'].concat(banners);
    }

    if (currentDate.isBetween(moment('2021-03-29'), moment('2021-05-17'))) {
      banners = ['/RayaMobile.png'].concat(banners);
    }

    if (currentDate.isBetween(moment('2021-07-12'), moment('2021-09-30'))) {
      banners = ['/GoGetLokalMobile.png'].concat(banners);
    }

    if (currentDate.isSameOrBefore(moment('2021-10-31'))) {
      banners = ['/Back2BusinessMobile.png'].concat(banners);
    }

    this.setState({ images: banners });

    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/private/v1/festival_promos`)
      .then(({ data }) => {
        if (
          data &&
          data.data &&
          data.data.festival_banners &&
          data.data.festival_banners.mobile_img
        ) {
          this.setState({
            images: [data.data.festival_banners.mobile_img, ...banners]
          });
        }
      });
  }

  handleBannerClick = async (e, image) => {
    const { push, isBusinessAccount } = this.props;

    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'hero_banner',
      platform: 'pwa'
    });
    if (image.includes('SameDayBanner.png')) {
      push('/dispatch');
    } else if (image && image.includes('RecruitMobileBanner.png')) {
      push('/part_timer/recruit');
    } else if (image && image.includes('ReferAndEarnMobile.png')) {
      push('/referral');
    } else if (image.includes('HalloweenMobile.jpg')) {
      push('/stores');
    } else if (image.includes('OpenEverydayMobile.png')) {
      window.open('https://gogetmy.zendesk.com/hc/en-us/articles/900000373146');
    } else if (image.includes('CashlessMobileBanner.png')) {
      push('/credits');
    } else if (image.includes('RayaMobile.png')) {
      push('/rewards');
    } else if (image.includes('GoGetLokalMobile.png')) {
      push('/food_shopping');
    } else if (image.includes('Back2BusinessMobile.png')) {
      push('/part_timer');
    } else if (image.includes('ConsumerMobileV2.png')) {
      if (window.cordova) {
        const response = await purchaseCreditPlan('54756ec2');
        if (response?.isSuccess) {
          openPaymentLink(response?.redirectLink, push, () => {});
        }
      } else {
        push('/credits?public_id=54756ec2');
      }
    } else {
      push('/credits?business=true');
    }
  };

  render() {
    const { images } = this.state;
    return (
      <>
        <SliderWrapper mobileLayout="true">
          <ImageSlider
            clickable="true"
            handleBannerClick={this.handleBannerClick}
            images={images}
            mobileLayout
          />
        </SliderWrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBanner);
