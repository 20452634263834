import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import colors from '../../style/colors';

const StyledButton = styled(Button)`
  && {
    width: 100%;
    height: 50px;
    ${props =>
      !props.disabled &&
      css`
        && {
          color: ${colors.white};
          background: ${colors.primary};
        }
      `}
  }
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        && {
          color: ${colors.white};
          background: ${colors.primary};
        }
      }
    `}
`;

class CtaButton extends Component {
  state = {
    wait: false
  };

  prepClick = () => {
    // delay to allow ios to close keyboard
    const { onClick } = this.props;
    const isSafariBrowser =
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0;

    if (onClick) {
      if (window.cordova || isSafariBrowser) {
        this.setState({ wait: true });
        setTimeout(() => {
          this.setState({ wait: false });
          onClick();
        }, 200);
      } else {
        onClick();
      }
    }
  };

  render() {
    const {
      loading,
      disabled,
      children,
      className,
      disableElevation = true,
      size
    } = this.props;

    const { wait } = this.state;
    return (
      <StyledButton
        className={className}
        color="primary"
        variant="contained"
        onClick={this.prepClick}
        disableElevation={disableElevation}
        disabled={!!loading || disabled || wait}
        size={size}
        data-testid="ctabutton-button"
      >
        {loading ? 'Loading...' : children}
      </StyledButton>
    );
  }
}

export default CtaButton;

CtaButton.propTypes = {
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Loading button
   */
  loading: PropTypes.bool,
  /**
   * Disbale button
   */
  disabled: PropTypes.bool,
  /**
   * Button contents
   */
  children: PropTypes.string.isRequired,
  /**
   * You can enable/disable the box shadow
   */
  disableElevation: PropTypes.bool
};

CtaButton.defaultProps = {
  onClick: undefined,
  loading: false,
  disabled: false,
  disableElevation: true
};
