import { Typography, Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components/macro';
import { times } from 'lodash';
import colors from '../../style/colors';

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  .slick-slider {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  .slick-list {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }
  .slick-slide > div {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
`;

const StyledImg = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 390px;
`;
const StyledTypography = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 1.5rem;
    color: black;
    margin-top: 26px;
    margin-left: 22px;
    margin-right: 22px;
  }
`;
const StyledDotContainer = styled.div`
  margin-top: 15px;
  margin-left: 22px;
  margin-right: 22px;
  display: flex;
`;
const StyledDot = styled.div`
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 6px;
  background-color: ${props =>
    props.active ? colors.primary : colors.greyUnselected};
`;
const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

const DesktopBackgroundSlider = ({ buttonContainer, desktopLayout }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    {
      label: 'Access and manage an unbeatable talent pool.',
      src: '/SignUpBackgroundMobile-1.jpg'
    },
    {
      label: 'Find the best person for your job.',
      src: '/SignUpBackgroundMobile-2.jpg'
    },
    {
      label: 'Get help at affordable rates.',
      src: '/SignUpBackgroundMobile-3.jpg'
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  const renderCustomDots = () => {
    return times(items.length).map((_, index) => {
      return <StyledDot active={currentIndex === index} />;
    });
  };

  return (
    <SliderWrapper>
      <Slider
        {...settings}
        beforeChange={(_, newIndex) => setCurrentIndex(newIndex)}
      >
        {items.map(item => {
          return (
            <Box image={item.src}>
              <Grid container>
                <Grid item xs={5}>
                  <StyledImg src={item.src} alt="background" />
                </Grid>
                <StyledGrid item xs={7}>
                  <Box>
                    <StyledTypography>{item.label}</StyledTypography>
                    <StyledDotContainer>
                      {renderCustomDots()}
                    </StyledDotContainer>
                  </Box>
                  <Box>{buttonContainer(desktopLayout)}</Box>
                </StyledGrid>
              </Grid>
            </Box>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default DesktopBackgroundSlider;
