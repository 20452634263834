import { mapKeys, size } from 'lodash';
import {
  BULK_ORDERS_NO_UPDATES,
  BULK_ORDERS_RECEIVE_ORDERS,
  BULK_ORDERS_RESET_STATE,
  BULK_REQUEST_ORDERS,
  BULK_RESET_TOTAL,
  BULK_UPDATE_RECEIVED_TAB
} from './constants/orders';

export const ordersReducerDefaultState = {
  isFetching: false,
  updatedAt: undefined,
  totalOrdersCount: undefined,
  totalOrdersReceived: 0,
  items: {},
  tabType: {
    processing: {
      received: 0,
      total: undefined
    },
    jobs_posted: {
      received: 0,
      total: undefined
    },
    history: {
      received: 0,
      total: undefined
    }
  }
};

export default (state = ordersReducerDefaultState, action) => {
  switch (action.type) {
    case BULK_REQUEST_ORDERS:
      return {
        ...state,
        isFetching: true
      };
    case BULK_ORDERS_NO_UPDATES:
      return {
        ...state,
        isFetching: false
      };
    case BULK_ORDERS_RECEIVE_ORDERS:
      const newOrders = mapKeys(action.items, 'id');
      const allOrders = {
        ...state.items,
        ...newOrders
      };
      return {
        ...state,
        isFetching: false,
        updatedAt: action.updatedAt,
        totalOrdersCount: action.totalOrdersCount,
        totalOrdersReceived: size(allOrders),
        items: allOrders,
        tabType: {
          processing: {
            ...state.tabType.processing,
            total: action.processing_total
          },
          jobs_posted: {
            ...state.tabType.jobs_posted,
            total: action.posted_total
          },
          history: {
            ...state.tabType.history,
            total: action.history_total
          }
        }
      };
    case BULK_UPDATE_RECEIVED_TAB:
      const { tabType } = state;
      tabType[action.tabType] = {
        ...tabType[action.tabType],
        received: action.received
      };
      return {
        ...state,
        tabType
      };
    case BULK_RESET_TOTAL:
      return {
        ...state,
        tabType: {
          processing: {
            ...state.tabType.processing,
            total: undefined
          },
          jobs_posted: {
            ...state.tabType.jobs_posted,
            total: undefined
          },
          history: {
            ...state.tabType.history,
            total: undefined
          }
        }
      };
    case BULK_ORDERS_RESET_STATE:
      return {
        ...state,
        updatedAt: undefined,
        totalOrdersCount: undefined,
        totalOrdersReceived: 0,
        items: {},
        tabType: {
          processing: {
            received: 0,
            total: undefined
          },
          jobs_posted: {
            received: 0,
            total: undefined
          },
          history: {
            received: 0,
            total: undefined
          }
        }
      };
    default:
      return state;
  }
};
