import {
  SALARY_TYPE_EXACT,
  SALARY_TYPE_RANGE,
  SUMMARY_PAGE
} from '../components/hiringPost/constants';
import {
  EDIT_HIRING_POST_END_UPDATE,
  EDIT_HIRING_POST_START_EDIT,
  EDIT_HIRING_POST_START_UPDATE,
  HIRING_POST_CHANGE_PAGE,
  HIRING_POST_EDIT_JOB_DETAILS,
  HIRING_POST_RESET_STATE
} from './constants/hiringPost';

import { createHiringPostReducerDefaultState } from './createHiringPostReducer';

export const editHiringPostReducerDefaultState = {
  ...createHiringPostReducerDefaultState,
  page: SUMMARY_PAGE
};

export default (state = editHiringPostReducerDefaultState, action) => {
  switch (action.type) {
    case EDIT_HIRING_POST_START_EDIT:
      return {
        ...state,
        page: SUMMARY_PAGE,
        job_details: {
          ...action.jobDetails,
          salary_type: action?.jobDetails?.salary_range_end
            ? SALARY_TYPE_RANGE
            : SALARY_TYPE_EXACT
        }
      };
    case `EDIT_${HIRING_POST_CHANGE_PAGE}`:
      return {
        ...state,
        page: action.page
      };
    case `EDIT_${HIRING_POST_EDIT_JOB_DETAILS}`:
      return {
        ...state,
        job_details: {
          ...state.job_details,
          ...action.update
        }
      };
    case EDIT_HIRING_POST_START_UPDATE:
      return {
        ...state,
        isPostingJob: true
      };
    case EDIT_HIRING_POST_END_UPDATE:
      return {
        ...state,
        isPostingJob: false
      };
    case `EDIT_${HIRING_POST_RESET_STATE}`:
      return {
        ...createHiringPostReducerDefaultState
      };
    default:
      return state;
  }
};
