import React from 'react';
import styled from 'styled-components/macro';

import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';

import KYCGhost from '../../../assets/icons/kyc_ghost.svg';
import KYCVerified from '../../../assets/icons/kyc_verified.svg';

import colors from '../../../style/colors';

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 1.5rem 0;
`;

const Progress = styled(LinearProgress)`
  && {
    height: 10px;
    border-radius: 6px;
    background-color: ${colors.greyLight};
  }
`;

const VerifiedIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%);
`;

const PositionIcon = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ position }) => `${position}%`};
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const StyledAvatar = styled(Avatar)`
  && {
    height: 37px;
    width: 37px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid ${colors.primary};
  }
`;

const ProgressBar = props => {
  const { tierPercentage, avatar, isShowingAvatar } = props;

  const positionIcon = () => {
    if (isShowingAvatar && avatar) {
      return <StyledAvatar src={avatar} alt="Avatar" />;
    } else {
      return <img src={KYCGhost} alt="Position Icon" />;
    }
  };

  return (
    <ProgressWrapper>
      <PositionIcon position={tierPercentage}>{positionIcon()}</PositionIcon>
      <Progress variant="determinate" color="primary" value={tierPercentage} />
      <VerifiedIcon src={KYCVerified} alt="Verified" />
    </ProgressWrapper>
  );
};

export default ProgressBar;
