import axios from 'axios';
import {
  SERVER_URL,
  failedResponse,
  successResponse,
  FESTIVE,
  HTTP_STATUS_OK
} from './apiHelpers';

export const fetchCreditsRemaining = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/users/credits_remaining`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        creditsRemaining: response?.data?.data?.credits_remaining,
        creditsExpiration: response?.data?.data?.credits_expiration
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchCreditPlans = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/credits/business`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        cards: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const purchaseCreditPlan = async publicID => {
  if (!publicID) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/credits`;
    const response = await axios.post(url, { public_id: publicID });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        redirectLink: response?.data?.data?.redirect_to
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const purchaseCreditFlexi = async amount => {
  if (!amount) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/credits/create_flexi`;
    const response = await axios.post(url, { topup_amount: amount });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        redirectLink: response?.data?.data?.redirect_to
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getCreditHistory = async params => {
  try {
    const url = `${SERVER_URL}/private/v1/credits/history`;
    const response = await axios.get(url, { params });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        newTransactions: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getReceipt = async id => {
  if (!id) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/credits/${id}/receipt`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        receipt: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updateSegment = async id => {
  if (!id) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/credits/${id}/update_segment`;
    const response = await axios.post(url, {});

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const checkReupgradeBalance = async publicID => {
  if (!publicID) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/credits/check_credit_balance_after_reupgrade`;
    const response = await axios.post(url, { public_id: publicID });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        reupgradeData: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getCreditBanner = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/credits/banner`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        banner: response?.data?.data?.img_path,
        isFestivePlan: response?.data?.data?.banner_type === FESTIVE
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
