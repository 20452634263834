import React from 'react';
import styled from 'styled-components/macro';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Dialog from '@material-ui/core/Dialog';

const StyledDialog = styled(Dialog)`
  > .MuiDialog-container {
    > .MuiPaper-root.MuiPaper-elevation24 {
      width: 100% !important;
    }
  }
`;
const TypeWrapper = styled.div`
  padding: ${({ noPad }) => !noPad && '1rem'};
`;

const DialogDrawer = props => {
  const { open, onClose, onOpen, children, maxWidth, desktop, noPad } = props;
  if (desktop) {
    return (
      <StyledDialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        data-testid="dialog-drawer-desktop"
      >
        <TypeWrapper noPad={noPad}>{children}</TypeWrapper>
      </StyledDialog>
    );
  } else {
    return (
      <SwipeableDrawer
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        anchor="bottom"
        data-testid="dialog-drawer-mobile"
      >
        <TypeWrapper noPad={noPad}>{children}</TypeWrapper>
      </SwipeableDrawer>
    );
  }
};

export default DialogDrawer;
