import {
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  REGISTERATION_PROCESS,
  REGISTERATION_FULLFILED,
  REGISTERATION_REJECTED
} from './constants/auth';

export const authReducerDefaultState = {
  loading: false,
  loaded: false,
  error: null
};

export default (state = authReducerDefaultState, action) => {
  switch (action.type) {
    case LOGIN_FULFILLED:
      return { ...state, loading: false, loaded: true };
    case LOGIN_REJECTED:
      return { ...state, loading: false, loaded: false, error: action.payload };
    case REGISTERATION_PROCESS:
      return { ...state, loading: true };
    case REGISTERATION_FULLFILED:
      return { ...state, loading: false, loaded: true };
    case REGISTERATION_REJECTED:
      return { ...state, loading: false, loaded: true };
    default:
      return state;
  }
};
